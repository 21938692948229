import { textItems } from './shared';

export const text = {
  title: 'Text/List',
  ...textItems,
  text: 'Edit text here',
  styles: {
    ...textItems.styles,
    fontSize: '12px',
  },
};
