import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import SoundsNav from './soundsNav/soundsNav';
import SoundsTraining from './soundsTraining/soundsTraining';
import SoundsCategory from './soundsCategory/soundsCategory';
import SoundsRecording from './soundsRecording/soundsRecording';
import firebase from '../../../../firebase';
import Loader from '../../shared/loader/loader';
import './sounds.scss';
import { setSounds, setUserInfo } from '../../../../actions';
import { baseURL } from '../../../../config';

const trainingCategory = 'training';
const recordingCategory = 'recording';

function Sounds(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const sounds = useSelector(state => state.sounds.sounds);
  const categories = useSelector(state => state.sounds.categories);
  // const hasAccess = useSelector(state => state.sounds.hasAccess);
  const hasAccess = true;
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(trainingCategory);
  const [accessDeniedTitle, setAccessDeniedTitle] = useState('');
  const [accessDeniedText, setAccessDeniedText] = useState('');
  const [introIndex, setIntroIndex] = useState(-1);
  const [showClickerInstructions, setShowClickerInstructions] = useState(false);
  const [userRecordings, setUserRecordings] = useState([]);

  useEffect(() => {
    if (user.coursesSet) {
      initializeSounds();
    }
  }, [user]);

  const initializeSounds = async () => {
    if (categories.length) {
      setUserRecordings(user.recordings || []);
      return;
    }

    try {
      const soundsSnapshot = await firebase.firestore().collection('training-sounds').doc('sounds').get();
      const soundsData = soundsSnapshot.data();
      const categories = Object.keys(soundsData);

      categories.forEach(category => {
        soundsData[category].sort((a, b) => {
          if (a.free && !b.free) {
            return -1;
          }

          if (!a.free && b.free) {
            return 1;
          }

          return 0;
        });
      });

      const sortedCategories = categories.sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();

        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      });

      let userHasAccessToSounds = user.hasOwnProperty('hasAccess') && user.hasAccess;
      const currentUser = firebase.auth().currentUser;

      if (!userHasAccessToSounds) {
        const response = await axios.get(`${baseURL}/sounds/user/${currentUser.uid}`);

        if (response.data && response.data.hasSoundsMembership) {
          userHasAccessToSounds = true;
        }
      }

      dispatch(setSounds({
        sounds: soundsData,
        categories: [trainingCategory, ...sortedCategories, recordingCategory],
        hasAccess: userHasAccessToSounds,
      }));

      setUserRecordings(user.recordings || []);

      if (!user.hasSeenSoundsIntro) {
        setIntroIndex(0);

        try {
          await firebase.firestore().collection('users').doc(currentUser.uid).update({
            hasSeenSoundsIntro: true,
          });

          dispatch(setUserInfo({
            ...user,
            hasSeenSoundsIntro: true,
          }));
        } catch (e) {
          // continue
        }
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error setting up the sounds dashboard. If this problem persists, please contact us.');
    }
  };

  const handleSaveNewRecording = async (recordedFile, displayName) => {
    const fileName = `${Date.now()}.mp3`;

    try {
      const currentUser = firebase.auth().currentUser;
      const storageRef = firebase.storage().ref();
      const userUploadsRef = storageRef.child(`userUploads/${currentUser.uid}/recordings/${fileName}`);

      await userUploadsRef.put(recordedFile);

      let storageUrl = await userUploadsRef.getDownloadURL();
      storageUrl = storageUrl.replace(
        'https://firebasestorage.googleapis.com/v0/b/pupford-6b94c.appspot.com/o/',
        'https://pupford.b-cdn.net/',
      );

      const newRecording = {
        fileName,
        url: storageUrl,
        displayName,
      };

      const updatedRecordings = [...userRecordings, newRecording];

      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        recordings: updatedRecordings,
      });

      setUserRecordings(updatedRecordings);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error saving your recording, please try again.');
    }
  };

  const handleDeleteSound = async (index) => {
    const recording = userRecordings[index];

    try {
      const currentUser = firebase.auth().currentUser;
      const recordingRef = firebase.storage().ref(`/userUploads/${currentUser.uid}/recordings/${recording.fileName}`);
      await recordingRef.delete();

      const updatedRecordings = [...userRecordings];
      updatedRecordings.splice(index, 1);

      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        recordings: updatedRecordings,
      });

      setUserRecordings(updatedRecordings);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error deleting your recording, please try again.');
    }
  };

  const handleSaveEditedRecording = async (displayName, index) => {
    try {
      const currentUser = firebase.auth().currentUser;
      const updatedRecordings = [...userRecordings];
      updatedRecordings[index] = {
        ...updatedRecordings[index],
        displayName,
      };

      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        recordings: updatedRecordings,
      });

      setUserRecordings(updatedRecordings);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error updating your recording, please try again.');
    }
  };

  const renderSoundSection = () => {
    if (selectedCategory === trainingCategory) {
      return (
        <SoundsTraining
          hasAccess={hasAccess}
          howToUseClickerClicked={() => setShowClickerInstructions(true)}
          howToUseSoundsClicked={() => setIntroIndex(0)}
        />
      );
    }

    if (selectedCategory === recordingCategory) {
      return (
        <SoundsRecording
          hasAccess={hasAccess}
          recordings={userRecordings}
          handleRecordingError={(title, message) => {
            setModalTitle(title);
            setModalText(message);
          }}
          handleSaveNewRecording={handleSaveNewRecording}
          handleUnsupportedFileType={() => {
            setModalTitle('Notice:');
            setModalText('This file type is not supported in your current browser.');
          }}
          handleDeleteSound={handleDeleteSound}
          handleSaveEditedRecording={handleSaveEditedRecording}
          accessDenied={() => {
            setAccessDeniedTitle('Unlock More Recordings');
            setAccessDeniedText('Upgrade to record up to 20 sounds and get access to over 100 sounds plus any future additions!');
          }}
          handleMaxPaidRecordingsReached={() => {
            setAccessDeniedTitle('Recordings Limit Reached');
            setAccessDeniedText('You have reached the maximum number of recordings. In order to add new recordings you must remove a recording from your list.');
          }}
        />
      );
    }

    return (
      <SoundsCategory
        sounds={sounds[selectedCategory]}
        hasAccess={hasAccess}
        accessDenied={() => {
          setAccessDeniedTitle('Access Over 100 Training Sounds');
          setAccessDeniedText('Upgrade to get access to over 100 sounds and any future additions!');
        }}
      />
    );
  };

  const introSections = () => {
    return [
      {
        videoId: '553424965',
        paragraphs: [],
      },
      {
        paragraphs: [
          'Start by playing the sound once at a very low volume.',
          `As soon as you play the sound once, give ${user.dogName || 'your dog'} a treat.`,
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058865087-training_still_1.png?alt=media&token=13bfade5-baf9-479c-9ead-7697381caf0b',
      },
      {
        paragraphs: [
          `If ${user.dogName || 'your dog'} gets nervous or scared, simply lower the volume back to their comfort level. There is no need to rush.`,
          'This process can take multiple sessions over days or even weeks.',
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058873165-training_still_2.png?alt=media&token=85b18e19-ab44-4c92-936c-51dec3094173',
      },
      {
        paragraphs: [
          'Repeat these steps until you can reach full volume of the sound without your dog getting scared or nervous.',
          `The key is to pair these sounds with high-value treats to make the new sounds/stimuli have a positive association for ${user.dogName || 'your dog'}!`,
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058870656-training_still_3.png?alt=media&token=212de046-4993-4e9f-8405-bdbd0a9a2b29',
      },
    ];
  };


  return (
    <div className="Sounds">
      {!categories.length ?
        <div className="loading-container">
          <Loader size={2} />
          <p>Launching sounds dashboard...</p>
        </div> :
        <>
          <SoundsNav
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={(category) => setSelectedCategory(category)}
          />

          <div className="sounds-container">
            {renderSoundSection()}
          </div>
        </>
      }
    </div>
  );
}

export default Sounds;
