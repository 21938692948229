import React, { useState } from 'react';

import Modal from './buttonPreviewModal';
import DangerouslySetHtmlContent from '../DangerouslySetHtmlContent';

function Button({ content, customStyles, clicked }) {
  const [modalOpen, setModalOpen] = useState(false);

  const isUrlAbsolute = (url) => {
    if (url.indexOf('//') === 0) {return true;} // URL is protocol-relative (= absolute)
    if (url.indexOf('://') === -1) {return false;} // URL has no protocol (= relative)
    if (url.indexOf('.') === -1) {return false;} // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
    if (url.indexOf('/') === -1) {return false;} // URL does not contain a single slash (= relative)
    if (url.indexOf(':') > url.indexOf('/')) {return false;} // The first colon comes after the first slash (= relative)
    if (url.indexOf('://') < url.indexOf('.')) {return true;} // Protocol is defined before first dot (= absolute)
    return false; // Anything else must be relative
  };

  if (content.clickAction === 'link') {
    return (
      <a href={content.link} target={content.openLinkInNewTab ? '_blank' : ''} rel={content.linkNoFollow ? 'nofollow' : ''}>
        <button style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
      </a>
    );
    // if (isUrlAbsolute(content.link)) {
    //   return (
    //     <a href={content.link} target={content.openLinkInNewTab ? '_blank' : ''} rel={content.linkNoFollow ? 'nofollow' : ''}>
    //       <button style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
    //     </a>
    //   );
    // }

    // return (
    //   <Link href={content.link}>
    //     <a target={content.openLinkInNewTab ? '_blank' : ''} rel={content.linkNoFollow ? 'nofollow' : ''}>
    //       <button style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
    //     </a>
    //   </Link>
    // );
  }

  if (content.clickAction === 'modal') {
    return (
      <>
        <button onClick={() => { setModalOpen(true) }} style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>

        <Modal
          open={modalOpen}
          close={() => { setModalOpen(false) }}
          title=""
          buttons={[]}
        >
          <DangerouslySetHtmlContent html={content.html} />
        </Modal>
      </>
    );
  }

  return (
    <button onClick={clicked} style={{ ...content.styles, ...customStyles }} className={content.customClasses} id={content.htmlID}>{content.text}</button>
  );
}

export default Button;
