import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from '../../shared/card/card';
import Loader from '../../shared/loader/loader';

import './myCourses.scss';
import { setAccessDenied } from '../../../../actions';
import courseHeaderImage from '../../../../images/courses-header.jpg';
import checkCircleIcon from '../../../../images/circle-check.svg';

function MyCourses(props) {

  if (props.loadingCourses) {
    return (
      <div className="MyCoursesLoader">
        <Loader size={2} />
      </div>
    );
  }

  const courseRows = () => {
    const courses = [[]];

    props.courses.forEach((course) => {
      courses.forEach((c, i) => {
        if (c.length !== 2) {
          courses[i].push(course);

          if (c.length === 2) {
            courses.push([]);
          }
        }
      });
    });

    if (courses[courses.length - 1].length === 1) {
      courses[courses.length - 1].push({});
    }

    return courses;
  };

  return (
    <div className="MyCourses">
      <div className="header-container">
        <Card>
          <div>
            <img className="header-image" src={courseHeaderImage} width="100%" />
          </div>
        </Card>
      </div>

      {courseRows().map((row, i) => {
        return (
          <div className="row" key={`course-row-${i}`}>
            {row.map((course, j) => {
              return (
                <div key={`course-${j}`} className="course-card">
                  {course.name ? <Link to={`/course/${course.webPath ? course.webPath : course.id}`}>
                    <Card>
                      <div className={`course-image${course.disabled || course.enabled === false ? ' disabled-image' : ''}`} style={{backgroundImage: `url(${course.backgroundImage})`}}>
                        {props.completedCourses.includes(course.id) ? <img src={checkCircleIcon} alt="complete" /> : null}
                      </div>
                      <div className="course-text">
                        <h3>{course.name}</h3>
                        <p>{course.description}</p>
                      </div>
                    </Card>
                  </Link> : null}
                  {!course.disabled ? null :
                    <Link className="buy-link" to={`/buy-course/${course.id}`}>
                      <div className="disabled">
                        <div className="lock-container">
                          <i aria-hidden="true"></i>
                        </div>
                      </div>
                    </Link>
                  }
                  {course.enabled !== false ? null :
                    <a className="buy-link">
                      <div className="disabled">
                        <div className="lock-container large">
                          <div>Coming Soon</div>
                        </div>
                      </div>
                    </a>
                  }
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  let allCourses = [];
  const completedCourses = [];
  let loadingFreeCourses = true;
  let loadingPaidCourses = true;

  if (state.freeCourses && state.freeCourses.courses && state.freeCourses.courses.length) {
    allCourses = [ ...allCourses, ...state.freeCourses.courses ];
    loadingFreeCourses = false;
  }

  if (state.courses && state.courses.length) {
    allCourses = [ ...allCourses, ...state.courses ];
    loadingPaidCourses = false;
  }

  if (allCourses.length) {
    allCourses.forEach(course => {
      if (course.modules && Array.isArray(course.modules) && state.user.academyCompletion[course.id]) {
        const completedModules = [];
        let completionAmount = 0;

        state.user.academyCompletion[course.id].forEach(id => {
          if (!completedModules.includes(id)) {
            completedModules.push(id);
          }
        });

        const numberOfModules = course.modules.reduce((acc, current) => {
          if (typeof current === 'object') {
            acc++;
          }

          return acc;
        }, 0);

        completionAmount = Math.round((completedModules.length / numberOfModules) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === '30-day-perfect-pup' && state.days.length && state.user.completedDays.length) {
        let completionAmount = 0;
        const uniqueCompletedDays = [];
    
        state.user.completedDays.forEach(day => {
          if (!uniqueCompletedDays.includes(day)) {
            uniqueCompletedDays.push(day);
          }
        });
    
        completionAmount = Math.round((uniqueCompletedDays.length / state.days.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === 'train-by-behavior' && state.behaviors.length && state.user.completedBehaviors.length) {
        let completionAmount = 0;
        const uniqueCompletedBehaviors = [];

        state.user.completedBehaviors.forEach(behavior => {
          if (!uniqueCompletedBehaviors.includes(behavior)) {
            uniqueCompletedBehaviors.push(behavior);
          }
        });

        completionAmount = Math.round((uniqueCompletedBehaviors.length / state.behaviors.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === 'new-dog-starter-course' && state.newDogSteps.length && state.user.completedNewDogSteps.length) {
        let completionAmount = 0;
        const uniqueCompletedNewDogSteps = [];

        state.user.completedNewDogSteps.forEach(steps => {
          if (!uniqueCompletedNewDogSteps.includes(steps)) {
            uniqueCompletedNewDogSteps.push(steps);
          }
        });

        completionAmount = Math.round((uniqueCompletedNewDogSteps.length / state.newDogSteps.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      }
    });
  }

  return {
    courses: allCourses,
    completedCourses,
    accessDenied: state.user.accessDenied,
    loadingCourses: loadingFreeCourses || loadingPaidCourses,
  };
};

export default connect(mapStateToProps, {
  setAccessDenied,
})(MyCourses);
