import {
  ADD_TO_CART,
  UPDATE_PRODUCT_QUANTITY,
  REMOVE_FROM_CART,
  REPOPULATE_CART,
  UPDATE_SHOPPING_CART,
} from './types';

export const addToCart = (product) => {
  return {
    type: ADD_TO_CART,
    payload: product,
  };
};

export const changeQuantity = (index, quantity) => {
  return {
    type: UPDATE_PRODUCT_QUANTITY,
    payload: {
      index,
      quantity,
    },
  };
};

export const removeFromCart = (index) => {
  return {
    type: REMOVE_FROM_CART,
    payload: index,
  };
};

export const repopulateCart = cart => {
  return {
    type: REPOPULATE_CART,
    payload: cart,
  };
};

export const updateShoppingCart = cart => {
  return {
    type: UPDATE_SHOPPING_CART,
    payload: cart,
  };
};
