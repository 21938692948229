import {
  SET_USER_TYPE,
  SET_USER_INFO,
  SET_USER_ZIP,
  SET_USER_DATA_TO_SYNC,
  SET_HAS_ACCESS_TO_ALL,
  SET_COURSES,
  SET_ACCESS_DENIED,
} from '../actions/types';

const defaultState = {
  academyCompletion: {},
  hasAccessToAll: false,
  coursesSet: false,
  accessDenied: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_USER_ZIP:
      return {
        ...state,
        zip: action.payload,
      };
    case SET_USER_DATA_TO_SYNC:
      return {
        ...state,
        userDataToSync: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...defaultState,
        ...action.payload,
      };
    case SET_HAS_ACCESS_TO_ALL:
      return {
        ...state,
        type: action.payload,
      };
    case SET_COURSES:
      return {
        ...state,
        coursesSet: true,
      };
    case SET_ACCESS_DENIED:
      return {
        ...state,
        accessDenied: action.payload,
      };
    default:
      return state;
  }
};
