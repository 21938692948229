import {
  SET_DOG_BREEDS,
} from './types';

export const setDogBreeds = (breeds) => {
  return {
    type: SET_DOG_BREEDS,
    payload: breeds,
  };
};
