import React, { useRef, useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from '../../../../firebase';

import MyCourses from '../myCourses/myCourses';
import Profile from '../profile/profile';
import Tabs from '../../layout/tabs/tabs';
import Loader from '../../shared/loader/loader';
import Modal from '../../shared/modal/modal';

import './home.scss';
import { setAccessDenied } from '../../../../actions';

function Home(props) {
  let location = useLocation();
  const fileInputEl = useRef(null);
  const [profileImage, setProfileImage] = useState('');
  const [profileImageLoaded, setProfileImageLoaded] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    // getUserProfileImage();

    if (props.accessDenied) {
      setAccessDenied(true);
      setTimeout(() => {
        props.setAccessDenied(false);
      }, 0);
    }
  }, []);

  const getUserProfileImage = () => {
    const userId = firebase.auth().currentUser.uid;
    const storageRef = firebase.storage().ref();

    storageRef.child(`${userId}/profile.jpg`).getDownloadURL().then((url) => {
      setProfileImage(url);
      setProfileImageLoaded(true);
    }).catch((error) => {
      setProfileImageLoaded(true);
    });
  };

  const uploadFilePressed = () => {
    fileInputEl.current.click();
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const userId = firebase.auth().currentUser.uid;

    if (file) {
      const storageRef = firebase.storage().ref();
      const profileImagesRef = storageRef.child(`${userId}/profile.jpg`);

      profileImagesRef.put(file).then(snapshot => {
        console.log('Uploaded.', snapshot);
        getUserProfileImage();
      }).catch((error) => {
        console.log('error uploading image');
      });
    }
  };

  const getProfileBackground = () => {
    // if (profileImageLoaded) {
    //   return { backgroundImage: `url(${profileImage || 'https://pupford.com/wp-content/uploads/2020/05/academy-main-2.jpg'})` };
    // }

    return { backgroundImage: `url(https://dwvecv5z9ozyt.cloudfront.net/academy/training+dashboard+hero+image+badge.jpg)` };

    // return {};
  };

  const activeTab = () => {
    if ((location.pathname.includes('find-a-trainer') || location.pathname.includes('profile')) ? '' : ' selected') {
      return 0;
    }

    if (location.pathname.includes('find-a-trainer') ? ' selected' : '') {
      return 1;
    }

    if (location.pathname.includes('profile') ? ' selected' : '') {
      return 2;
    }
  };

  const handleTabClick = (index) => {
    if (index === 0) {
      props.history.push('/courses');
    } else if (index === 1) {
      props.history.push('/home/find-a-trainer');
    } else {
      props.history.push('/home/profile');
    }
  };

  return (
    <div className="Home">
      <Tabs
        activeTab={activeTab()}
        tabs={['My Courses', 'Find a Trainer', 'Profile']}
        onTabClick={handleTabClick}
      ></Tabs>

      <div className="tab-content-container">
        <Switch>
          <Route path="/courses" render={(renderProps) => <MyCourses {...renderProps} courses={props.courses} completedCourses={props.completedCourses} />} />
          <Route path="/home/profile" component={Profile} />
          <Route render={(renderProps) => <MyCourses {...renderProps} courses={props.courses} completedCourses={props.completedCourses} />} />
        </Switch>
      </div>

      <Modal
        open={accessDenied}
        close={() => { setAccessDenied(false) }}
        title="Notice:"
        buttons={[
          <button key="go-to-academy" className="success" onClick={() => { window.open('https://pupford.com/pupford-academy/', '_blank') }}>Learn More</button>,
        ]}
      >
        <div>
          <div className="modal-text">You don't have access to the course you landed on. To get access, you can purchase the course by clicking on the course link below or get full access by purchasing an Academy membership.</div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  let allCourses = [];
  const completedCourses = [];

  if (state.freeCourses && state.freeCourses.courses && state.freeCourses.courses.length) {
    allCourses = [ ...allCourses, ...state.freeCourses.courses ];
  }

  if (state.courses && state.courses.length) {
    allCourses = [ ...allCourses, ...state.courses ];
  }

  if (allCourses.length) {
    allCourses.forEach(course => {
      if (course.modules && Array.isArray(course.modules) && state.user.academyCompletion[course.id]) {
        const completedModules = [];
        let completionAmount = 0;

        state.user.academyCompletion[course.id].forEach(id => {
          if (!completedModules.includes(id)) {
            completedModules.push(id);
          }
        });

        const numberOfModules = course.modules.reduce((acc, current) => {
          if (typeof current === 'object') {
            acc++;
          }

          return acc;
        }, 0);

        completionAmount = Math.round((completedModules.length / numberOfModules) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === '30-day-perfect-pup' && state.days.length && state.user.completedDays.length) {
        let completionAmount = 0;
        const uniqueCompletedDays = [];
    
        state.user.completedDays.forEach(day => {
          if (!uniqueCompletedDays.includes(day)) {
            uniqueCompletedDays.push(day);
          }
        });
    
        completionAmount = Math.round((uniqueCompletedDays.length / state.days.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === 'train-by-behavior' && state.behaviors.length && state.user.completedBehaviors.length) {
        let completionAmount = 0;
        const uniqueCompletedBehaviors = [];

        state.user.completedBehaviors.forEach(behavior => {
          if (!uniqueCompletedBehaviors.includes(behavior)) {
            uniqueCompletedBehaviors.push(behavior);
          }
        });

        completionAmount = Math.round((uniqueCompletedBehaviors.length / state.behaviors.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      } else if (course.id === 'new-dog-starter-course' && state.newDogSteps.length && state.user.completedNewDogSteps.length) {
        let completionAmount = 0;
        const uniqueCompletedNewDogSteps = [];

        state.user.completedNewDogSteps.forEach(steps => {
          if (!uniqueCompletedNewDogSteps.includes(steps)) {
            uniqueCompletedNewDogSteps.push(steps);
          }
        });

        completionAmount = Math.round((uniqueCompletedNewDogSteps.length / state.newDogSteps.length) * 100);

        if (completionAmount === 100) {
          completedCourses.push(course.id);
        }
      }
    });
  }

  return {
    courses: allCourses,
    completedCourses,
    accessDenied: state.user.accessDenied,
  };
};

export default connect(mapStateToProps, {
  setAccessDenied,
})(Home);
