

export const legacyFreeCourses = {
  'yKTAPoTaY60NFK23Uow0': {
    type: 'completedDays',
    moduleIdMap: {
      'fe28bff7-64c0-417d-97bf-fb96ee426080': 0,
      'f9a4180b-02e1-4e22-8afd-75abc81ac70c': 1,
      '8cfb397f-7c13-4894-81c5-b43e7b7dea6f': 2,
      'bb77b16c-9ab3-4568-9514-795d61d101e4': 3,
      'adf2bdb8-df8d-4dd4-b95e-0acd2a8d3051': 4,
      '5cc63c59-a203-4a58-a149-23137adf1368': 5,
      'd0f8bc25-cbcf-4c76-b3b8-50e16978a3b2': 6,
      '3d1fa735-d66e-4186-9c20-bf5ce3b3c151': 7,
      'cf005ca8-b5b8-473b-aa81-421456ec71de': 8,
      'cabf7151-c491-4f2e-a6a5-81d26e1a99e9': 9,
      '4c02e542-0c1e-46c2-a30c-d8e61195fecc': 10,
      'c1bb5c13-78f8-46e6-83c7-eb53bc8307e2': 11,
      '740c0e4f-ed12-4ce8-8fcf-d43c4c07e474': 12,
      '273f68a4-78fc-4407-ba56-1b49c61375d8': 13,
      '1243249d-0cb7-489c-9491-4414bc0a0676': 14,
      'b87663d1-fac3-4867-9d1c-35a12c222dc9': 15,
      'ca8cd874-58e9-4b46-bc68-1ca3dad2679e': 16,
      'bc065cab-b346-4196-99ae-e1a07c7a3b2d': 17,
      '856fc7cc-5f34-4c9f-86cf-00baf6a09967': 18,
      '24a705ed-6021-4990-ba54-4bb88a812169': 19,
      '54f9ef9d-dfa0-4e77-bb6e-fd1b38c35a48': 20,
      '877933e2-6145-47d5-91fe-c7959fc81886': 21,
      '09696a86-6e98-4907-beb5-a7147d6732b7': 22,
      'db5872a1-7040-47da-903d-503b65d8d7ae': 23,
      'ccdd7477-4932-4932-bd82-571e36743a21': 24,
      '5b0ba75a-cc02-4d1d-b065-44ab68d4183b': 25,
      '2878ac53-8b4b-4fe3-9d09-e8aaf52ebcf9': 26,
      '90b27e6e-c2a2-4dba-85da-895f726ed78c': 27,
      'c5edc6d8-a9ff-4969-b871-d655668aab21': 28,
      '23895ae1-4dba-4493-bb43-e7d2c64d5219': 29,
    },
  },
  'vjbxuv6pgC6b5U3Z0xDq': {
    type: 'completedBehaviors',
    moduleIdMap: {
      '4e6eb01e-b75e-4184-a287-b3a082d60516': 'dcde2737-1d67-4657-afc2-dc9cc7bdd53b',
      '95862a5d-9bfe-425d-b1bd-611398827119': '8f4cf7f0-57ed-449a-9e27-a0ba9be614fd',
      'af5f66a1-39e0-46a0-ab1c-620fdc1b18da': '4e2d42b8-d3b4-438f-b51d-17f88b09b38a',
      '657ac6ed-0382-4723-805c-e527977f8c50': '908bf27c-1e09-46d2-96f7-ec41dd53e26b',
      '1baa5e33-4593-4f53-b22e-d1115b693fb2': '88b1f4b5-d3ff-462c-9731-4468d484b381',
      '0592ff14-2a8e-4be1-815f-96ae5871e58b': '796d7591-bad9-47e5-ad18-6bcd0d40c821',
      '67e4c5f7-6cca-4d1a-9a93-80df8776a54b': '76a57f08-3099-424d-ab77-71f73a26ac63',
      '80dbb7b7-bea8-4d8f-bf53-136a02e26bc4': 'f4792017-4cb2-4ed8-94e7-25a3fe70ff45',
      '81776976-99e4-4626-b1f6-e9d8892db9a5': 'd894a87c-c04b-4162-b806-158d51685e65',
      'e34881e3-c7d9-4065-b122-1e5653702dac': 'bb8f6feb-4f62-42c7-ab1c-738dbb22a80c',
      '5f4191c6-00ea-4a8b-8494-47ad58a296f4': 'ce799fd2-9acb-4649-a505-f6010b174205',
      '3257368c-7b08-4354-9b9c-f475a3852935': '58189929-056f-4936-83d9-d9b1a880d6e0',
      'f4bc1d46-9cce-45c9-a370-2546e4869a71': '19824c71-710c-4d0c-8b8e-ec467e295ca7',
      'f0a5402c-52b9-4f04-910c-0630b5cef00f': '55f6e1db-7299-4136-a333-5437053f0856',
      '1ef57c49-7405-4f0f-9ed1-a1f210e782c8': '12d0a7c9-5d70-4bca-8ee5-7e4865f798b4',
      'ffa5919d-4e5c-4e21-b002-29e0ec150ccd': '74b454f6-64ba-46b1-9e22-61e84f6326a0',
      '17a5e281-da13-43b8-8614-ecb409e1d6cc': '5f052dcc-fa4a-4dbd-9093-9ab4992609d5',
      '551f750d-c26a-4dbb-a64a-3ac0265441c5': '2d4ba506-b77c-486b-bdbd-23aa6bc67820',
      'dd562925-0a7d-437b-a70f-663731bf83f4': 'a42c547a-f1f5-4780-9d75-914c00bd2355',
      '6168853b-c406-4a62-8956-74a769bebf21': '3286e62f-e261-4460-b0f9-c8149a6a3b75',
    },
  },
  'uTnOxsve4CoziFTKpdVw': {
    type: 'completedNewDogSteps',
    moduleIdMap: {
      '1e093c3d-6070-4b7c-b640-bf71465d80a1': '08be3ea3-8a7d-4e23-a382-1387f939dbcb',
      'e6a98d06-bb3b-48e6-a573-b4f293c22e02': '9e59fa1e-7a35-4bca-9807-859fa5cb2edb',
      '2e694f29-1914-4081-81e5-d9afaf54d084': '62282943-00c6-4c9e-bc90-25bdc470e1eb',
      '2c158fe9-da61-4f85-bcf7-cf91b5bbc4b0': '07a8ca29-0633-4dd1-a4fe-3e45ef6dd63c',
      '4b6c898f-2697-45c8-b384-33eb47c954fe': '89ceff47-57ee-4ac9-afab-324841858297',
      '1e05c600-37d8-402f-9a8b-7f3971d52246': 'ccac08bd-4bca-4d54-8258-a49f98d28512',
      'e43a158c-9670-4401-a32b-42e8a0b2656f': 'ec6d5599-b3b2-4ba8-9c73-ef6155a8fb1d',
      '813b5965-dfa0-4de2-b068-a2af54523ad6': 'fde710d9-d20f-4057-8aa7-bb6ff9323702',
      'b992ffc3-d966-4b1d-9762-6df311d68c38': '0c680c48-8838-4c69-9f1f-c87b74eb1979',
      '82845b37-a1a2-4780-8d20-0553a686e1cd': '39fddc74-35b3-4edb-9e95-404ad09b3fa7',
      'c47f01ac-d4ef-4207-852c-31f67084451f': '0b87764d-47ea-4e3b-b173-c5a4535548c5',
    },
  },
};
