import React from 'react';

import './passwordStrength.scss';
import checkMark from '../../../images/check-mark-green.svg';
import times from '../../../images/times-red.svg';

function PasswordStrength({ hasLowerCase, hasUpperCase, hasNumber, hasLength }) {
  return (
    <div className="PasswordStrength">
      <div className="password-strength-container-inner">
        <div className="password-strength-title">Password must have:</div>
        <div className={`password-strength-row${hasLowerCase ? ' valid' : ''}`}>
          <div>At least one lower case letter</div>
          {hasLowerCase ?
            <img src={checkMark} alt="check-mark" /> :
            <img src={times} alt="error" />
          }
        </div>
        <div className={`password-strength-row${hasUpperCase ? ' valid' : ''}`}>
          <div>At least one upper case letter</div>
          {hasUpperCase ?
            <img src={checkMark} alt="check-mark" /> :
            <img src={times} alt="error" />
          }
        </div>
        <div className={`password-strength-row${hasNumber ? ' valid' : ''}`}>
          <div>At least one number</div>
          {hasNumber ?
            <img src={checkMark} alt="check-mark" /> :
            <img src={times} alt="error" />
          }
        </div>
        <div className={`password-strength-row${hasLength ? ' valid' : ''}`}>
          <div>At least 8 characters</div>
          {hasLength ?
            <img src={checkMark} alt="check-mark" /> :
            <img src={times} alt="error" />
          }
        </div>
        <div className="password-reset-arrow"></div>
      </div>
    </div>
  );
}

export default PasswordStrength;
