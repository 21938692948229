import React from 'react';

import Loader from '../shared/loader/loader';
import NewUserOnboardingStep1 from './newUserOnboardingStep1/newUserOnboardingStep1';
import NewUserOnboardingStep2 from './newUserOnboardingStep2/newUserOnboardingStep2';
import NewUserOnboardingStep3 from './newUserOnboardingStep3/newUserOnboardingStep3';
import NewUserOnboardingStep4 from './newUserOnboardingStep4/newUserOnboardingStep4';

import './newUserOnboarding.scss';

function NewUserOnboarding(props) {

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return <NewUserOnboardingStep1 updateStep={props.updateStep} />;
      case 2:
        return <NewUserOnboardingStep2 updateStep={props.updateStep} />;
      case 3:
        return <NewUserOnboardingStep3 updateStep={props.updateStep} />;
      case 4:
        return <NewUserOnboardingStep4 {...props} updateStep={props.updateStep} />;
      default:
        return null;
    }
  }

  return (
    <div className="NewUserOnboarding">
      {!props.loading ? null :
        <div className="loading-container">
          <div className="spinner-container">
            <Loader />
          </div>
        </div>
      }
      <div className="content-container">
        {renderStep(props.step)}
      </div>
    </div>
  );
}

export default NewUserOnboarding;
