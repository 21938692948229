import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';
import barsIcon from '../../../../images/menu-bars.svg';
import pawIcon from '../../../../images/paw.svg';

function Header(props) {
  return (
    <div className="Header">
      <div className="header-left-container">
        <img src={barsIcon} onClick={props.openNav} alt="menu" className="menu-bars" />
        <Link to="/">
          <img
            src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
            alt=""
          />
        </Link>
      </div>

      <div className="user-info-container">
        <p>Welcome{props.userName ? `, ${props.userName}` : ''}</p>

        <Link to="/profile">
          <div className="profile-image-container">
            {!props.profileImageUrl ?
              <img src={pawIcon} alt="profile" className="paw-icon" /> :
              <img src={props.profileImageUrl} alt="User"/>
            }
          </div>
        </Link>

        <button className="cart-button" onClick={props.cartClicked}>
          {!props.cart || !props.cart.products || !props.cart.products.length ? null :
            <div className="cart-amount">
              <div className="cart-amount-inner">
                <div className="cart-amount-text">
                  {props.cart.products.reduce((acc, curr) => {
                    return acc += +curr.quantity;
                  }, 0)}
                </div>
              </div>
            </div>
          }

          <img src="https://pupford.b-cdn.net/website-content%2Fcart.png?alt=media&token=482e5d94-586f-400d-a1ea-29a60d4d92c8" />
        </button>
      </div>
    </div>
  );
}

export default Header;
