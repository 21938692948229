export const SET_FREE_COURSES = 'set_free_courses';
export const SET_FREE_COURSE_ID = 'set_free_course_id';
export const SET_FREE_CURRENT_MODULE_INDEX = 'set_free_current_module_index';
export const SET_COURSES = 'set_courses';
export const SET_USER_INFO = 'set_user_info';
export const SET_USER_TYPE = 'set_user_type';
export const SET_USER_ZIP = 'set_user_zip';
export const SET_USER_DATA_TO_SYNC = 'set_user_data_to_sync';
export const SET_HAS_ACCESS_TO_ALL = 'set_has_access_to_all';
export const SET_ACCESS_DENIED = 'set_access_denied';
export const SET_SIMPLIFIED_PRODUCTS = 'set_simplified_products';
export const SET_TRAINING_TOOLS = 'set_training_tools';
export const SET_SOUNDS = 'set_sounds';
export const SET_SOUNDS_ACCESS = 'set_sounds_access';
export const SET_BEHAVIORS = 'set_behaviors';
export const SET_DOG_BREEDS = 'set_dog_breeds';
export const SET_ACADEMY_COURSE_PRODUCTS = 'set_academy_course_products';
export const SET_SHOP_SETTINGS = 'set_shop_settings';
export const ADD_TO_CART = 'add_to_cart';
export const UPDATE_PRODUCT_QUANTITY = 'update_product_quantity';
export const UPDATE_SHOPPING_CART = 'update_shopping_cart';
export const REMOVE_FROM_CART = 'remove_from_cart';
export const REPOPULATE_CART = 'repopulate_cart';
