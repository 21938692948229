import React from 'react';

import './card.scss';

function Card({ children, backgroundColor = '#fff' }) {
  return (
    <div className="Card" style={{ backgroundColor }}>
      {children}
    </div>
  );
}

export default Card;
