import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import axios from 'axios';
import firebase from '../../../../firebase';

import {
  setUserInfo,
  setAccessDenied,
} from '../../../../actions';
import './module.scss';
import Card from '../../shared/card/card';
import Loader from '../../shared/loader/loader';
import Modal from '../../shared/modal/modal';
import PhysicalProductModal from '../../shared/physicalProductModal/physicalProductModal';
import CompleteModal from '../../shared/completeModal/completeModal';
import arrowsLeftIcon from '../../../../images/arrow-left.svg';

import { legacyFreeCourses } from './legacyFreeCourses';

function Module(props) {
  const [module, setModule] = useState(null);
  const [confirmUnmarkModalOpen, setConfirmUnmarkModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [popupModalOpen, setPopupModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    if (props.module && !module) {
      handleModule();
    }
  }, [props.module]);

  useEffect(() => {
    if (!props.module && props.user.coursesSet && props.hasLoadedFreeCourses) {
      setTimeout(() => {
        props.setAccessDenied(true);
        props.history.push('/courses');
      }, 0);
    }
  }, [props.module, props.user.coursesSet, props.hasLoadedFreeCourses]);

  const getVimeoEmbed = async (videoUrl) => {
    try {
      const videoUrlArr = videoUrl.split('/');
      let videoId;

      if (videoUrlArr[5] && !isNaN(parseInt(videoUrlArr[5]))) {
        videoId = videoUrlArr[5];
      } else {
        return '';
      }

      const result = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);

      if (!result || !result.data || !result.data.html) {
        return '';
      }

      const iframeDiv = document.createElement('div');
      iframeDiv.innerHTML = result.data.html;

      const iframe = iframeDiv.firstChild;
      iframe.height = '100%';
      iframe.width = '100%';

      return iframe.outerHTML;
    } catch (e) {
      console.log('error', e);
      return '';
    }
  };

  const handleModule = async () => {
    const updatedModule = {
      ...props.module,
    };

    const updatedSections = [];

    for (let i = 0; i < updatedModule.sections.length; i++) {
      const updatedSection = {
        ...updatedModule.sections[i],
      };

      if (updatedSection.entries && Array.isArray(updatedSection.entries)) {
        const updatedEntries = [];

        for (let j = 0; j < updatedSection.entries.length; j++) {
          const updatedEntry = {
            ...updatedSection.entries[j],
          };

          if (updatedEntry.type === 'video' && updatedEntry.value.includes('vimeo')) {
            updatedEntry.vimeoEmbed = await getVimeoEmbed(updatedEntry.value);
          }

          if (updatedEntry.entries && Array.isArray(updatedEntry.entries)) {
            const updatedNestedEntries = [];

            for (let k = 0; k < updatedEntry.entries.length; k++) {
              const updatedNestedEntry = {
                ...updatedEntry.entries[k],
              };

              if (updatedNestedEntry.type === 'video' && updatedNestedEntry.value.includes('vimeo')) {
                updatedNestedEntry.vimeoEmbed = await getVimeoEmbed(updatedNestedEntry.value);
              }

              updatedNestedEntries.push(updatedNestedEntry);
            }
            
            updatedEntry.entries = updatedNestedEntries;
          }

          updatedEntries.push(updatedEntry);
        }

        updatedSection.entries = updatedEntries;
      }

      updatedSections.push(updatedSection);
    }

    updatedModule.sections = updatedModule.sections.map(section => {
      const updatedSection = {
        ...section,
      };

      if (section.entries && Array.isArray(section.entries)) {
        updatedSection.entries = updatedSection.entries.map(entry => {
          return {
            ...entry,
          };
        });
      }

      return updatedSection;
    });

    updatedModule.sections = updatedSections;

    setModule(updatedModule);
  };

  if (!module) {
    return (
      <div className="ModuleLoader">
        <Loader />
      </div>
    );
  }

  const openPDF = (value) => {
    window.open(value, '_blank');
  };

  const renderSectionItem = (s, i, j, k) => {
    if (s.type === 'title') {
      return (
        <h2 style={{fontWeight: 'bold', fontSize: 18}}>
          {s.value}
        </h2>
      );
    } else if (s.type === 'subtitle') {
      return (
        <h3 style={{fontWeight: 'bold', fontSize: 15, color: 'rgba(0, 0, 0, .6)'}}>
          {s.value}
        </h3>
      );
    } else if (s.type === 'paragraph') {
      return (
        <p className="paragraph">
          {s.value}
        </p>
      );
    } else if (s.type === 'pdf') {
      return (
        <button
          onClick={() => { openPDF(s.value) }}
        >View PDF</button>
      );
    } else if (s.type === 'video') {
      if (s.vimeoEmbed) {
        return (
          <div className="video-container">
            <div className="video-inner" dangerouslySetInnerHTML={{__html: s.vimeoEmbed}}>
            </div>
          </div>
        );
      }

      return (
        <div className="video-container">
          <div className="video-inner">
            <video width="100%" height="100%" controls poster={s.thumbnail}>
              <source src={s.value} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      );
    } else if (s.type === 'youtube') {
      return (
        <div className="video-container">
          <div className="video-inner">
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${s.value}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      );
    } else if (s.type === 'image') {

      if (s.hideFromVersionsThatSupportLoopedVideo === true) {
        return null;
      }

      return <img src={s.value} width="100%" alt=""></img>;
    } else if (s.type === 'quiz') {
      return (
        <iframe title={`quiz-${i}-${j}`} src={s.value} frameBorder="0" width="100%" height="1000px" />
      );
    } else if (s.type === 'products') {
      const products = [[]];
  
      s.value.forEach((product) => {
        if (!product.url) {
          return;
        }
  
        products.forEach((p, i) => {
          if (p.length !== 3) {
            products[i].push(product);
  
            if (p.length === 3) {
              products.push([]);
            }
          }
        });
      });
  
      if (products[products.length - 1].length === 1) {
        products[products.length - 1].push({}, {});
      } else if (products[products.length - 1].length === 2) {
        products[products.length - 1].push({});
      }

      if (!products[0].length) {
        return null;
      } else {
        return (
          <>
            <div className="section-title">Tools You Need</div>

            <div className="products-container">
              {products.map((row, i) => {
                return (
                  <div className="row" key={`product-row-${i}`}>
                    {row.map((product, j) => {
                      return (
                        <div key={`product-${j}`} className="product-card">
                          {!product.name ? null :
                            <a onClick={() => {
                              setSelectedProduct({
                                id: product.id,
                                name: product.name,
                                image: {
                                  url: product.image,
                                },
                              });
                              setProductModalOpen(true);
                            }}>
                              <div className="product-container">
                                <div className="product-image" style={{backgroundImage: `url(${product.image})`}} />
                                <div className="product-text">
                                  <h3>{product.name}</h3>
                                </div>
                              </div>
                            </a>
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        );
      }
    } else if ( s.entries ) {
      return (
        <Collapsible trigger={<span><div>{s.sectionTitle}</div><i aria-hidden="true"></i></span>} transitionTime={150}>
          {s.entries.map((s, k) => {
            return (
              <div key={`nested-detail-section-${i}-${k}`} style={{marginTop: 6, marginBottom: 6}}>
                {renderSectionItem(s, i, j, k)}
              </div>
            );
          })}
        </Collapsible>
      );
    }
  };

  const handleUpdateCompletedState = async (isComplete) => {
    if (confirmUnmarkModalOpen) {
      setConfirmUnmarkModalOpen(false);
    }
    setSaving(true);

    const userItemsToUpdate = {};

    const courseCompletion = props.academyCompletion[props.courseId] || [];
    let courseCompletionCopy = [ ...courseCompletion ];

    if (isComplete) {
      courseCompletionCopy.push(module.id);
    } else {
      courseCompletionCopy = courseCompletionCopy.filter(item => {
        return item !== module.id;
      });

      if (legacyFreeCourses.hasOwnProperty(props.courseId) && props.user.hasOwnProperty(legacyFreeCourses[props.courseId].type)) {
        userItemsToUpdate[legacyFreeCourses[props.courseId].type] = props.user[legacyFreeCourses[props.courseId].type].filter(item => {
          return item !== legacyFreeCourses[props.courseId].moduleIdMap[module.id];
        });
      }
    }

    userItemsToUpdate.academyCompletion = {
      ...props.user.academyCompletion,
      [props.courseId]: courseCompletionCopy,
    };

    const userId = firebase.auth().currentUser.uid;

    try {
      const userDogsSnapshot = await firebase.firestore().collection('user-dogs').doc(userId).get();
      const userDogsData = userDogsSnapshot.data();

      if (userDogsData && userDogsData.list) {
        const dogs = userDogsData.list;

        const currentEditDogIndex = dogs.findIndex(d => d.dogName === props.user.dogName);

        if (currentEditDogIndex !== -1) {
          dogs[currentEditDogIndex] = {
            ...dogs[currentEditDogIndex],
            academyCompletion: userItemsToUpdate.academyCompletion,
          };

          await firebase.firestore().collection('user-dogs').doc(userId).set({
            list: dogs,
          });
        }
      }
    } catch (e) {
      setSaving(false);
      setModalTitle('Error:');
      setModalText('There was an error updating your pup\'s profile, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
      return;
    }

    const userRef = firebase.firestore().collection('users').doc(userId);

    try {
      await userRef.update(userItemsToUpdate);

      props.setUserInfo({
        ...props.user,
        ...userItemsToUpdate,
      });

      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <div className="Module">
      <Card>
        <div className="header">
          <div className="main-image" style={{backgroundImage: `url(${module.backgroundImage})`}}>
          </div>
          <div className="filter-container">
            <div className="filter-text">
              <h1>{module.title}</h1>
              <p>{module.description}</p>
            </div>
          </div>
        </div>

        <div className="back-header-bar">
          <Link className="back-button" to={`/course/${props.match.params.id}`}>
            <img src={arrowsLeftIcon} alt="back" className="back-icon" />
            <p>Back to {props.courseName}</p>
          </Link>
          {saving ? <Loader className="saving" size={1} /> : (props.completed ?
            <button className="success" onClick={() => { setConfirmUnmarkModalOpen(true) }}>Completed</button> :
            <button onClick={() => {
              handleUpdateCompletedState(true);

              if (module.popup) {
                setPopupModalOpen(true);
              }
            }}>Mark Completed</button>)
          }
        </div>
      </Card>

      <div className="module-container">
        {module.sections.map((s, i) => {
          return (
            <div key={`main-section-${i}`} className="main-section">
              <Card>
                <div className="main-section-inner">
                  <h2>{s.sectionTitle}</h2>

                  {s.entries.map((s, j) => {
                    return (
                      <div key={`detail-section-${i}-${j}`}>
                        {renderSectionItem(s, i, j)}
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          );
        })}
      </div>

      <CompleteModal
        open={popupModalOpen}
        close={() => { setPopupModalOpen(false); }}
        content={module.popup || {}}
        actionPressed={() => {
          setPopupModalOpen(false);
          window.open(module.popup.webURL, '_blank');
        }}
      />

      <Modal
        open={confirmUnmarkModalOpen}
        close={() => { setConfirmUnmarkModalOpen(false) }}
        title="Mark Incomplete?"
        buttons={[
          <button key="complete-confirm" onClick={() => { handleUpdateCompletedState(false) }}>Confirm</button>,
          <button key="complete-cancel" onClick={() => { setConfirmUnmarkModalOpen(false) }} className="secondary">Cancel</button>,
        ]}
      >
        <div>
          <div>Are you sure you want to mark this module as incomplete?</div>
        </div>
      </Modal>

      <Modal
        open={productModalOpen}
        close={() => {
          setProductModalOpen(false);
          setSelectedProduct({});
        }}
        title=""
        buttons={[]}
      >
        {!productModalOpen ? null :
          <PhysicalProductModal
            simplifiedProduct={selectedProduct}
            close={() => {
              setProductModalOpen(false);
              setSelectedProduct({});
            }}
          />
        }
      </Modal>

      <Modal
        open={!!modalText}
        close={() => setModalText('')}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => setModalText('')}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state, otherProps) => {
  let module;
  let courseName;
  let courseId;
  let completed = false;
  let course = state.courses.find(c => c.id === otherProps.match.params.id);
  const academyCompletion = state.user.academyCompletion;
  let hasLoadedFreeCourses = false;

  if (!course) {
    course = state.freeCourses.courses.find(c => c.webPath === otherProps.match.params.id);

    if (state.freeCourses.courses.length) {
      hasLoadedFreeCourses = true;
    }
  }

  if (course && course.modules && course.modules.length) {
    module = course.modules[+otherProps.match.params.module];
    courseName = course.name;
    courseId = course.id;

    if (academyCompletion[course.id] && Array.isArray(academyCompletion[course.id]) && module && academyCompletion[course.id].includes(module.id)) {
      completed = true;
    }
  }

  return {
    module,
    courseName,
    courseId,
    completed,
    academyCompletion,
    hasLoadedFreeCourses,
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  setUserInfo,
  setAccessDenied,
})(Module);
