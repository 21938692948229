import { textItems } from './shared';

export const h1 = {
  title: 'H1',
  ...textItems,
  styles: {
    ...textItems.styles,
    fontSize: '24px',
  },
};
