import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader/loader';
import axios from 'axios';

import './shop.scss';

const priceFilters = {
  name: 'Price',
  options: [
    {
      name: 'Less than $10',
      low: 0,
      high: 10,
    },
    {
      name: '$10 to $20',
      low: 10,
      high: 20,
    },
    {
      name: '$20 to $30',
      low: 20,
      high: 30,
    },
    {
      name: '$30 to $40',
      low: 30,
      high: 40,
    },
    {
      name: '$40 to $50',
      low: 40,
      high: 50,
    },
    {
      name: '$50 & Above',
      low: 50,
      high: 100000,
    },
  ],
};


export default function Shop({ }) {
  const [results, setResults] = useState([]);
  const [productsInitialized, setProductsInitialized] = useState(true);
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState('featured');
  const [customFilters, setCustomFilters] = useState([]);
  const [secondaryFilters, setSecondaryFilters] = useState([]);
  const [filters, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [secondaryFilter, setSecondaryFilter] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`https://us-central1-pupford-6b94c.cloudfunctions.net/webApi/products-simplified/v2/shop`);
        const filtersRes = await axios.get(`https://us-central1-pupford-6b94c.cloudfunctions.net/webApi/product-filters`);
        const data = res.data;
        const fetchedProducts = data.filter((product) => {
          return product.active;
        });
        const fetchedFilters = filtersRes.data;

        setProducts(fetchedProducts);
        setFilter(fetchedFilters.filters);

      } catch (e) {
        console.log('error', e);
      }
      setLoading(false);
    };

    if (!products.length) {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    if (products && Array.isArray(products)) {
      const uniqueCategories = [];
      const mappedProducts = products.filter(p => {
        return p.showOnShopPage || !p.hasOwnProperty('showOnShopPage');
      }).map((p, i) => {
        p.categories.forEach(c => {
          if (!uniqueCategories.includes(c)) {
            uniqueCategories.push(c);
          }
        });

        const filterPrice = !(p.isOnSale || p.lowestSubscriptionPrice) ? +p.price :
          (p.lowestSubscriptionPrice ? +p.lowestSubscriptionPrice : +p.salePrice);

        return {
          ...p,
          filterPrice,
          featuredIndex: i,
        };
      });

      setCustomFilters([
        priceFilters,
        ...filters.map(f => {
          const filterCopy = { ...f };
          filterCopy.options = filterCopy.options.map(o => {
            return { name: o };
          });
          return filterCopy;
        }),
      ]);
      setResults(mappedProducts);
      setProducts(mappedProducts);
      setProductsInitialized(true);
    }
  }, [filterBy, filters]);

  useEffect(() => {
    if (productsInitialized) {
      let productsCopy = [...products];

      if (filterBy && secondaryFilter) {
        if (filterBy === 'Price') {
          const foundPriceRange = priceFilters.options.find(o => o.name === secondaryFilter);

          productsCopy = productsCopy.filter(p => {
            return p.filterPrice >= foundPriceRange.low && p.filterPrice < foundPriceRange.high;
          });
        } else {
          productsCopy = productsCopy.filter(p => {
            if (!p.filters.length) {
              return false;
            } else {
              const foundFilter = p.filters.find(productFilter => {
                return productFilter.name === filterBy && productFilter.selected.includes(secondaryFilter);
              });

              return foundFilter !== undefined;
            }
          });
        }
      }

      if (sort === 'featured') {
        productsCopy.sort((a, b) => {
          return a.featuredIndex - b.featuredIndex;
        });
      }

      if (sort === 'alphabetical') {
        productsCopy.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      }

      if (sort === 'priceLowHigh') {
        productsCopy.sort((a, b) => {
          return parseFloat(a.filterPrice) - parseFloat(b.filterPrice);
        });
      }

      if (sort === 'priceHighLow') {
        productsCopy.sort((a, b) => {
          return parseFloat(b.filterPrice) - parseFloat(a.filterPrice);
        });
      }

      setResults(productsCopy);
    }
  }, [sort, productsInitialized, products, secondaryFilter]);

  return (
    <div className="categoryShopPageContent">
      {loading ?

        <div className="loading-container">
          <Loader size={2} />
          <p>Loading Shop...</p>
        </div> :
        <div className="productsContainer">
          <div className="topRowContainer">
            <div className="filtersContainer">
              <div>
                <label htmlFor="filter-element">Filter by:</label>
                <div className="filtersSelectContainer">
                  <select id="filter-element" value={filterBy} onChange={(e) => {
                    setFilterBy(e.target.value);

                    if (e.target.value) {
                      const foundFilter = customFilters.find(f => f.name === e.target.value);
                      setSecondaryFilter(foundFilter.options[0].name);
                      setSecondaryFilters(foundFilter.options);
                    } else {
                      setSecondaryFilters([]);
                      setSecondaryFilter('');
                    }
                  }}>
                    <option value={''}>None</option>
                    {customFilters.map(f => {
                      return (
                        <option key={f.name} value={f.name}>{f.name}</option>
                      );
                    })}
                  </select>
                  {!filterBy ? null :
                    <select value={secondaryFilter} onChange={(e) => { setSecondaryFilter(e.target.value) }}>
                      {secondaryFilters.map(f => {
                        return (
                          <option key={f.name} value={f.name}>{f.name}</option>
                        );
                      })}
                    </select>
                  }
                </div>
              </div>
            </div>

            <div className="sortContainer">
              <label htmlFor="sort-element">Sort by:</label>
              <select id="sort-element" value={sort} onChange={(e) => { setSort(e.target.value) }}>
                <option value={'featured'}>Featured</option>
                <option value={'alphabetical'}>A-Z</option>
                <option value={'priceLowHigh'}>Price: Low to High</option>
                <option value={'priceHighLow'}>Price: High to Low</option>
              </select>
            </div>
          </div>

          {(productsInitialized && !results.length) ?
            <p className="noResultsContainer">
              There are no results for the current selection
            </p> : null
          }

          <div className="productsRow">
            {results.map(product => {
              return (
                <div className="productContainer" key={product.id}>
                  <a href={`https://pupford.com/product/${product.path}/`} target="_blank">
                    <div className="productInner">
                      <div className="productTopContainer">
                        <img src={product.image && product.image.url} alt={product.shopPageName || product.name} />
                        <div className="productDetails">
                          <p className="productName">{product.shopPageName || product.name}</p>
                          <div className="productPrice">
                            {!product.isGiftCard ?
                              <>
                                {product.useCustomizableBundleProductsPricing ?
                                  <>
                                    <p className="salePrice">
                                      Price Varies
                                    </p>
                                  </> :
                                  <>
                                    {parseFloat(product.price) === 0 ?
                                      <p className='salePrice'>
                                        Try Free - Just Cover Shipping
                                      </p> :
                                      <p className={`regularPrice ${product.isOnSale || product.lowestSubscriptionPrice ? 'strikeThrough' : ''}`}>${product.price}</p>
                                    }
                                  </>
                                }
                              </> :
                              null
                            }
                            {!(product.isOnSale || product.lowestSubscriptionPrice) || product.useCustomizableBundleProductsPricing ? null :
                              <p className={`salePrice ${product.isOnSale || product.lowestSubscriptionPrice ? 'strikeThrough' : ''}`}>
                                {!product.lowestSubscriptionPrice ? null : <span>From </span>}
                                ${product.lowestSubscriptionPrice ? (+product.lowestSubscriptionPrice).toFixed(2) : product.salePrice}
                                {product.lowestSubscriptionPrice ? null : <span className='saleBox'>Sale</span>}
                              </p>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="shopButtonContainer">
                        <button className="shopButtonMainContainer">
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
}
