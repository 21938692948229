import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import firebase from '../../firebase';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import {
  setCourses,
  setUserInfo,
  setHasAccessToAll,
  setFreeCourses,
  repopulateCart,
  changeQuantity,
  removeFromCart,
} from '../../actions';
import closeIcon from '../../images/times.svg'
import Header from './layout/header/header';
import ShoppingCart from './layout/shoppingCart/shoppingCart';
import Loader from './shared/loader/loader';
import Dashboard from './sections/dashboard/dashboard';
import Profile from './sections/profile/profile';
import MyCourses from './sections/myCourses/myCourses';
import BuyCourse from './sections/buyCourse/buyCourse';
import Home from './sections/home/home';
import Course from './sections/course/course';
import Shop from './sections/shop/shop';
import Sounds from './sections/sounds/sounds';
import Module from './sections/module/module';
import NewUserOnboarding from './newUserOnboarding/newUserOnboarding';
import Modal from './shared/modal/modal';
import Nav from './layout/nav/nav';

import './authed.scss';
import { baseURL } from '../../config';

const academyBonusId = 'academy-bonus-content';

const behaviorsOldNewIdsMap = {
  'dcde2737-1d67-4657-afc2-dc9cc7bdd53b': '4e6eb01e-b75e-4184-a287-b3a082d60516',
  '8f4cf7f0-57ed-449a-9e27-a0ba9be614fd': '95862a5d-9bfe-425d-b1bd-611398827119',
  '4e2d42b8-d3b4-438f-b51d-17f88b09b38a': 'af5f66a1-39e0-46a0-ab1c-620fdc1b18da',
  '908bf27c-1e09-46d2-96f7-ec41dd53e26b': '657ac6ed-0382-4723-805c-e527977f8c50',
  '88b1f4b5-d3ff-462c-9731-4468d484b381': '1baa5e33-4593-4f53-b22e-d1115b693fb2',
  '796d7591-bad9-47e5-ad18-6bcd0d40c821': '0592ff14-2a8e-4be1-815f-96ae5871e58b',
  '76a57f08-3099-424d-ab77-71f73a26ac63': '67e4c5f7-6cca-4d1a-9a93-80df8776a54b',
  'f4792017-4cb2-4ed8-94e7-25a3fe70ff45': '80dbb7b7-bea8-4d8f-bf53-136a02e26bc4',
  'd894a87c-c04b-4162-b806-158d51685e65': '81776976-99e4-4626-b1f6-e9d8892db9a5',
  'bb8f6feb-4f62-42c7-ab1c-738dbb22a80c': 'e34881e3-c7d9-4065-b122-1e5653702dac',
  'ce799fd2-9acb-4649-a505-f6010b174205': '5f4191c6-00ea-4a8b-8494-47ad58a296f4',
  '58189929-056f-4936-83d9-d9b1a880d6e0': '3257368c-7b08-4354-9b9c-f475a3852935',
  '19824c71-710c-4d0c-8b8e-ec467e295ca7': 'f4bc1d46-9cce-45c9-a370-2546e4869a71',
  '55f6e1db-7299-4136-a333-5437053f0856': 'f0a5402c-52b9-4f04-910c-0630b5cef00f',
  '12d0a7c9-5d70-4bca-8ee5-7e4865f798b4': '1ef57c49-7405-4f0f-9ed1-a1f210e782c8',
  '74b454f6-64ba-46b1-9e22-61e84f6326a0': 'ffa5919d-4e5c-4e21-b002-29e0ec150ccd',
  '5f052dcc-fa4a-4dbd-9093-9ab4992609d5': '17a5e281-da13-43b8-8614-ecb409e1d6cc',
  '2d4ba506-b77c-486b-bdbd-23aa6bc67820': '551f750d-c26a-4dbb-a64a-3ac0265441c5',
  'a42c547a-f1f5-4780-9d75-914c00bd2355': 'dd562925-0a7d-437b-a70f-663731bf83f4',
  '3286e62f-e261-4460-b0f9-c8149a6a3b75': '6168853b-c406-4a62-8956-74a769bebf21'
};

const newDogOldNewIdsMap = {
  '08be3ea3-8a7d-4e23-a382-1387f939dbcb': '1e093c3d-6070-4b7c-b640-bf71465d80a1',
  '9e59fa1e-7a35-4bca-9807-859fa5cb2edb': 'e6a98d06-bb3b-48e6-a573-b4f293c22e02',
  '62282943-00c6-4c9e-bc90-25bdc470e1eb': '2e694f29-1914-4081-81e5-d9afaf54d084',
  '07a8ca29-0633-4dd1-a4fe-3e45ef6dd63c': '2c158fe9-da61-4f85-bcf7-cf91b5bbc4b0',
  '89ceff47-57ee-4ac9-afab-324841858297': '4b6c898f-2697-45c8-b384-33eb47c954fe',
  'ccac08bd-4bca-4d54-8258-a49f98d28512': '1e05c600-37d8-402f-9a8b-7f3971d52246',
  'ec6d5599-b3b2-4ba8-9c73-ef6155a8fb1d': 'e43a158c-9670-4401-a32b-42e8a0b2656f',
  'fde710d9-d20f-4057-8aa7-bb6ff9323702': '813b5965-dfa0-4de2-b068-a2af54523ad6',
  '0c680c48-8838-4c69-9f1f-c87b74eb1979': 'b992ffc3-d966-4b1d-9762-6df311d68c38',
  '39fddc74-35b3-4edb-9e95-404ad09b3fa7': '82845b37-a1a2-4780-8d20-0553a686e1cd',
  '0b87764d-47ea-4e3b-b173-c5a4535548c5': 'c47f01ac-d4ef-4207-852c-31f67084451f'
};

const perfectPupOldNewIdsMap = {
  '0': 'fe28bff7-64c0-417d-97bf-fb96ee426080',
  '1': 'f9a4180b-02e1-4e22-8afd-75abc81ac70c',
  '2': '8cfb397f-7c13-4894-81c5-b43e7b7dea6f',
  '3': 'bb77b16c-9ab3-4568-9514-795d61d101e4',
  '4': 'adf2bdb8-df8d-4dd4-b95e-0acd2a8d3051',
  '5': '5cc63c59-a203-4a58-a149-23137adf1368',
  '6': 'd0f8bc25-cbcf-4c76-b3b8-50e16978a3b2',
  '7': '3d1fa735-d66e-4186-9c20-bf5ce3b3c151',
  '8': 'cf005ca8-b5b8-473b-aa81-421456ec71de',
  '9': 'cabf7151-c491-4f2e-a6a5-81d26e1a99e9',
  '10': '4c02e542-0c1e-46c2-a30c-d8e61195fecc',
  '11': 'c1bb5c13-78f8-46e6-83c7-eb53bc8307e2',
  '12': '740c0e4f-ed12-4ce8-8fcf-d43c4c07e474',
  '13': '273f68a4-78fc-4407-ba56-1b49c61375d8',
  '14': '1243249d-0cb7-489c-9491-4414bc0a0676',
  '15': 'b87663d1-fac3-4867-9d1c-35a12c222dc9',
  '16': 'ca8cd874-58e9-4b46-bc68-1ca3dad2679e',
  '17': 'bc065cab-b346-4196-99ae-e1a07c7a3b2d',
  '18': '856fc7cc-5f34-4c9f-86cf-00baf6a09967',
  '19': '24a705ed-6021-4990-ba54-4bb88a812169',
  '20': '54f9ef9d-dfa0-4e77-bb6e-fd1b38c35a48',
  '21': '877933e2-6145-47d5-91fe-c7959fc81886',
  '22': '09696a86-6e98-4907-beb5-a7147d6732b7',
  '23': 'db5872a1-7040-47da-903d-503b65d8d7ae',
  '24': 'ccdd7477-4932-4932-bd82-571e36743a21',
  '25': '5b0ba75a-cc02-4d1d-b065-44ab68d4183b',
  '26': '2878ac53-8b4b-4fe3-9d09-e8aaf52ebcf9',
  '27': '90b27e6e-c2a2-4dba-85da-895f726ed78c',
  '28': 'c5edc6d8-a9ff-4969-b871-d655668aab21',
  '29': '23895ae1-4dba-4493-bb43-e7d2c64d5219'
};

function Authed(props) {
  const userInfo = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const [pathName, setPathName] = useState('');
  const [stepLoading, setStepLoading] = useState(false);
  const [addingUserToPerfectPup, setAddingUserToPerfectPup] = useState(false);
  const [showAddUserToPerfectPupDayEmailConfirmation, setShowAddUserToPerfectPupDayEmailConfirmation] = useState(false);
  const [navClosed, setNavClosed] = useState(true);
  const [cartOpen, setCartOpen] = useState(false);
  const [cartInitialized, setCartInitialized] = useState(false);
  const [numberOfProductsInCart, setNumberOfProductsInCart] = useState(0);

  useEffect(() => {
    let shouldScroll = true;
    if (props.location.pathname.includes('/home/') && pathName.includes('/home/')) {
      shouldScroll = false;
    }

    if (shouldScroll) {
      window.scrollTo(0, 0);
    }

    setPathName(props.location.pathname);
  }, [props.location.pathname]);

  useEffect(() => {
    const currentUser = firebase.auth().currentUser.uid;

    async function getUserInfo() {
      const db = firebase.firestore();

      const completionRef = db.collection('course-completion-ads').doc('ads');
      let popups = [];

      try {
        const querySnapshot = await completionRef.get();
        const popupData = querySnapshot.data().data;
        const popupMap = {};

        popupData.forEach(popup => {
          if (!popupMap[popup.id]) {
            popupMap[popup.id] = popup;
          }
        });

        popups = popupMap;
      } catch (err) {
        // continue without popups
      }

      try {
        const snapshot = await db.collection('free-courses').where('enabled', '==', true).get();
  
        const freeCourses = snapshot.docs.map(doc => {
          const data = doc.data();
          const modules = data.modules.map(module => {
            return {
              ...module,
              popup: popups[module.adId] || null,
            };
          });
  
          return {
            ...data,
            modules,
            id: doc.id,
          };
        }).sort((a, b) => a.position - b.position);
  
        props.setFreeCourses(freeCourses);
      } catch (e) {
        console.log(e.toString());
      }

      try {
        const docRef = db.collection('users').doc(currentUser);
        const doc = await docRef.get();
        const info = doc.data();

        if (!info) {
          return;
        }

        const userData = updateCompletedFreeCourses(info);

        props.setUserInfo(userData);

        const daysDoc = await db.collection('days').get();
        const daysData = daysDoc.docs[0].data();

        let days = daysData.days;
        let behaviors = daysData.behaviors;
        let newDogSteps = daysData.newPup;

        if (popups) {
          days = days.map(day => {
            day.popup = popups[day.adId] || null;
            return day;
          });

          behaviors = behaviors.map(behavior => {
            behavior.popup = popups[behavior.adId] || null;
            return behavior;
          });

          newDogSteps = newDogSteps.map(step => {
            step.popup = popups[step.adId] || null;
            return step;
          });
        }

        setUserInfo(info);
        // setLoading(false);
      } catch (e) {
        console.log(e.toString());
      }

    }

    getUserInfo();
    fetCourses(currentUser);
    getShoppingCart(currentUser);
  }, []);

  useEffect(() => {
    if (cart.products) {
      if (cart.products.length > numberOfProductsInCart && cartInitialized) {
        setCartOpen(true);
      }

      setNumberOfProductsInCart(cart.products.length);
    }
  }, [cart, cartInitialized, numberOfProductsInCart]);

  const updateCompletedFreeCourses = (user) => {
    const perfectPupID = 'yKTAPoTaY60NFK23Uow0';
    const behaviorId = 'vjbxuv6pgC6b5U3Z0xDq';
    const newDogId = 'uTnOxsve4CoziFTKpdVw';

    if (user) {
      if (!user.academyCompletion) {
        user.academyCompletion = {};
      }

      if (user.completedDays && Array.isArray(user.completedDays) && user.completedDays.length) {
        if (!user.academyCompletion[perfectPupID]) {
          user.academyCompletion[perfectPupID] = [];
        }

        user.completedDays.forEach(module => {
          const id = perfectPupOldNewIdsMap[module];
          if (!user.academyCompletion[perfectPupID].includes(id)) {
            user.academyCompletion[perfectPupID].push(id);
          }
        });
      }

      if (user.completedBehaviors && Array.isArray(user.completedBehaviors) && user.completedBehaviors.length) {
        if (!user.academyCompletion[behaviorId]) {
          user.academyCompletion[behaviorId] = [];
        }

        user.completedBehaviors.forEach(module => {
          const id = behaviorsOldNewIdsMap[module];
          if (!user.academyCompletion[behaviorId].includes(id)) {
            user.academyCompletion[behaviorId].push(id);
          }
        });
      }

      if (user.completedNewDogSteps && Array.isArray(user.completedNewDogSteps) && user.completedNewDogSteps.length) {
        if (!user.academyCompletion[newDogId]) {
          user.academyCompletion[newDogId] = [];
        }

        user.completedNewDogSteps.forEach(module => {
          const id = newDogOldNewIdsMap[module];
          if (!user.academyCompletion[newDogId].includes(id)) {
            user.academyCompletion[newDogId].push(id);
          }
        });
      }
    }

    return user;
  };

  const fetCourses = async (currentUser) => {
    try {
      const result = await axios.get(`${baseURL}/courses/user/${currentUser}`);
      const response = await axios.post(`${baseURL}/courses/simplified`, {
        email: '',
      });

      if (result && result.data && result.data.courses && Array.isArray(result.data.courses)) {
        const accessIds = result.data.courses.map(course => course.id);

        if (response && response.data && Array.isArray(response.data)) {
          response.data.forEach(course => {
            if (!accessIds.includes(course.id)) {
              result.data.courses.push({
                ...course,
                backgroundImage: course.image,
                disabled: true,
              });
            }
          });
        }

        result.data.courses = result.data.courses.filter(course => {
          return !course.retired;
        });

        if (result.data.hasAccessToAll) {
          props.setHasAccessToAll(true);
          try {
            const db = firebase.firestore();
            const querySnapshot = await db.collection('academy-bonus').where('enabled', '==', true).get();
            const postsData = querySnapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            });

            const moduleMap = {};

            postsData.forEach(post => {
              if (!moduleMap.hasOwnProperty(post.category)) {
                moduleMap[post.category] = [post];
              } else {
                moduleMap[post.category].push(post);
              }
            });

            let modules = [];

            for (let key in moduleMap) {
              modules.push(`${key[0].toUpperCase() + key.slice(1)}s`);
              modules = modules.concat(moduleMap[key]);
            }

            props.setCourses([
              ...result.data.courses,
              {
                backgroundImage: 'https://pupford.com/wp-content/uploads/2020/06/dog-on-pink-background.jpg',
                description: 'Extra Videos, Podcasts, and Articles Exclusively for Academy Members',
                name: 'Academy Bonus Content',
                modules,
                id: academyBonusId,
                moduleCount: modules.filter(m => typeof m !== 'string').length
              },
            ]);
          } catch (e) {
            props.setCourses(result.data.courses);
          }
        } else {
          props.setCourses(result.data.courses);
        }

      }
    } catch (e) {
      console.log(e.toString());
    }
  };

  const getShoppingCart = async (userId) => {
    if (cart.userId) {
      return;
    }

    try {
      const userCartRef = await firebase.firestore().collection('shopping-carts').doc(userId).get();
      const cartData = userCartRef.data();

      if (cartData) {
        props.repopulateCart({
          ...cartData,
          userId,
        });
      }

      setCartInitialized(true);
    } catch (e) {
      setCartInitialized(true);
      return;
    }
  };

  const updateOnboardingStep = async (step) => {
    setStepLoading(true);

    try {
      const currentUser = firebase.auth().currentUser.uid;
      const db = firebase.firestore();
      const docRef = db.collection('users').doc(currentUser);
      await docRef.update({
        webOnboardingStep: step,
      });
    } catch (e) {
      // continue
    }

    setStepLoading(false);
    const info = {
      ...props.user,
      webOnboardingStep: step,
    };
    props.setUserInfo(info);
    setUserInfo(info);
  };

  const addUserToPerfectPup = async (addToList) => {
    if (addingUserToPerfectPup) {
      return;
    }

    setAddingUserToPerfectPup(true);

    const user = firebase.auth().currentUser;
    const userRef = firebase.firestore().collection('users').doc(user.uid);

    let isInternational = false;

    try {
      const result = await axios.get(`https://freegeoip.app/json/`);
      const countryCode = result.data.country_code;

      if (countryCode !== 'US' && countryCode !== 'CA') {
        isInternational = true;
      }
    } catch (e) {
      console.log('error getting country code', e);
      // continue on with isInternational set to false
    }

    try {
      const doc = await userRef.get();
      const userData = doc.data();

      if (!process || !process.env || !process.env.NODE_ENV || process.env.NODE_ENV !== 'development' && addToList) {
        await axios.post('https://api-pupford.b-cdn.net/webApi/add-user-to-track-list', {
          email: user.email,
          firstName: userData.userName,
          dogName: userData.dogName,
          selectedPath: 'Full Class',
          isInternational,
        });
      }

      await userRef.update({ selectedPath: 'full' });
    } catch (e) {
      // continue navigating to training calendar
    }

    props.setUserInfo({
      ...props.user,
      selectedPath: 'full',
    });

    setAddingUserToPerfectPup(false);
    props.history.push('/course/30-day-perfect-pup');
  };

  if (props.user && props.user.webOnboardingStep) {
    return (
      <NewUserOnboarding
        {...props}
        loading={stepLoading}
        step={props.user.webOnboardingStep}
        updateStep={updateOnboardingStep}
      />
    );
  }

  return (
    <div className="Authed">

      <Header
        profileImageUrl={(userInfo.profileImage && userInfo.profileImage.url) || ''}
        userName={userInfo.userName}
        openNav={() => setNavClosed(false)}
        cart={cart}
        cartClicked={() => setCartOpen(true)}
      />

      <ShoppingCart
        cart={cart}
        open={cartOpen}
        closeNav={() => setCartOpen(false)}
        changeQuantity={props.changeQuantity}
        removeFromCart={props.removeFromCart}
      />

      <div className="main-container">
        <div className="nav-container">
          <div className={`nav-inner${navClosed ? ' closed' : ''}`}>
            <div className="logo-container">
              <img onClick={() => setNavClosed(true)} src={closeIcon} alt="close" className="close"/>
              <Link to="/">
                <img
                  className="logo"
                  src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
                  alt=""
                />
              </Link>
            </div>

            <Nav
              logOut={props.logOut}
              closeNav={() => setNavClosed(true)}
            />
          </div>
        </div>

        <div className="content-container">
          {props.hasCompletedNewDogCourse && props.user.selectedPath !== 'full' ?
            <div className="perfect-pup-banner">
              <div className="perfect-pup-message">
                You have completed the New Dog Starter Course. Continue your training with 30 Day Perfect Pup. {addingUserToPerfectPup ? <Loader size={1} /> : <span onClick={() => { setShowAddUserToPerfectPupDayEmailConfirmation(true) }}>Click here to get started</span>}
              </div>
            </div> : null
          }
          <div className="content-inner">
            <Switch>
              {/* <Route path="/home" component={Home} /> */}
              <Route path="/course/:id/:module" component={Module} />
              <Route path="/course/:id" component={Course} />
              <Route path="/profile" component={Profile} />
              <Route path="/courses" component={MyCourses} />
              <Route path="/buy-course/:id" component={BuyCourse} />
              <Route path="/shop" component={Shop} />
              <Route path="/training-sounds" component={Sounds} />
              <Route path="/" component={Dashboard} />
              <Route component={Dashboard}/>
            </Switch>
          </div>
        </div>
      </div>

      <Modal
        open={showAddUserToPerfectPupDayEmailConfirmation}
        close={() => {
          addUserToPerfectPup(false);
          setShowAddUserToPerfectPupDayEmailConfirmation(false);
        }}
        title={''}
        buttons={[
          <button key="add-to-list" className="success" onClick={() => {
            addUserToPerfectPup(true);
            setShowAddUserToPerfectPupDayEmailConfirmation(false);
          }}>Yes</button>,
          <button key="no-thanks" onClick={() => {
            addUserToPerfectPup(false);
            setShowAddUserToPerfectPupDayEmailConfirmation(false);
          }}>No Thanks</button>,
        ]}
      >
        <div>
          <div className="modal-text">Would you like to receive guided daily emails during the 30 Day Perfect Pup? These emails will include relevant training tips, videos, and product recommendations to help your pup!</div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  let hasCompletedNewDogCourse = false;

  if (state.newDogSteps && state.newDogSteps.length && state.user.completedNewDogSteps && state.user.completedNewDogSteps.length) {
    let completionAmount = 0;
    const uniqueCompletedNewDogSteps = [];

    state.user.completedNewDogSteps.forEach(behavior => {
      if (!uniqueCompletedNewDogSteps.includes(behavior)) {
        uniqueCompletedNewDogSteps.push(behavior);
      }
    });

    completionAmount = Math.round((uniqueCompletedNewDogSteps.length / state.newDogSteps.length) * 100);

    if (completionAmount === 100) {
      hasCompletedNewDogCourse = true;
    }
  }

  return {
    user: state.user,
    hasCompletedNewDogCourse,
  };
};

export default connect(mapStateToProps, {
  setCourses,
  setUserInfo,
  setHasAccessToAll,
  setFreeCourses,
  repopulateCart,
  changeQuantity,
  removeFromCart,
})(withRouter(Authed));
