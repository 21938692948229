import React, { useEffect, useState } from 'react';
import { ItemTypes } from './itemTypes';

import Row from './row';
import Column from './column';
import Button from './button';
import Accordion from './accordion';
import Tabs from './tabs';
import DangerouslySetHtmlContent from '../DangerouslySetHtmlContent';

const headerTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const regex = /([\w-]*)\s*:\s*([^;]*)/g;

const count = { acc: 0 };

function Preview({ items, clicked }) {
  const [updatedItems, setUpdatedItems] = useState([]);

  useEffect(() => {
    if (items.length) {
      const updated = updateItems(items, count);
      setUpdatedItems(updated);
    }
  }, [items]);

  const updateItems = (items, count) => {
    for (let i = 0; i < items.length; i++) {
      count.acc++;
      items[i].count = count.acc;

      if (items[i].children) {
        updateItems(items[i].children, count);
      }
    }

    return items;
  };

  const renderItem = (content) => {
    let match, properties = {};
    while(match=regex.exec(content.customStyles)) properties[match[1]] = match[2].trim();

    if (content.type === ItemTypes.ROW) {
      return (
        <Row key={`child-${content.count}`} content={content} customStyles={properties}>
          {content.children.map((item) => {
            return renderItem(item);
          })}
        </Row>
      );
    }

    if (content.type === ItemTypes.COLUMN) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{
          zIndex: 1,
          flex: content.styles.flex || 1,
          alignSelf: content.styles.alignSelf,
          width: '100%',
        }}>
          <Column content={content} customStyles={properties}>
            {content.children.map((item) => {
              return renderItem(item);
            })}
          </Column>
        </div>
      );
    }

    if (headerTypes.includes(content.type)) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <content.type style={{ ...content.styles, whiteSpace: 'pre-wrap', ...properties }} className={content.customClasses} id={content.htmlID}>{content.text}</content.type>
        </div>
      );
    }

    if (content.type === ItemTypes.TEXT) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <div style={{ ...content.styles, ...properties }} dangerouslySetInnerHTML={{__html: content.text}} className={content.customClasses} id={content.htmlID} />
        </div>
      );
    }

    if (content.type === ItemTypes.BUTTON) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Button content={content} customStyles={properties} clicked={clicked} />
        </div>
      );
    }

    if (content.type === ItemTypes.IMAGE) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <img src={content.src || 'https://place-hold.it/500x500.png&text=Image&bold'} alt={content.alt} style={{ ...content.styles, ...properties }} className={`${content.customClasses} lazyload`} id={content.htmlID} />
        </div>
      );
    }

    if (content.type === ItemTypes.CODE) {
      return (
        <div key={`child-${content.count}`}>
          <div className={`${content.visibilityClassName} ${content.customClasses}`} id={content.htmlID} style={{zIndex: 1, position: 'relative'}}>
            <DangerouslySetHtmlContent html={content.html} />
          </div>
        </div>
      );
    }

    if (content.type === ItemTypes.ACCORDION) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Accordion content={content} customStyles={properties} />
        </div>
      );
    }

    if (content.type === ItemTypes.TABS) {
      return (
        <div key={`child-${content.count}`} className={content.visibilityClassName} style={{zIndex: 1, position: 'relative'}}>
          <Tabs content={content} customStyles={properties} />
        </div>
      );
    }

    return <div></div>;
  };

  return (
    <div>
      {updatedItems.map((item, i) => {
        return (
          <div key={`row-${i}`}>
            {renderItem(item)}
          </div>
        )
      })}
    </div>
  );
}

export default Preview;
