import React from 'react';

import './openInMobile.scss';
import webIcon from '../../../../images/web.svg';

const appStoreUrl = 'https://apps.apple.com/us/app/pupford-dog-training-videos/id1476456602?ls=1';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.pupford.pupford';

function OpenInMobile({ close, appUrl, isAndroid, isIOS }) {
  const handleOpenApp = async (e) => {
    close();

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;

    if (isAndroid) {
      setTimeout(() => {
        if (window.confirm('Would you like to download the Pupford app in the App Store?')) {
          window.location.replace(playStoreUrl);
        }
      }, 200);
      return;
    }

    if (isSafari) {
      e.preventDefault();
      window.location.replace(appUrl);

      setTimeout(() => {
        if (window.confirm('Would you like to download the Pupford app in the App Store?')) {
          window.location.replace(appStoreUrl);
        }
      }, 200);
    } else {
      if (isIOS) {
        window.open(appStoreUrl);
      }
  
      if (isAndroid) {
        window.open(playStoreUrl);
      }
    }
  };

  return (
    <div className="OpenInMobile">
      <div className="bottom-container">
        <div className="bottom-container-header">
          <h1>View this Content</h1>
        </div>

        <div className="bottom-container-content-container">
          <div className="content">
            <div className="row">
              <div className="left-container">
                <div className="pupford-icon-container">
                  <img src="https://yt3.ggpht.com/ytc/AAUvwnhiFT59AXoJbfj600_ub4GsN40_8NoPI9sdFQ1Q=s68-c-k-c0x00ffffff-no-rj" />
                </div>

                <p>Pupford App</p>
              </div>

              <div className="right-container">
                <a href={appUrl}>
                  <button onClick={handleOpenApp}>OPEN</button>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="left-container">
                <div className="browser-icon-container">
                  <img src={webIcon} className="browser-icon" alt="browser" />
                </div>

                <p>Browser</p>
              </div>

              <div className="right-container">
                <button onClick={close}>CONTINUE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenInMobile;
