import {
  SET_SHOP_SETTINGS,
} from '../actions/types';

const defaultState = { settingsSet: false };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SHOP_SETTINGS:
      return {
        settingsSet: true,
        ...action.payload,
      };
    default:
      return state;
  }
};
