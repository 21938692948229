import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import debounce from 'lodash.debounce';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import firebase from '../../../../firebase';

import Payment from '../payment/payment';
import Loader from '../loader/loader';
import LoadingMessage from '../loadingMessage/loadingMessage';
import { allCountries, unitedStates, canada } from '../../../../lib/statesAndCountries';
import { withStripe } from '../withStripe';
import infoTriangleRed from '../../../../images/info-triangle-red.svg';
import './purchaseCourseModal.scss';

function PurchaseCourseModal({
  product = {},
  singleCoursePrice,
  user,
  stripeEnabled,
  paypalEnabled,
  cancelPressed,
  handleStripeSuccess,
  isAcademyPurchase,
  academyCourseProducts,
  stripe,
  elements,
}) {
  const delayedGetTax = useCallback(debounce((d) => getTaxAmount(d), 500), []);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [billingInfo, setBillingInfo] = useState({
    country: 'US',
    state: unitedStates[0].value,
    zip: '',
  });
  const [billingStates, setBillingStates] = useState(unitedStates);
  const [selectedPaymentType, setSelectedPaymentType] = useState();
  const [useExistingCard, setUseExistingCard] = useState(false);
  const [stripeCards, setStripeCards] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [selectedStripeCardIndex, setSelectedStripeCardIndex] = useState(0);
  const [stripeFieldsComplete, setStripeFieldsComplete] = useState({
    number: false,
    expiration: false,
    cvc: false,
  });
  const [taxAmount, setTaxAmount] = useState(0);
  const [subscriptionTaxAmount, setSubscriptionTaxAmount] = useState(0);
  const [calculatingOrderTax, setCalculatingOrderTax] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessageTitle, setErrorMessageTitle] = useState('');
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [academyProducts, setAcademyProducts] = useState([]);
  const [selectedAcademyProductId, setSelectedAcademyProductId] = useState([]);
  const [price, setPrice] = useState('');
  const [subscriptionPrice, setSubscriptionPrice] = useState('');

  useEffect(() => {
    const gtag = window.gtag || function() {};

    if (!isAcademyPurchase) {
      setPrice(singleCoursePrice);
      setSubscriptionPrice('');
      gtag('event', 'begin_checkout', {
        'items': [{
          id: product.id,
          name: product.name,
          brand: 'Pupford',
          category: product.categories ? product.categories.join('/') : '',
          quantity: 1,
          price: singleCoursePrice,
        }],
      });
      return;
    }

    const sortedAcademyProducts = academyCourseProducts.map(p => {
      const details = {
        id: p.id,
        name: p.name,
        displayPrice: p.isOnSale ? p.salePrice : p.price,
        categories: p.categories || [],
      };

      if (p.subscriptionOnly) {
        const subscription = p.subscriptionOptions[0];
        const discountPercentage = +(subscription.discountPercentage);
        const firstTimeDiscountPercentage = +(subscription.firstOrderDiscountPercentage);

        if (firstTimeDiscountPercentage) {
          details.displayPrice = (p.price * (1 - (firstTimeDiscountPercentage / 100))).toFixed(2);
        }

        if (discountPercentage) {
          const discountedPrice = (p.price * (1 - (discountPercentage / 100))).toFixed(2);

          if (!firstTimeDiscountPercentage) {
            details.displayPrice = discountedPrice;
          }

          details.subscriptionPrice = discountedPrice;
        } else {
          details.subscriptionPrice = p.price;
        }
      }

      return details;
    }).sort((a, b) => {
      const priceA = parseFloat(a.displayPrice);
      const priceB = parseFloat(b.displayPrice);

      return priceA - priceB;
    });

    setAcademyProducts(sortedAcademyProducts);
    setSelectedAcademyProductId(sortedAcademyProducts[0].id);
    setPrice(sortedAcademyProducts[0].displayPrice);

    if (sortedAcademyProducts[0].subscriptionPrice) {
      setSubscriptionPrice(sortedAcademyProducts[0].subscriptionPrice);
    } else {
      setSubscriptionPrice('');
    }

    gtag('event', 'begin_checkout', {
      'items': [{
        id: sortedAcademyProducts[0].id,
        name: sortedAcademyProducts[0].name,
        brand: 'Pupford',
        category: sortedAcademyProducts[0].categories ? sortedAcademyProducts[0].categories.join('/') : '',
        quantity: 1,
        price: sortedAcademyProducts[0].displayPrice,
      }],
    });
  }, [isAcademyPurchase, academyCourseProducts, singleCoursePrice]);

  useEffect(() => {
    if (user && user.billing) {
      setBillingInfo({
        ...billingInfo,
        country: user.billing.country || 'US',
        state: user.billing.state || unitedStates[0].value,
        zip: user.billing.zip || '',
      });
    }

    if (user.stripeCards && Array.isArray(user.stripeCards) && user.stripeCustomerId) {
      user.stripeCards = user.stripeCards.filter(c => {
        const date = moment([c.exp_year, c.exp_month - 1]);
        const expirationDate = moment(date).endOf('month');
        const currentDate = moment();

        return currentDate < expirationDate;
      });

      setStripeCards(user.stripeCards);
      setStripeCustomerId(user.stripeCustomerId);
      setUseExistingCard(true);
    }
  }, [user]);

  useEffect(() => {
    if (stripeEnabled) {
      setSelectedPaymentType('stripe');
    } else if (paypalEnabled) {
      setSelectedPaymentType('paypal');
    }
  }, [stripeEnabled, paypalEnabled]);

  useEffect(() => {
    if (price) {
      delayedGetTax({
        ...billingInfo,
        subtotal: price,
        subscriptionPrice,
      });
    }
  }, [billingInfo, price, subscriptionPrice]);

  const getTaxAmount = async (data) => {
    setCalculatingOrderTax(true);

    const taxData = {
      country: data.country,
      amount: +data.subtotal,
      shipping: 0,
      isDigital: true,
    };

    if (data.country === 'US') {
      if (!data.zip) {
        setTaxAmount(0);
        setSubscriptionTaxAmount(0);
        setCalculatingOrderTax(false);
        return;
      } else {
        taxData.state = data.state;
        taxData.zip = data.zip;
      }
    }

    if (data.country === 'CA') {
      taxData.state = data.state;
    }

    try {
      const result = await axios.post('https://api-pupford.b-cdn.net/webApi/calculate-sales-tax', taxData);

      if (result.data.tax && result.data.tax.amount_to_collect && +result.data.tax.amount_to_collect > 0) {
        setTaxAmount(result.data.tax.amount_to_collect);
      } else {
        setTaxAmount(0);
      }

      if (data.subscriptionPrice) {
        const subscriptionResult = await axios.post('https://api-pupford.b-cdn.net/webApi/calculate-sales-tax', {
          ...taxData,
          amount: +data.subscriptionPrice,
        });

        if (subscriptionResult.data.tax && subscriptionResult.data.tax.amount_to_collect && +subscriptionResult.data.tax.amount_to_collect > 0) {
          setSubscriptionTaxAmount(subscriptionResult.data.tax.amount_to_collect);
        } else {
          setSubscriptionTaxAmount(0);
        }
      } else {
        setSubscriptionTaxAmount(0);
      }
    } catch (e) {
      console.log('e', e);
      setTaxAmount(0);
      setSubscriptionTaxAmount(0);
    }

    setCalculatingOrderTax(false);
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    const updateData = {
      ...billingInfo,
      country,
    };

    if (country === 'CA') {
      setBillingStates(canada);
      updateData.state = canada[0].value;
    }

    if (country === 'US') {
      setBillingStates(unitedStates);
      updateData.state = unitedStates[0].value;
    } else {
      updateData.zip = '';
    }

    setBillingInfo(updateData);
  };

  const placeOrder = async () => {
    const errors = [];

    if (billingInfo.country === 'US' && billingInfo.zip.length < 5) {
      errors.push('A valid zip code is required.');
    }

    if (selectedPaymentType === 'stripe' && !useExistingCard) {
      if (!stripeFieldsComplete.number) {
        errors.push('Card number is required.');
      }

      if (!stripeFieldsComplete.expiration) {
        errors.push('Card expiration date is required.');
      }

      if (!stripeFieldsComplete.cvc) {
        errors.push('Card security code is required.');
      }
    }

    if (!acceptTermsAndConditions) {
      errors.push('You must accept terms and conditions.');
    }

    const products = [];
    const subscriptions = [];

    if (isAcademyPurchase) {
      const foundProduct = academyCourseProducts.find(p => p.id === selectedAcademyProductId);
      
      if (!foundProduct) {
        errors.push('Selected Plan not found.');
      } else {
        const productImage = (foundProduct.media && foundProduct.media.length) ? foundProduct.media[0] : {};
        products.push({
          id: foundProduct.id,
          name: foundProduct.name,
          quantity: 1,
          sku: foundProduct.sku,
          originalPrice: foundProduct.price,
          paidPrice: price,
          isDigital: true,
          categories: foundProduct.categories || [],
          path: foundProduct.path || '',
          imageUrl: productImage.url || '',
        });

        if (foundProduct.subscriptionOnly && foundProduct.subscriptionOptions.length) {
          const subscription = foundProduct.subscriptionOptions[0];
          const priceToUse = subscriptionPrice || price;
          const taxToUse = subscriptionTaxAmount || taxAmount;

          subscriptions.push({
            interval: subscription.interval,
            period: subscription.period,
            products: [{
              productId: foundProduct.id,
              name: foundProduct.name,
              price: priceToUse,
              quantity: 1,
              sku: foundProduct.sku,
              shippingClass: foundProduct.shippingClass,
              isDigital: true,
              categories: foundProduct.categories || [],
              path: foundProduct.path || '',
              imageUrl: productImage.url || '',
            }],
            subtotal: parseFloat(priceToUse),
            shippingAmount: 0,
            taxAmount: taxToUse,
          });
        }
      }
    } else {
      const productImage = (product.media && product.media.length) ? product.media[0] : {};
      products.push({
        id: product.id,
        name: product.name,
        quantity: 1,
        sku: product.sku,
        originalPrice: product.price,
        paidPrice: price,
        isDigital: true,
        categories: product.categories || [],
        path: product.path || '',
        imageUrl: productImage.url || '',
      });
    }

    // Resetting error message, this will only be displayed if there are any error messages
    setErrorMessageTitle('We were unable to place your order. Please update the following fields and try again.');
    setErrorMessages(errors);

    if (errors.length) {
      return;
    }

    setLoadingMessage('Placing order...');

    const total = +price + taxAmount;
    const currentUser = firebase.auth().currentUser;
    const email = currentUser.email;
    const orderData = {
      fromAcademy: true,
      status: 'PENDING_PAYMENT',
      hasOrderBump: false,
      hasFreeProduct: false,
      userId: currentUser.uid,
      userEmail: email,
      email,
      phone: '',
      products,
      couponsUsed: [],
      total: total.toFixed(2),
      subtotal: (+price).toFixed(2),
      taxAmount: taxAmount.toFixed(2),
      shippingAmount: '0.00',
      billing: {
        ...billingInfo,
        firstName: user.userName || '',
        lastName: '',
      },
      shipping: {
        firstName: '',
        lastName: '',
        address: '',
        addressSecondary: '',
        city: '',
        zip: '',
        country: 'US',
        state: '',
      },
      subscriptions,
      updated: firebase.firestore.Timestamp.fromDate(new Date()),
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      thankYouPage: product.customThankYouPagePath || '',
    };

    if (selectedPaymentType === 'paypal') {
      setLoadingMessage('Connecting to PayPal. Do not refresh the page.');

      try {
        const order = await firebase.firestore().collection('orders-v2').add({
          ...orderData,
          paymentType: 'paypal',
        });

        const result = await axios.post('https://api-pupford.b-cdn.net/webApi/ba-token', {
          returnUrl: `https://pupford.com/order-success?id=${order.id}`,
          cancelUrl: `https://pupford.com/order-cancel?id=${order.id}`,
        });
        const data = result.data;

        if (data && data.links && data.links[0]) {
          window.location.href = data.links[0].href;
        } else {
          setLoadingMessage('');
          setErrorMessageTitle('The following errors occurred.');
          setErrorMessages([
            'There was an error connecting to PayPal, please try again. If this problem persists, please contact us.',
          ]);
        }
      } catch (err) {
        setLoadingMessage('');
        setErrorMessageTitle('The following errors occurred.');
        setErrorMessages([
          'There was an error connecting to PayPal, please try again. If this problem persists, please contact us.',
        ]);
      }
    } else if (selectedPaymentType === 'stripe') {
      setLoadingMessage('Placing order. Do not refresh the page.');

      try {
        const order = await firebase.firestore().collection('orders-v2').add({
          ...orderData,
          paymentType: 'stripe',
        });

        const orderId = order.id;

        const stripeData = {
          orderId,
          currentUserEmail: email,
          userId: currentUser.uid,
          type: useExistingCard ? 'use-token' : 'new-card',
          token: '',
          amount: total.toFixed(2),
          stripeCardId: '',
          stripeCustomerId,
        };

        if (useExistingCard) {
          stripeData.stripeCardId = stripeCards[selectedStripeCardIndex].id;
        } else {
          const cardNumberElement = elements.getElement(CardNumberElement);

          const {error, token} = await stripe.createToken(cardNumberElement);

          if (error || !token.id) {
            setLoadingMessage('');
            setErrorMessageTitle('Error:');
            setErrorMessages([
              'There was an error connecting to Stripe, please try again. If this problem persists, please contact us.',
            ]);
            return;
          } else {
            stripeData.token = token.id;
          }
        }

        const result = await axios.post(`https://api-pupford.b-cdn.net/webApi/s-create-order`, stripeData);

        if (result.data.error) {
          setLoadingMessage('');
          setErrorMessageTitle('Error:');
          setErrorMessages([
            result.data.error,
          ]);
          return;
        }

        const fbq = window.fbq || function() {};
        fbq('track', 'Purchase', {value: +total, currency: 'USD'});

        const gtag = window.gtag || function() {};
        gtag('event', 'purchase', {
          transaction_id: orderId,
          value: +total,
          currency: 'USD',
          tax: +taxAmount,
          shipping: 0,
          items: orderData.products.map(product => {
            return {
              id: product.id,
              name: product.name,
              brand: 'Pupford',
              quantity: product.quantity,
              category: product.categories ? product.categories.join('/') : '',
              price: product.paidPrice,
            };
          }),
        });

        handleStripeSuccess();
      } catch (err) {
        setLoadingMessage('');
        setErrorMessageTitle('Error:');
        setErrorMessages([
          'There was an error creating your order, please try again. If this problem persists, please contact us.',
        ]);
      }
    }
  };

  return (
    <div className="PurchaseCourseModal">
      {!loadingMessage ? null :
        <div className="loading-message-container">
          <LoadingMessage message={loadingMessage} />
        </div>
      }

      <div className="top-section">
        {!isAcademyPurchase || !academyProducts.length ? null :
          <div className="input-container">
            <label>Plan</label>
            <select value={selectedAcademyProductId} onChange={(e) => {
              const value = e.target.value;
              const foundProduct = academyProducts.find(p => p.id === value);

              setSelectedAcademyProductId(value);
              setTaxAmount(0);
              setPrice(foundProduct.displayPrice);

              if (foundProduct.subscriptionPrice) {
                setSubscriptionPrice(foundProduct.subscriptionPrice);
              } else {
                setSubscriptionPrice('');
              }

              const gtag = window.gtag || function() {};
              gtag('event', 'checkout_progress', {
                'items': [{
                  id: foundProduct.id,
                  name: foundProduct.name,
                  brand: 'Pupford',
                  quantity: 1,
                  category: foundProduct.categories ? foundProduct.categories.join('/') : '',
                  price: foundProduct.displayPrice,
                }],
              });
            }}>
              {academyProducts.map((p, i) => {
                return <option key={p.id} value={p.id}>{p.name}</option>
              })}
            </select>
          </div>
        }

        <div className="price-section well">
          <div className="price-row">
            <p>Price</p>
            <p>${price}</p>
          </div>

          <div className="price-row">
            <p>Tax</p>
            <p>${taxAmount.toFixed(2)}</p>
          </div>

          <div className="total-row">
            <p>Total</p>
            <p className="total-text">${(+price + taxAmount).toFixed(2)}</p>
          </div>

          {!subscriptionPrice ? null :
            <div className="price-section-sub">
              {calculatingOrderTax ?
                <p>Calculating tax...</p> :
                <p>Renewal Price: ${(+subscriptionPrice + +subscriptionTaxAmount).toFixed(2)}</p>
              }
            </div>
          }
        </div>
      </div>

      <div className="payment-details-container">
        <div className="input-container">
          <label>Country *</label>
          <select value={billingInfo.country} onChange={handleCountryChange}>
            {allCountries.map((c, i) => {
              return <option key={`shipping-country-${i}`} value={c.value}>{c.name}</option>
            })}
          </select>
        </div>

        <div className="input-row">
          {(billingInfo.country !== 'US' && billingInfo.country !== 'CA') ? null :
            <div className={`input-container ${billingInfo.country !== 'CA' ? 'half-width' : 'full-width'}`}>
              <label>State *</label>
              <select value={billingInfo.state} onChange={(e) => {
                setBillingInfo({
                  ...billingInfo,
                  state: e.target.value,
                });
              }}>
                {billingStates.map((s, i) => {
                  return <option key={`billing-state-${i}`} value={s.value}>{s.name}</option>
                })}
              </select>
            </div>
          }

          {billingInfo.country !== 'US' ? null :
            <div className="input-container half-width">
              <label>Postcode *</label>
              <input
                type="text"
                placeholder="Postcode"
                value={billingInfo.zip}
                onChange={(e) => {
                  setBillingInfo({
                    ...billingInfo,
                    zip: e.target.value,
                  });
                }}
              />
            </div>
          }
        </div>

        <Payment
          stripeEnabled={stripeEnabled}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          useExistingCard={useExistingCard}
          stripeCards={stripeCards}
          stripeFieldsComplete={stripeFieldsComplete}
          setStripeFieldsComplete={setStripeFieldsComplete}
          selectedStripeCardIndex={selectedStripeCardIndex}
          setSelectedStripeCardIndex={setSelectedStripeCardIndex}
          setUseExistingCard={setUseExistingCard}
          paypalEnabled={paypalEnabled}
        />
      </div>

      {!errorMessages.length ? null :
        <div className="error-container">
          <div className="error-icon-container">
            <img src={infoTriangleRed} alt="error" />
          </div>
          <div>
            <p><strong>{errorMessageTitle}</strong></p>

            <ul>
              {errorMessages.map((error, i) => {
                return <li key={`error-${i}`}>{error}</li>;
              })}
            </ul>
          </div>
        </div>
      }

      <div className="terms-container">
        <input
          type="checkbox"
          id="termsAndConditions"
          checked={acceptTermsAndConditions}
          onChange={(e) => {
            setAcceptTermsAndConditions(e.target.checked);
          }}
        />
        <label htmlFor="termsAndConditions">I have read and agree to the website <a href="https://pupford.com/terms-conditions" target="_blank">terms and conditions</a>.</label>
      </div>

      <div className="bottom-buttons-container">
        <button
          className="place-order-button"
          disabled={calculatingOrderTax}
          onClick={placeOrder}
        >
          {calculatingOrderTax ? <Loader size={1} /> : 'Place Order'}
        </button>

        <div className="cancel-text-container">
          <a
            className="cancel-text"
            onClick={() => {
              setErrorMessages([]);
              setErrorMessageTitle('');
              cancelPressed();
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
}

export default withStripe(PurchaseCourseModal);
