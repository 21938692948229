import {
  SET_FREE_COURSE_ID,
  SET_FREE_CURRENT_MODULE_INDEX,
  SET_FREE_COURSES,
} from '../actions/types';

const defaultState = {
  currentCourseId: '',
  currentCourse: {},
  courses: [],
  currentModuleIndex: -1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FREE_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case SET_FREE_COURSE_ID:
      return {
        ...state,
        currentCourseId: action.payload,
      };
    case SET_FREE_CURRENT_MODULE_INDEX:
      return {
        ...state,
        currentModuleIndex: action.payload,
      };
    default:
      return state;
  }
};
