import React from 'react';

import './newUserOnboardingStep3.scss';

function NewUserOnboardingStep3(props) {
  return (
    <div className="NewUserOnboardingStep3">
      <div className="filter">
        <div className="content-container">
          <h1>Complete Your Pre-Training Class Survey</h1>
          <p>(It only takes a couple of minutes)</p>
          <p>We'll use this information to help provide you with the most relevant and important information and content for you and your pup during the training course. And don't worry, all information is confidential and private. It takes less than 5 minutes.</p>
          <p>Don't skip this survey! It'll help keep you accountable and improve the training for your pup.</p>
          <button onClick={() => {
            window.open('https://pupford.com/perfect-pup-pre-training-survey/', '_blank');
            props.updateStep(4);
          }}>Take Survey</button>
          <div className="skip-survey">
            <a href="" onClick={(e) => {
              e.preventDefault();
              props.updateStep(4);
            }}>Skip Survey</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUserOnboardingStep3;
