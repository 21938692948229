import {
  SET_ACADEMY_COURSE_PRODUCTS,
} from './types';

export const setAcademyCourseProducts = (products) => {
  return {
    type: SET_ACADEMY_COURSE_PRODUCTS,
    payload: products,
  };
};
