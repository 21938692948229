import React from 'react';
import {
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';

import { stripeKey } from '../../../../config';

export function withStripe(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      if (stripeKey) {
        this.state = {
          stripe: loadStripe(stripeKey),
        };
      }
    }

    render() {
      if (this.props.stripeEnabled && this.state && this.state.stripe) {
        return (
          <Elements stripe={this.state.stripe}>
            <ElementsConsumer>
              {({elements, stripe}) => (
                <WrappedComponent {...this.props} elements={elements} stripe={stripe} />
              )}
            </ElementsConsumer>
          </Elements>
        );
      }
      
      return <WrappedComponent {...this.props} stripeEnabled={false} />;
    }
  };
}
