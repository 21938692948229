import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../authed/shared/modal/modal';
import Loader from '../../authed/shared/loader/loader';

import './login.scss';

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    props.logIn(email, password);
  };

  return (
    <div className="Login">
      <div className="main-container">
        <div className="left-section">
          <div className="left-inner">
            <div className="left-text-container">
              <div>
                <h1>Welcome to the Pupford Training Dashboard</h1>
                <p>This area is for members only. If you are already a member please log in using the form on the right.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          <h1>Training Login</h1>
          <p>To access your Pupford Courses and/or Pupford Academy Membership, log in below.</p>

          <form onSubmit={handleSubmit}>
            <label>
              Email:
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>

            <label>
              Password:
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>

            {!props.loggingIn ?
              <input
                type="submit"
                value="Log In"
              ></input> :
              <div className="loading-container"><Loader size={2} /></div>
            }
          </form>

          <div className="forgot-password">
            <Link to="/forgot-password">
              Forgot Password
            </Link>
          </div>
        </div>
      </div>

      <Modal
        open={props.showLoginError}
        close={props.closeError}
        title="Error:"
        buttons={[
          <button key="error-close" onClick={props.closeError}>Close</button>,
        ]}
      >
        <div>
          <div>{props.loginError}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Login;
