import React, { useEffect } from 'react';

function OpenDay(props) {

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if ((/android/i.test(userAgent)) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
      window.location.replace('pupford://');
    }

    setTimeout(() => {
      props.history.replace(`/course/30-day-perfect-pup/${props.match.params.id - 1}`);
    }, 1000);
  }, []);
  
  return(
    <div style={{padding: 20}}>
      <p>Redirecting...</p>
    </div>
  );
}

export default OpenDay;
