import React from 'react';

import './soundsCategory.scss';

function SoundsCategory({ sounds, hasAccess, accessDenied }) {

  const handleSoundClick = (sound) => {
    if (!sound.free && !hasAccess) {
      accessDenied();
      return;
    }

    const audio = new Audio(sound.source);
    audio.play();
  };

  return (
    <div className="SoundsCategory">
      {sounds.map(sound => {
        return (
          <div
            key={sound.label}
            className="sound-container"
            onClick={() => handleSoundClick(sound)}
          >

            <div className="sound-inner">
              <img src={sound.iconSource} alt={sound.label} />

              {(!sound.free && !hasAccess) ?
                <div className="lock-icon">
                  <i></i>
                </div> :
                null
              }
            </div>
            <div className="sound-label-container">
              <p>{sound.label}</p>
            </div>

          </div>
        );
      })}
    </div>
  );
}

export default SoundsCategory;
