import {
  SET_TRAINING_TOOLS,
} from './types';

export const setTrainingTools = (trainingTools) => {
  return {
    type: SET_TRAINING_TOOLS,
    payload: trainingTools,
  };
};
