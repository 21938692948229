import {
  row,
  column,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  text,
  button,
  image,
  accordion,
  tabs,
} from './typeDefinitions';

export const ItemTypes = {
  ROW: 'row',
  COLUMN: 'column',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  TEXT: 'text',
  BUTTON: 'button',
  IMAGE: 'image',
  TABS: 'tabs',
  ACCORDION: 'accordion',
  CODE: 'code',
};

export const Items = [
  {
    type: ItemTypes.ROW,
    title: 'Row',
    children: [],
    ...row,
  },
  {
    type: ItemTypes.COLUMN,
    title: 'Column',
    children: [],
    ...column,
  },
  {
    type: ItemTypes.H1,
    ...h1,
  },
  {
    type: ItemTypes.H2,
    ...h2,
  },
  {
    type: ItemTypes.H3,
    ...h3,
  },
  {
    type: ItemTypes.H4,
    ...h4,
  },
  {
    type: ItemTypes.H5,
    ...h5,
  },
  {
    type: ItemTypes.H6,
    ...h6,
  },
  {
    type: ItemTypes.TEXT,
    ...text,
  },
  {
    type: ItemTypes.BUTTON,
    ...button,
  },
  {
    type: ItemTypes.IMAGE,
    ...image,
  },
  // {
  //   type: ItemTypes.VIDEO,
  //   title: 'Video',
  // },
  {
    type: ItemTypes.TABS,
    ...tabs,
  },
  {
    type: ItemTypes.ACCORDION,
    ...accordion,
  },
  {
    type: ItemTypes.CODE,
    title: 'HTML',
    html: '',
  },
];
