import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import './manageDogsModal.scss';
import firebase from '../../../../firebase';
import { setUserInfo } from '../../../../actions';
import Modal from '../modal/modal';
import LoadingMessage from '../loadingMessage/loadingMessage';
import timesIcon from '../../../../images/times.svg';

function ManageDogsModal({ open, close, currentDog, dogBreeds, user }) {
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [dogs, setDogs] = useState([]);
  const [switchToDog, setSwitchToDog] = useState(false);
  const [addDogModalOpen, setAddDogModalOpen] = useState(false);
  const [addDogDetails, setAddDogDetails] = useState({
    dogBirthday: moment().format('YYYY-MM-DD'),
    dogBreed: '',
    dogName: '',
  });

  useEffect(() => {
    if (open) {
      fetchDogs();
    }
  }, [open]);

  const fetchDogs = async () => {
    setLoadingMessage('Retrieving your dogs...');

    try {
      const userId = firebase.auth().currentUser.uid;
      const userDogsSnapshot = await firebase.firestore().collection('user-dogs').doc(userId).get();
      let userDogsData = userDogsSnapshot.data();

      if (!userDogsData || !userDogsData.list) {
        userDogsData = {
          list: [{
            ...currentDog,
          }],
        };

        await firebase.firestore().collection('user-dogs').doc(userId).set(userDogsData);
      }

      setDogs(userDogsData.list);
      setLoadingMessage('');
    } catch (e) {
      setLoadingMessage('');
      close({
        modalTitle: 'Error:',
        modalText: 'There was an error loading your pups, please try again. If this problem persists, please contact us.',
      });
    }
  };

  const calculateCompletedModules = (modules) => {
    let completedModules = 0;

    for (let key in modules) {
      if (Array.isArray(modules[key])) {
        completedModules += modules[key].length;
      }
    }

    return completedModules;
  };

  const updateCurrentDog = async (newDog) => {
    if (!newDog) {
      return;
    }

    const dog = { ...newDog };

    setSwitchToDog(false);
    setLoadingMessage('Updating current dog...');

    try {
      const userId = firebase.auth().currentUser.uid;
      await firebase.firestore().collection('users').doc(userId).update(dog);

      dispatch(setUserInfo({
        ...user,
        ...dog,
      }));
      setLoadingMessage('');
      close();
    } catch (e) {
      setLoadingMessage('');
      close({
        modalTitle: 'Error:',
        modalText: 'There was an error updating your current pup, please try again. If this problem persists, please contact us.',
      });
    }
  };

  const submitAddDog = async () => {
    const dogName = addDogDetails.dogName;
    const dogBreed = addDogDetails.dogBreed || '';
    const dogBirthday = moment(addDogDetails.dogBirthday || Date.now()).valueOf();

    if (!dogName) {
      setModalTitle('Notice');
      setModalText('Your dog\'s name is required, please enter a valid name.');
      return;
    }
    
    const foundDogIndex = dogs.findIndex(dog => dog.dogName === dogName);

    if (foundDogIndex !== -1) {
      setModalTitle('Notice');
      setModalText(`You have already added ${dogName}. Please enter a unique name.`);
      return;
    }

    setAddDogModalOpen(false);
    setAddDogDetails({
      dogBirthday: moment().format('YYYY-MM-DD'),
      dogBreed: '',
      dogName: '',
    });
    setLoadingMessage('Adding your dog...');

    const updatedDogs = [
      ...dogs,
      {
        dogBirthday,
        dogBreed,
        dogName,
        behaviorTracking: {},
        academyCompletion: {},
      },
    ];

    try {
      const userId = firebase.auth().currentUser.uid;
      await firebase.firestore().collection('user-dogs').doc(userId).set({
        list: updatedDogs,
      });

      setDogs(updatedDogs);
      setLoadingMessage('');
    } catch (e) {
      setLoadingMessage('');
      setModalTitle('Notice!');
      setModalText('There was an error adding your pup, please try again. If this problem persists, please contact us.');
    }
  };

  if (!open) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <div className="ManageDogsModal">
      {!loadingMessage ? null : <LoadingMessage message={loadingMessage} />}
      <div className="modal-body">
        <div className="close-button" onClick={close}>
          <img src={timesIcon} alt="close" />
        </div>

        <h3 className="title">My Dogs</h3>
        <div className="modal-content">
          <div className="dogs-list">
            {dogs.map((dog, i) => {
              return (
                <div
                  key={`dog-${i}`}
                  className="dog-container"
                  onClick={() => {
                    if (dog.dogName === currentDog.dogName) {
                      return;
                    }

                    setSwitchToDog({ ...dog });
                  }}
                >
                  <div className="dog-details-container">
                    <p className="dog-name">{dog.dogName}</p>
                    <p className="dog-details"><strong>Breed:</strong> {dog.dogBreed}</p>
                    <p className="dog-details"><strong>Birthday:</strong> {moment(dog.dogBirthday).format('MMM DD, YYYY')}</p>
                  </div>

                  <div className="completed-modules-container">
                    <p className="completed-modules-amount">{calculateCompletedModules(dog.academyCompletion || {})}</p>
                    <p className="completed-modules-text">Completed</p>
                    <p className="completed-modules-text">Modules</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="add-dog-button-container">
            <button
              onClick={() => {
                const dogBreed = (dogBreeds && dogBreeds.length) ? dogBreeds[0] : '';
                setAddDogDetails({
                  dogBirthday: moment().format('YYYY-MM-DD'),
                  dogBreed,
                  dogName: '',
                });
                setAddDogModalOpen(true);
              }}
            >
              Add Another Dog
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={!!switchToDog}
        close={() => setSwitchToDog(false)}
        title="Switch Dogs?"
        buttons={[
          <button key="switch-pups-confirm" className="success" onClick={() => updateCurrentDog({ ...switchToDog })}>Confirm</button>,
          <button key="switch-pups-close" onClick={() => setSwitchToDog(false)}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Would you like to set {(switchToDog && switchToDog.dogName) ? switchToDog.dogName : 'this dog'} as your current pup?</div>
        </div>
      </Modal>

      <Modal
        open={addDogModalOpen}
        close={() => {
          setAddDogModalOpen(false);
          setAddDogDetails({
            dogBirthday: moment().format('YYYY-MM-DD'),
            dogBreed: '',
            dogName: '',
          });
        }}
        title="Add Another Dog"
        buttons={[
          <button key="modal-submit" className="success" onClick={() => submitAddDog()}>Submit</button>,
          <button key="modal-close" onClick={() => {
            setAddDogModalOpen(false);
            setAddDogDetails({
              dogBirthday: moment().format('YYYY-MM-DD'),
              dogBreed: '',
              dogName: '',
            });
          }}>Close</button>,
        ]}
      >
        <div>
          <div>
            <label>Your Dog's Name</label>
            <input
              value={addDogDetails.dogName}
              onChange={(e) => {
                setAddDogDetails({
                  ...addDogDetails,
                  dogName: e.target.value,
                });
              }}
              placeholder="Enter your dog's name"
              type="text"
            />
          </div>
          <div className="dog-breed-select">
            <label>Your Dog's Breed</label>
            <select value={addDogDetails.dogBreed} onChange={(e) => {
              setAddDogDetails({
                ...addDogDetails,
                dogBreed: e.target.value,
              });
            }}>
              {dogBreeds.map(breed => {
                return <option key={breed} value={breed}>{breed}</option>
              })}
            </select>
          </div>
          <div>
            <label>Your Dog's Birthday</label>
            <input
              value={addDogDetails.dogBirthday}
              onChange={(e) => {
                setAddDogDetails({
                  ...addDogDetails,
                  dogBirthday: e.target.value,
                });
              }}
              type="date"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => setModalText('')}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => setModalText('')}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>,
    document.querySelector('#manageDogsModalTarget')
  );
}

export default ManageDogsModal;
