import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './nav.scss';

const links = [
  {
    route: '/',
    name: 'Dashboard',
  },
  {
    route: '/courses',
    name: 'Courses',
  },
  {
    route: '/shop',
    name: 'Shop',
  },
  {
    route: '/training-sounds',
    name: 'Training Sounds',
  },
  {
    route: '/profile',
    name: 'Profile',
  },
];

function Nav({
  closeNav,
  logOut,
}) {
  let location = useLocation();

  const getActiveClassName = (link) => {
    if (location.pathname.includes('/course')) {
      return link.route.includes('/course') ? ' active' : '';
    }

    if (location.pathname.includes('/profile')) {
      return link.route.includes('/profile') ? ' active' : '';
    }

    return location.pathname === link.route ? ' active' : '';
  };

  return (
    <div className="Nav">
      {links.map(link => {
        return (
          <Link to={link.route} key={link.route}>
            <div
              onClick={closeNav}
              className={`nav-item${getActiveClassName(link)}`}
            >
              {link.name}
            </div>
          </Link>
        );
      })}
      <a>
        <div
          className="nav-item sign-out"
          onClick={() => {
            closeNav();
            logOut();
          }}
        >
          Sign Out
        </div>
      </a>
    </div>
  );
}

export default Nav;
