export const image = {
  title: 'Image',
  src: '',
  alt: '',
  styles: {
    display: 'inherit',
    height: '100%',
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  editableStyles: [
    {
      type: 'height',
      label: 'Height',
      placeholder: 'Enter height',
    },
    {
      type: 'width',
      label: 'Width',
      placeholder: 'Enter width',
    },
    {
      type: 'marginTop',
      label: 'Margin Top',
      placeholder: 'Enter margin top',
    },
    {
      type: 'marginBottom',
      label: 'Margin Bottom',
      placeholder: 'Enter margin bottom',
    },
    {
      type: 'marginLeft',
      label: 'Margin Left',
      placeholder: 'Enter margin left',
    },
    {
      type: 'marginRight',
      label: 'Margin Right',
      placeholder: 'Enter margin right',
    },
  ],
};
