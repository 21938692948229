import {
  SET_SHOP_SETTINGS,
} from './types';

export const setShopSettings = (settings) => {
  return {
    type: SET_SHOP_SETTINGS,
    payload: settings,
  };
};
