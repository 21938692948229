import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../firebase';
import axios from 'axios';

import Modal from '../../authed/shared/modal/modal';
import Loader from '../../authed/shared/loader/loader';
import AuthHeader from '../authHeader/authHeader';
import PasswordStrength from '../passwordStrength/passwordStrength';
import { baseURL } from '../../../config';
import {
  setUserType,
  setUserDataToSync,
  setUserZip,
} from '../../../actions';

import './joinAsPro.scss';

const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;
const numberRegex = /[0-9]/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function JoinAsPro(props) {
  const [loading, setLoading] = useState(false);
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('zip')) {
      setZip(urlParams.get('zip'));
    }
  }, []);

  const passwordChanged = (e) => {
    const value = e.target.value;

    setHasLowerCase(lowerCaseRegex.test(value));
    setHasUpperCase(upperCaseRegex.test(value));
    setHasNumber(numberRegex.test(value));
    setHasLength(value.length >= 8);
    setPassword(value);
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await axios.post(`${baseURL}/sign-up-as-pro`, {
        email,
        password,
      });

      if (result.data.error) {
        setShowModal(true);
        setModalTitle('Error:');
        setModalText(result.data.error);
      } else {
        props.setUserType('trainer');
        props.setUserDataToSync(result.data);

        if (zip) {
          props.setUserZip(zip);
        }

        await firebase.auth().createUserWithEmailAndPassword(email, password);

        props.history.push('/pro');
      }
    } catch (e) {
      console.log(e);
      setShowModal(true);
      setModalTitle('Error:');
      if (typeof e === 'object' && e.message) {
        setModalText(e.message);
      } else {
        setModalText('We were unable to create your account, please try again. If this problem persists, contact us at hello@pupford.com.');
      }
    }

    setLoading(false);
  };

  return (
    <div className="JoinAsPro">
      <AuthHeader />

      <div className="container">
        <div className="container-inner">
          <h2>Create your free account</h2>
          <p>Sign up to start getting leads in your area.</p>

          <form onSubmit={handleSubmit}>
            <label>
              Email:
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>

            <div className="new-password-container">
              <label>
                Password:
              </label>
              <input
                type="password"
                value={password}
                onBlur={() => {
                  setPasswordFocused(false);
                }}
                onFocus={() => { setPasswordFocused(true) }}
                onChange={passwordChanged}
              ></input>
              {!passwordFocused ? null :
                <PasswordStrength
                  hasLowerCase={hasLowerCase}
                  hasUpperCase={hasUpperCase}
                  hasNumber={hasNumber}
                  hasLength={hasLength}
                />
              }
            </div>

            <p>By signing up, you agree to the <a href="https://pupford.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://pupford.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

            {!loading ?
              <input
                type="submit"
                value="Sign Up"
                disabled={!(emailRegex.test(email)) || (!hasLowerCase || !hasUpperCase || !hasNumber || !hasLength)}
              ></input> :
              <div className="loading-container"><Loader size={2} /></div>
            }
          </form>
        </div>
      </div>

      <Modal
        open={showModal}
        close={() => { setShowModal(false) }}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => { setShowModal(false) }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(null, {
  setUserType,
  setUserDataToSync,
  setUserZip,
})(JoinAsPro);
