import { textItems } from './shared';

export const h6 = {
  title: 'H6',
  ...textItems,
  styles: {
    ...textItems.styles,
    fontSize: '14px',
  },
};
