import { textItems } from './shared';

export const h4 = {
  title: 'H4',
  ...textItems,
  styles: {
    ...textItems.styles,
    fontSize: '18px',
  },
};
