import React, { useState } from 'react';
import axios from 'axios';

import Modal from '../../authed/shared/modal/modal';
import Loader from '../../authed/shared/loader/loader';
import { baseURL } from '../../../config';

import './forgotPassword.scss';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailToSubmit = email.toLowerCase();
    const isEmail = emailRegex.test(emailToSubmit);

    if (!isEmail) {
      setModalTitle('Notice:');
      setModalText('The email address provided is not valid. Please provide a valid email address.');
      setShowInvalidEmail(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/send-reset-password-link`, {
        email,
      });
      const data = response.data;

      if (data.error) {
        setModalTitle('Error:');
        setModalText(data.error);
        setShowInvalidEmail(true);
      } else {
        setModalTitle('Success!');
        setModalText(`A link to reset your password has been sent to ${email} and should be arriving shortly. If it does not arrive in your inbox, check your spam folder.`);
        setShowInvalidEmail(true);
        setEmail('');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error processing your password reset request. Please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
      setShowInvalidEmail(true);
    }

    setLoading(false);
  };

  return (
    <div className="ForgotPassword">
      <div className="form-container">
        <h1>Forgot Password</h1>
        <p>Lost your password? Please enter your email address below. You will receive a link to create a new password via email.</p>

        <form onSubmit={handleSubmit}>
          <label>
            Email:
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>

          {!loading ?
            <input
              type="submit"
              value="Reset Password"
            ></input> :
            <div className="loading-container"><Loader size={2} /></div>
          }
        </form>

        <div className="return-to-login">
          <a href="" onClick={(e) => {
            e.preventDefault();
            props.history.goBack();
          }}>
            Go Back
          </a>
        </div>
      </div>

      <Modal
        open={showInvalidEmail}
        close={() => { setShowInvalidEmail(false) }}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => { setShowInvalidEmail(false) }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default ForgotPassword;
