import React from 'react';

import './newUserOnboardingStep4.scss';

function NewUserOnboardingStep4(props) {
  return (
    <div className="NewUserOnboardingStep4">
      <div className="filter">
        <div className="content-container">
          <h1>About the Pupford Academy</h1>
          <p>This 30 Day Perfect Pup Course (100% free) is great for getting your pup started off on the right paw for training! If at any time you're needing additional resources, check out the Pupford Academy for more in-depth behavioral training and additional skills.</p>
          <button onClick={() => {
            props.updateStep(0);
            props.history.push('/course/30-day-perfect-pup');
          }}>Start 30 Day Perfect Pup</button>
          <div className="academy-button-container">
            <a
              title="Open Pupford Academy (in new tab)"
              href="https://pupford.com/pupford-academy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <button>Learn More About Pupford Academy</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUserOnboardingStep4;
