import React, { useRef, useEffect } from 'react';
import firebase from '../../../../firebase';

import './completeModal.scss';
import timesIcon from '../../../../images/times.svg';

function CompleteModal({ open, close, content, actionPressed }) {
  const modalEl = useRef(null);

  useEffect(() => {
    const modalTarget = document.getElementById('modalTarget');

    if (open) {
      firebase.analytics().logEvent('ad_view', {
        ad: content.internalName,
        user: firebase.auth().currentUser.uid,
      });

      modalTarget.append(modalEl.current);
      document.body.classList.add('modal-open');
    } else if (modalEl.current) {
      modalTarget.removeChild(modalEl.current);
      document.body.classList.remove('modal-open');
    }
  }, [open]);

  if (!open && !modalEl.current) {
    return <div></div>;
  }

  const getOverlayImageJustifyContent = (value) => {
    const centerValues = ['left', 'right', 'center'];
    const bottomValues = ['bottom', 'bottom-left', 'bottom-right'];

    if (centerValues.includes(value)) {
      return 'space-around';
    }

    if (bottomValues.includes(value)) {
      return 'flex-end';
    }

    return 'flex-start';
  };

  const getOverlayImageTextAlignment = (value) => {
    const centerValues = ['center', 'top', 'bottom'];
    const rightValues = ['right', 'top-right', 'bottom-right'];

    if (centerValues.includes(value)) {
      return 'center';
    }

    if (rightValues.includes(value)) {
      return 'right';
    }

    return 'left';
  };

  const renderItem = (item) => {
    if (item.type === 'title') {
      return (
        <h2 style={{textAlign: item.textAlign, color: content.textColor || '#000'}}>
          {item.value}
        </h2>
      );
    } else if (item.type === 'subtitle') {
      return (
        <h3 style={{textAlign: item.textAlign, color: content.textColor || '#000'}}>
          {item.value}
        </h3>
      );
    } else if (item.type === 'paragraph') {
      return (
        <p style={{textAlign: item.textAlign, color: content.textColor || '#000'}}>
          {item.value}
        </p>
      );
    } else if (item.type === 'button') {
      return (
        <div className="modal-button-container">
          <button
            onClick={() => {
              actionPressed();

              firebase.analytics().logEvent('ad_clicked', {
                ad: content.internalName,
                user: firebase.auth().currentUser.uid,
              });
            }}
          >{item.value}</button>
        </div>
      );
    } else if (item.type === 'image') {
      return (
        <div className="image-container">
          <img src={item.value} style={{ width: `${item.width || 100}%`, marginTop: item.marginTop || 20, marginBottom: item.marginBottom || 20 }}/>
        </div>
      );
    } else if (item.type === 'video') {
      return (
        <div className="video-container">
          <div className="video-inner">
            <video width="100%" height="100%" controls poster={item.thumbnail} autoPlay muted>
              <source src={item.value} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      );
    } else if (item.type === 'textOverlayImage') {
      const textAlignment = getOverlayImageTextAlignment(item.textAlign);

      return (
        <div className="text-overlay-image-container">
          <img src={item.value} width="100%" height="auto"/>

          <div style={{position: 'absolute', top: 0, right: 0, bottom: 4, left: 0, backgroundColor: item.hasOverlayFilter ? item.overlayFilterColor : 'rgba(0, 0, 0, 0)'}}>
            <div style={{position: 'relative', padding: 10, paddingLeft: 15, paddingRight: 15, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: getOverlayImageJustifyContent(item.textAlign)}}>
              <div>
                {!item.title ? null : <h2 style={{textAlign: textAlignment, color: item.textColor || '#000'}}>{item.title}</h2>}
                {!item.paragraph ? null : <h3 style={{textAlign: textAlignment, color: item.textColor || '#000'}}>{item.paragraph}</h3>}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === 'review') {
      return (
        <div className="testimonial-container">
          
          <div className="stars-container">
            <img src="https://pupford.com/wp-content/uploads/2018/08/5-stars-300x57.png" />
          </div>

          <p className="review-paragraph">"{item.value}"</p>

          <p className="review-paragraph reviewer-name">- {item.name}</p>
        </div>
      );
    }
  };

  return (
    <div ref={modalEl} className="CompleteModal" style={{ backgroundImage: `url(${content.backgroundImage})` || ''}}>
      <div className="modal-inner" style={{ backgroundColor: content.backgroundOverlayColor || 'rgba(0, 0, 0, 0)' }}>
        <div className="close-button" onClick={close}>
          <img src={timesIcon} alt="close" />
        </div>

        <div className="modal-body">
          <div className="modal-width">
            <div>
              {!content.headline ? null : <h1 style={{ color: content.textColor || '#000' }}>{content.headline}</h1>}

              {content.items && content.items.map((item, i) => {
                return (
                  <div key={`completion-modal-item-${i}`}>
                    {renderItem(item)}
                  </div>
                );
              })}
            </div>

            <div className="no-thanks-container">
              <a className="no-thanks" style={{color: content.textColor}} onClick={close}>No, thanks.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteModal;
