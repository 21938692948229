import React, { useState } from 'react';
import axios from 'axios';

import Modal from '../../authed/shared/modal/modal';
import Loader from '../../authed/shared/loader/loader';
import PasswordStrength from '../passwordStrength/passwordStrength';
import { baseURL } from '../../../config';

import './resetPassword.scss';

const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;
const numberRegex = /[0-9]/;

function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  const [confirmNewPasswordFocused, setConfirmNewPasswordFocused] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}/confirm-password-reset`, {
        token: props.match.params.token,
        password: newPassword,
      });
      const data = response.data;

      if (data.error) {
        if (data.error.name === 'TokenExpiredError') {
          setModalTitle('Error:');
          setModalText('Your reset token has expired. To reset your password, return to the "Forgot Password" page and try again.');
          setShowPasswordError(true);
        } else {
          setModalTitle('Error:');
          setModalText('An error occurred resetting your password. If this problem persists, return to the "Forgot Password" page and try again.');
          setShowPasswordError(true);
        }
      } else {
        setModalTitle('Success!');
        setModalText('Your password has been reset. You can now log in with your new password.');
        setShowPasswordError(true);
        setNewPassword('');
        setConfirmNewPassword('');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('An error occurred resetting your password. Please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
      setShowPasswordError(true);
    }

    setLoading(false);
  };

  const newPasswordChanged = (e) => {
    const value = e.target.value;
    setHasLowerCase(lowerCaseRegex.test(value));
    setHasUpperCase(upperCaseRegex.test(value));
    setHasNumber(numberRegex.test(value));
    setHasLength(value.length >= 8);
    setNewPassword(value);
  };

  return (
    <div className="ResetPassword">
      <div className="form-container">
        <h1>Reset Password</h1>
        <p>To reset your password, enter and verify your new password below.</p>

        <form onSubmit={handleSubmit}>
          <div className="new-password-container">
            <label>
              New Password:
            </label>
            <input
              type="password"
              value={newPassword}
              onBlur={() => {
                setNewPasswordFocused(false);
              }}
              onFocus={() => { setNewPasswordFocused(true) }}
              onChange={newPasswordChanged}
            ></input>
            {!newPasswordFocused ? null :
              <PasswordStrength
                hasLowerCase={hasLowerCase}
                hasUpperCase={hasUpperCase}
                hasNumber={hasNumber}
                hasLength={hasLength}
              />
            }
          </div>

          <div className="new-password-container">
            <label>
              Confirm New Password:
            </label>
            <input
              type="password"
              value={confirmNewPassword}
              onBlur={(e) => {
                setConfirmNewPasswordFocused(false);
              }}
              onFocus={() => { setConfirmNewPasswordFocused(true) }}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
              }}
            ></input>
            {!confirmNewPasswordFocused || !newPassword ? null : 
              <div className="password-strength-container">
                <div className="password-strength-container-inner">
                  <div className={`password-match-title ${(newPassword === confirmNewPassword) ? 'match' : 'do-not-match'}`}>
                    Passwords {(newPassword === confirmNewPassword) ? 'match': 'do not match'}
                  </div>
                  <div className="password-reset-arrow password-confirm-arrow"></div>
                </div>
              </div>
            }
          </div>

          {!loading ?
            <input
              type="submit"
              value="Reset Password"
              disabled={(newPassword !== confirmNewPassword) || (!hasLowerCase || !hasUpperCase || !hasNumber || !hasLength)}
            ></input> :
            <div className="loading-container"><Loader size={2} /></div>
          }
        </form>
      </div>

      <Modal
        open={showPasswordError}
        close={() => { setShowPasswordError(false) }}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => { setShowPasswordError(false) }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default ResetPassword;
