import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from '../../shared/card/card';
import Loader from '../../shared/loader/loader';
import ProgressRing from '../../shared/progressRing/progressRing';
import {
  setAccessDenied,
} from '../../../../actions';
import arrowsLeftIcon from '../../../../images/arrow-left.svg';
import checkCircleIcon from '../../../../images/circle-check.svg';

import './course.scss';

function Course(props) {

  if (!props.course) {
    if (props.coursesSet && props.hasLoadedFreeCourses) {
      setTimeout(() => {
        props.setAccessDenied(true);
        props.history.push('/courses');
      }, 0);
      return null;
    }

    return (
      <div className="CourseLoader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="Course">
      <div className="course-content-container">
        <Card>
          <div className="header">
            <div className="main-image" style={{backgroundImage: `url(${props.course.backgroundImage})`}}>
            </div>
            <div className="filter-container">
              <div className="filter-text">
                <h1>{props.course.name}</h1>
              </div>
            </div>
            <div className="progress-container">
              <ProgressRing
                radius={ 50 }
                stroke={ 4 }
                progress={ props.completionAmount }
              />
            </div>
          </div>

          <div className="back-header-bar">
            <Link className="back-button" to="/courses">
              <img src={arrowsLeftIcon} alt="back" className="back-icon" />
              <p>View All Courses</p>
            </Link>
          </div>
        </Card>

        <div className={props.course.layout && props.course.layout === 'calendar' ? 'calendar-container' : 'module-outer-container'}>
          {props.course.modules.map((m, i) => {
            if (typeof m === 'string') {
              const isNotFirst = i !== 0;

              return (
                <h2 key={`module-${i}`} className={isNotFirst ? 'module-section-heading' : ''}>
                  {m}
                </h2>
              );
            } else if (props.course.layout && props.course.layout === 'calendar') {
              return (
                <Link to={`/course/${props.course.webPath ? props.course.webPath : props.course.id}/${i}`} className={`day-container${props.completedModules.includes(m.id) ? ' completed' : ''}`} key={`day-${i}`}>
                  <div className="day-number">{m.title}</div>
                  <div className="day-title">{m.description}</div>
                </Link>
              );
            } else {
              return (
                <Link key={`module-${i}`} className="module-inner-container" to={`/course/${props.course.webPath ? props.course.webPath : props.course.id}/${i}`}>
                  <Card>
                    <div className="module-container" style={{backgroundImage: `url(${m.imageUrl})`}}>
                      {props.completedModules.includes(m.id) ? <img src={checkCircleIcon} alt="complete" /> : null}
                    </div>
                    <h3>{m.title}</h3>
                  </Card>
                </Link>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, otherProps) => {
  let course = state.courses.find(c => c.id === otherProps.match.params.id);
  let completedModules = [];
  let completionAmount = 0;
  let hasLoadedFreeCourses = false;

  if (!course) {
    course = state.freeCourses.courses.find(c => c.webPath === otherProps.match.params.id);
  }

  if (state.freeCourses.courses.length) {
    hasLoadedFreeCourses = true;
  }

  if (course && course.modules && Array.isArray(course.modules) && course.id && state.user.academyCompletion && state.user.academyCompletion[course.id]) {
    state.user.academyCompletion[course.id].forEach(id => {
      if (!completedModules.includes(id)) {
        completedModules.push(id);
      }
    });

    const numberOfModules = course.modules.reduce((acc, current) => {
      if (typeof current === 'object') {
        acc++;
      }

      return acc;
    }, 0);

    completionAmount = Math.round((completedModules.length / numberOfModules) * 100);
  }

  if (!course || !course.modules || !Array.isArray(course.modules)) {
    course = null;
  }

  return {
    course,
    completedModules,
    completionAmount,
    coursesSet: state.user.coursesSet,
    hasLoadedFreeCourses,
  };
};

export default connect(mapStateToProps, {
  setAccessDenied,
})(Course);
