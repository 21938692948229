import React, { useState } from 'react';

import './soundsRecording.scss';
import microphoneImage from '../../../../../images/microphone.png';
import Card from '../../../shared/card/card';
import Modal from '../../../shared/modal/modal';

const maxFreeRecordings = 3;
const maxPaidRecordings = 20;

function SoundsRecording({
  recordings,
  hasAccess,
  accessDenied,
  handleMaxPaidRecordingsReached,
  handleRecordingError,
  handleSaveNewRecording,
  handleUnsupportedFileType,
  handleDeleteSound,
  handleSaveEditedRecording,
}) {
  const [recorder, setRecorder] = useState(false);
  const [recordedFile, setRecordedFile] = useState('');
  const [recordedURL, setRecordedURL] = useState('');
  const [newRecordingTitle, setNewRecordingTitle] = useState('New Recording');
  const [openPopoverIndex, setOpenPopoverIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [editOriginalName, setEditOriginalName] = useState('');
  const [editName, setEditName] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const startRecorder = async () => {
    try {
      const micRecorder = new window.MicRecorder({
        bitRate: 128
      });

      await micRecorder.start();
      setRecorder(micRecorder);
    } catch (e) {
      console.log(e);
      handleRecordingError('Error:', 'There was an error initializing the recording, please try again.');
    }
  };

  const stopRecorder = async () => {
    try {
      const result = await recorder.stop();
      const [buffer, blob] = await result.getMp3();

      const file = new File(buffer, 'me-at-thevoice.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });

      setRecordedFile(file);
      setRecordedURL(window.URL.createObjectURL(file));
      setRecorder(false);
    } catch (e) {
      console.log(e);
      handleRecordingError('Error:', 'There was an error with your recording, please try again.');
    }
  };

  const handleRecordClick = () => {
    if (recorder) {
      stopRecorder();
      return;
    }

    if (!hasAccess && recordings.length === maxFreeRecordings) {
      accessDenied();
      return;
    }

    if (recordings.length === maxPaidRecordings) {
      handleMaxPaidRecordingsReached();
      return;
    }

    startRecorder();
  };

  return (
    <div className="SoundsRecording">
      <div className="left-container">
        <div
          className="left-container-inner"
          onClick={handleRecordClick}
        >
          <Card>
            {!recorder ? null :
              <div className="record-icon"></div>
            }

            <img src={microphoneImage} alt="Clicker" />

            <div className="sound-label-container">
              <p>Record</p>
            </div>
          </Card>
        </div>
      </div>

      <div className="right-container">
        {!recordings.length ?
          <div className="no-recording-container">
            <p>Hit the record button to start your first recording. Your saved recordings will show up here.</p>
          </div> :
          <>
            {recordings.map((recording, i) => {
              return (
                <div className="recording-row-outer" key={`recording-${i}`}>
                  <Card>
                    <div className="recording-row">
                      {openPopoverIndex !== i ? null :
                        <div className="popover">
                          <div className="popover-inner">
                            <button
                              className="success"
                              onClick={() => {
                                setOpenPopoverIndex(-1);
                                setEditName(recording.displayName || '');
                                setEditOriginalName(recording.displayName || '');
                                setEditIndex(i);
                              }}
                            >Edit</button>
                            <button
                              onClick={() => {
                                setOpenPopoverIndex(-1);
                                setDeleteIndex(i);
                              }}
                            >Delete</button>
                          </div>
                        </div>
                      }
                      <div className="recording-left">
                        <i onClick={() => {
                          if (openPopoverIndex === i) {
                            setOpenPopoverIndex(-1);
                            return
                          }

                          setOpenPopoverIndex(i);
                        }}>
                          <div></div>
                          <div></div>
                          <div></div>
                        </i>

                        <p>{recording.displayName || 'Title not set'}</p>
                      </div>

                      <div>
                        <div
                          className="play-icon-container"
                          onClick={() => {
                            const audio = document.createElement('audio');
                            audio.src = recording.url;
                            audio.play().catch(e => {
                              handleUnsupportedFileType();
                            });
                          }}
                        >
                          <i></i>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </>
        }
      </div>

      <Modal
        open={deleteIndex !== -1}
        close={() => setDeleteIndex(-1)}
        title={`Delete ${(recordings[deleteIndex] && recordings[deleteIndex].displayName) ? recordings[deleteIndex].displayName : 'Sound'}`}
        buttons={[
          <button
            key="delete-sound"
            onClick={() => {
              handleDeleteSound(deleteIndex);
              setDeleteIndex(-1);
            }}
          >
            Delete
          </button>,
          <button
            key="cancel-delete-sound"
            className="success"
            onClick={() => setDeleteIndex(-1)}
          >
            Cancel
          </button>,
        ]}
      >
        <p>
          Are you sure you want to delete {(recordings[deleteIndex] && recordings[deleteIndex].displayName) ? recordings[deleteIndex].displayName : 'this sound'}?
        </p>
      </Modal>

      <Modal
        open={editIndex !== -1}
        close={() => {
          setEditIndex(-1);
          setEditName('');
          setEditOriginalName('');
        }}
        title={`Edit ${editOriginalName || 'Recording'}`}
        buttons={[
          <button
            key="modal-save"
            className="success"
            disabled={!editName || editName === editOriginalName}
            onClick={() => {
              handleSaveEditedRecording(editName, editIndex);
              setEditIndex(-1);
              setEditName('');
              setEditOriginalName('');
            }}
          >
            Save
          </button>,
          <button
            key="modal-close"
            onClick={() => {
              setEditIndex(-1);
              setEditName('');
              setEditOriginalName('');
            }}
          >
            Cancel
          </button>,
        ]}
      >
        <div className="save-recording-modal-container">
          <label>Title</label>
          <input
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
        </div>
      </Modal>

      <Modal
        open={!!recordedURL}
        close={() => {
          window.URL.revokeObjectURL(recordedURL);
          setRecordedURL('');
          setRecordedFile('');
        }}
        title="Save Recording"
        buttons={[
          <button
            key="modal-save"
            className="success"
            disabled={!newRecordingTitle}
            onClick={() => {
              handleSaveNewRecording(recordedFile, newRecordingTitle);
              setNewRecordingTitle('New Recording');
              window.URL.revokeObjectURL(recordedURL);
              setRecordedURL('');
              setRecordedFile('');
            }}
          >
            Save
          </button>,
          <button
            key="modal-close"
            onClick={() => {
              window.URL.revokeObjectURL(recordedURL);
              setRecordedURL('');
              setRecordedFile('');
            }}
          >
            Discard
          </button>,
        ]}
      >
        <div className="save-recording-modal-container">
          <label>Title</label>
          <input
            type="text"
            value={newRecordingTitle}
            onChange={(e) => setNewRecordingTitle(e.target.value)}
          />
          <audio src={recordedURL} controls autoPlay controlsList="nodownload" />
        </div>
      </Modal>
    </div>
  );
}

export default SoundsRecording;
