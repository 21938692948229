import React from 'react';

function Column({ content, children, customStyles }) {
  return (
    <>
      <div style={{
        position: 'relative',
        ...content.styles,
        backgroundImage: content.styles.backgroundImage ? `url(${content.styles.backgroundImage})` : '',
        ...customStyles,
      }} className={content.customClasses} id={content.htmlID}>
        <div style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: content.backgroundOverlayColor || 'rgba(0, 0, 0, 0)'
        }}></div>
        {children}
      </div>
    </>
  );
}

export default Column;
