import React from 'react';

import './newUserOnboardingStep2.scss';

function NewUserOnboardingStep2(props) {
  return (
    <div className="NewUserOnboardingStep2">
      <div className="filter">
        <div className="content-container">
          <h1>Class FAQ's & Important Info</h1>
          <p>✅ Videos will repeat 2-3 days in a row for each topic. Zak designed the course this way to give you plenty of time to practice the behaviors.</p>
          <p>✅ You can mark off each day upon completion. That progress will update on both the website & Pupford App.</p>
          <p>✅ Join the private community that goes along with the course by clicking on the tab once you finish this Getting Started guide.</p>
          <p>✅ This class is truly 100% free. Plus, you'll have unlimited access to all course material forever!</p>
          <button onClick={() => { props.updateStep(3) }}>Next Step</button>
        </div>
      </div>
    </div>
  );
}

export default NewUserOnboardingStep2;
