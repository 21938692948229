import React from 'react';

import './newUserOnboardingStep1.scss';

function NewUserOnboardingStep1(props) {
  return (
    <div className="NewUserOnboardingStep1">
      <div className="filter">
        <div className="content-container">
          <h1>Welcome to the 30 Day Perfect Pup Class!</h1>
          <p>Use this getting started guide to get the most out of this course 👍</p>
          <button onClick={() => { props.updateStep(2) }}>Get Started</button>
        </div>
      </div>
    </div>
  );
}

export default NewUserOnboardingStep1;
