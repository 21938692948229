import {
  SET_USER_INFO,
  SET_USER_TYPE,
  SET_USER_ZIP,
  SET_USER_DATA_TO_SYNC,
  SET_HAS_ACCESS_TO_ALL,
  SET_ACCESS_DENIED,
} from './types';

export const setUserInfo = (user) => {
  return {
    type: SET_USER_INFO,
    payload: user,
  };
};

export const setUserType = (type) => {
  return {
    type: SET_USER_TYPE,
    payload: type,
  };
};

export const setUserZip = (zip) => {
  return {
    type: SET_USER_ZIP,
    payload: zip,
  };
};

export const setUserDataToSync = (data) => {
  return {
    type: SET_USER_DATA_TO_SYNC,
    payload: data,
  };
};

export const setHasAccessToAll = (hasAccessToAll) => {
  return {
    type: SET_HAS_ACCESS_TO_ALL,
    payload: hasAccessToAll,
  };
};

export const setAccessDenied = (hasAccess) => {
  return {
    type: SET_ACCESS_DENIED,
    payload: hasAccess,
  };
};
