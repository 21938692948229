import { containerItems } from './shared';

export const column = {
  title: 'Column',
  ...containerItems,
  styles: {
    ...containerItems.styles,
    flex: 1,
    alignSelf: 'flex-start',
  },
  editableStyles: [
    ...containerItems.editableStyles,
    {
      type: 'flex',
      label: 'Flex',
      placeholder: 'Enter flex',
    },
    {
      type: 'alignSelf',
      label: 'Vertical Alignment',
      inputType: 'select',
      options: [
        'flex-start',
        'center',
        'flex-end',
        'stretch',
      ],
    },
  ],
};
