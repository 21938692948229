import { textItems } from './shared';

export const button = {
  title: 'Button',
  ...textItems,
  text: 'Click Here',
  clickAction: 'link', // link or modal
  html: '',
  link: '',
  openLinkInNewTab: false,
  linkNoFollow: false,
  styles: {
    ...textItems.styles,
    backgroundColor: 'red',
    textAlign: 'center',
    color: '#fff',
    borderRadius: '4px',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: 'bold',
    fontSize: '18px',
    cursor: 'pointer',
    alignSelf: 'inherit',
  },
  editableStyles: [
    ...textItems.editableStyles,
    {
      type: 'alignSelf',
      label: 'Align',
      inputType: 'select',
      options: [
        'inherit',
        'left',
        'center',
        'right',
      ],
    },
  ],
};
