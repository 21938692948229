import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../../../firebase';

import RatingStars from '../../shared/ratingStars';
import Card from '../../shared/card/card';
import Modal from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';
import LoadingMessage from '../../shared/loadingMessage/loadingMessage';
import ImageCropper from '../../shared/imageCropper';
import PasswordStrength from '../../../auth/passwordStrength/passwordStrength';
import ManageDogsModal from '../../shared/manageDogsModal/manageDogsModal';
import { baseURL } from '../../../../config';
import { setBehaviors, setDogBreeds, setUserInfo } from '../../../../actions';
import pencilIcon from '../../../../images/pencil.svg';
import pawIcon from '../../../../images/paw.svg';
import './profile.scss';

const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;
const numberRegex = /[0-9]/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Profile(props) {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const behaviors = useSelector((state) => state.behaviors);
  const dogBreeds = useSelector((state) => state.dogBreeds);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  const [confirmNewPasswordFocused, setConfirmNewPasswordFocused] = useState(false);
  const [passwordUpdating, setPasswordUpdating] = useState(false);
  const [currentBehaviorIndex, setCurrentBehaviorIndex] = useState(-1);
  const [currentBehaviorRating, setCurrentBehaviorRating] = useState(0);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState('');
  const [updateUsernameOpen, setUpdateUsernameOpen] = useState(false);
  const [updatedDogDetails, setUpdatedDogDetails] = useState({
    dogBirthday: moment().format('YYYY-MM-DD'),
    dogBreed: '',
    dogName: '',
  });
  const [updateDogDetailsOpen, setUpdateDogDetailsOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [addedImageSrc, setAddedImageSrc] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [imageType, setImageType] = useState('');
  const [manageDogsModalOpen, setManageDogsModalOpen] = useState(false);

  useEffect(() => {
    fetchBehaviors();
    fetchDogBreeds();
  }, []);

  useEffect(() => {
    if (!showPreview || !completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    setAddedImageSrc(null);
  }, [completedCrop, showPreview]);

  const fetchBehaviors = async () => {
    if (!behaviors.length) {
      try {
        const behaviorsSnapshot = await firebase.firestore().collection('categories').doc('behaviors').get();
        const behaviorsData = behaviorsSnapshot.data();
        const filteredBehaviors = behaviorsData.behaviors.filter(behavior => behavior !== 'None');
  
        dispatch(setBehaviors(filteredBehaviors));
      } catch (e) {
        console.log('error fetching behaviors', e);
      }
    }
  };

  const fetchDogBreeds = async () => {
    if (!dogBreeds.length) {
      try {
        const breedsSnapshot = await firebase.firestore().collection('breeds').doc('list').get();
        const breedsData = breedsSnapshot.data();
  
        dispatch(setDogBreeds(breedsData.breeds));
      } catch (e) {
        console.log('error fetching behaviors', e);
      }
    }
  };

  const openEdit = (type) => {
    switch (type) {
      case 'email':
        setUpdatedEmail(user.email || '');
        setUpdateEmailOpen(true);
        return;
      case 'username':
        setUpdatedUsername(user.userName || '');
        setUpdateUsernameOpen(true);
        return;
      case 'profileImage':
        setImageUploadOpen(true);
        return;
      case 'dogDetails':
        const dogBirthDate = user.dogBirthday ? moment(user.dogBirthday) : moment();

        setUpdatedDogDetails({
          dogBirthday: dogBirthDate.format('YYYY-MM-DD'),
          dogBreed: user.dogBreed || dogBreeds[0],
          dogName: user.dogName || '',
        });
        setUpdateDogDetailsOpen(true);
        return;
      default:
        return;
    }
  };

  const handleChangePassword = async () => {
    setPasswordUpdating(true);

    const currentUser = firebase.auth().currentUser;

    try {
      await firebase.auth().signInWithEmailAndPassword(currentUser.email, currentPassword);
      await currentUser.updatePassword(newPassword);

      setPasswordUpdating(false);
      setChangePasswordModalOpen(false);
      setModalTitle('Success!');
      setModalText('Your password has been updated!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (e) {
      setPasswordUpdating(false);
      setChangePasswordModalOpen(false);
      setModalTitle('Error:');
      setModalText('The current password provided is incorrect, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
    }
  };

  const newPasswordChanged = (e) => {
    const value = e.target.value;
    setHasLowerCase(lowerCaseRegex.test(value));
    setHasUpperCase(upperCaseRegex.test(value));
    setHasNumber(numberRegex.test(value));
    setHasLength(value.length >= 8);
    setNewPassword(value);
  };

  const saveBehaviorRating = async () => {
    const currentBehavior = behaviors[currentBehaviorIndex];
    const behaviorRating = currentBehaviorRating;

    setLoadingMessage(`Updating ${currentBehavior}...`);

    try {
      const behaviorTracking = user.behaviorTracking || {};
      const behaviorObject = {};
      let behaviors = behaviorTracking ? { ...behaviorTracking } : {};
      let behaviorRatings = [];

      if (behaviorTracking && behaviorTracking[currentBehavior]) {
        behaviorObject[currentBehavior] = {...behaviorTracking[currentBehavior]};
        behaviorRatings = [...behaviorTracking[currentBehavior].ratings, behaviorRating];
        behaviorObject[currentBehavior].ratings = behaviorRatings;
      } else {
        behaviorRatings.push(behaviorRating);
        behaviorObject[currentBehavior] = {};
        behaviorObject[currentBehavior].ratings = behaviorRatings
      }

      const newBehaviorTracking = {
        ...behaviors,
        ...behaviorObject,
      };
      const userId = firebase.auth().currentUser.uid;

      try {
        const userDogsSnapshot = await firebase.firestore().collection('user-dogs').doc(userId).get();
        const userDogsData = userDogsSnapshot.data();

        if (userDogsData && userDogsData.list) {
          const dogs = userDogsData.list;

          const currentEditDogIndex = dogs.findIndex(d => d.dogName === user.dogName);

          if (currentEditDogIndex !== -1) {
            dogs[currentEditDogIndex] = {
              ...dogs[currentEditDogIndex],
              behaviorTracking: newBehaviorTracking,
            };

            await firebase.firestore().collection('user-dogs').doc(userId).set({
              list: dogs,
            });
          }
        }
      } catch (e) {
        setLoadingMessage('');
        setModalText('There was an error updating your pup\'s profile, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
        setModalTitle('Error:');
        return;
      }

      await firebase.firestore().collection('users').doc(userId).update({
        behaviorTracking: newBehaviorTracking,
      });

      dispatch(setUserInfo({
        ...user,
        behaviorTracking: newBehaviorTracking,
      }));
      setLoadingMessage('');
      setCurrentBehaviorIndex(-1);
      setCurrentBehaviorRating(0);
    } catch (e) {
      console.log('error', e);
      setLoadingMessage('');
      setModalText('There was an error saving your updated behavior rating, please try again.');
      setModalTitle('Error:');
    }
  };

  const updateSubscriptionEmails = async (currentUser, newEmail) => {
    try {
      const userSubscriptions = await firebase.firestore().collection('subscriptions').where('userId', '==', currentUser.uid).get();

      if (userSubscriptions.empty) {
        return;
      }

      userSubscriptions.forEach(doc => {
        const newData = {
          email: newEmail,
          userEmail: newEmail,
        };

        try {
          firebase.firestore().collection('subscriptions').doc(doc.id).update(newData);
        } catch (error) {
          //could not update subscriptions
        }
      });
    } catch (error) {
        // error retrieving subscriptions
    }
  };

  const submitUpdatedEmail = async () => {
    setLoadingMessage('Updating email...');
    const currentUser = firebase.auth().currentUser;

    try {
      await firebase.auth().signInWithEmailAndPassword(currentUser.email, currentPassword);
      await currentUser.updateEmail(updatedEmail);

      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        email: updatedEmail,
      });

      await axios.post(`${baseURL}/update-klaviyo-profile`, {
        email: user.email,
        newEmail: updatedEmail,
        "What is the name of your dog?": user.dogName || '',
        "Just for fun, when is _'s birthday? (If you don't know just guess)": user.dogBirthday || Date.now(),
        "What breed is _? If a mixed breed, choose the most relevant option.": user.dogBreed || '',
      });

      updateSubscriptionEmails(currentUser, updatedEmail);

      dispatch(setUserInfo({
        ...user,
        email: updatedEmail,
      }));
      setUpdateEmailOpen(false);
      setUpdatedEmail('');
      setCurrentPassword('');
      setLoadingMessage('');
    } catch (e) {
      console.log('error', e);
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error updating your email address, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
    }
  };

  const submitUpdateUsername = async () => {
    setLoadingMessage('Updating username...');
    const currentUser = firebase.auth().currentUser;

    try {
      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        userName: updatedUsername,
      });

      dispatch(setUserInfo({
        ...user,
        userName: updatedUsername,
      }));
      setUpdateUsernameOpen(false);
      setUpdatedUsername('');
      setLoadingMessage('');
    } catch (e) {
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error updating your email address, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
    }
  };

  const submitUpdateDogDetails = async () => {
    setLoadingMessage("Updating your dog's profile...");
    const currentUser = firebase.auth().currentUser;
    const userId = currentUser.uid;
    const updatedBirthday = moment(updatedDogDetails.dogBirthday || Date.now()).valueOf();

    try {
      const userDogsSnapshot = await firebase.firestore().collection('user-dogs').doc(userId).get();
      const userDogsData = userDogsSnapshot.data();

      if (!userDogsData || !userDogsData.list) {
        await firebase.firestore().collection('user-dogs').doc(userId).set({
          list: [{
            dogBirthday: updatedBirthday,
            dogBreed: updatedDogDetails.dogBreed,
            dogName: updatedDogDetails.dogName,
            behaviorTracking: user.behaviorTracking || {},
            academyCompletion: user.academyCompletion || {},
          }],
        });
      } else {
        const dogs = userDogsData.list;

        const currentEditDogIndex = dogs.findIndex(d => d.dogName === user.dogName);

        if (currentEditDogIndex !== -1) {
          for (let i = 0; i < dogs.length; i++) {
            if (i !== currentEditDogIndex && dogs[i].dogName === updatedDogDetails.dogName) {
              setLoadingMessage('');
              setModalTitle('Error:');
              setModalText(`You have already added ${updatedDogDetails.dogName}. Please enter a unique name.`);
              return;
            }
          }

          dogs[currentEditDogIndex] = {
            ...dogs[currentEditDogIndex],
            dogBirthday: updatedBirthday,
            dogBreed: updatedDogDetails.dogBreed,
            dogName: updatedDogDetails.dogName,
          };

          await firebase.firestore().collection('user-dogs').doc(userId).set({
            list: dogs,
          });
        }
      }
    } catch (e) {
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error updating your pup\'s profile, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
      return;
    }

    try {
      await firebase.firestore().collection('users').doc(currentUser.uid).update({
        dogName: updatedDogDetails.dogName,
        dogBirthday: updatedBirthday,
        dogBreed: updatedDogDetails.dogBreed,
      });

      await axios.post(`${baseURL}/update-klaviyo-profile`, {
        email: user.email,
        newEmail: user.email,
        "What is the name of your dog?": updatedDogDetails.dogName || '',
        "Just for fun, when is _'s birthday? (If you don't know just guess)": updatedBirthday,
        "What breed is _? If a mixed breed, choose the most relevant option.": updatedDogDetails.dogBreed,
      });

      dispatch(setUserInfo({
        ...user,
        dogName: updatedDogDetails.dogName,
        dogBirthday: updatedBirthday,
        dogBreed: updatedDogDetails.dogBreed,
      }));
      setUpdateDogDetailsOpen(false);
      setUpdatedDogDetails({
        dogBirthday: moment().format('YYYY-MM-DD'),
        dogBreed: '',
        dogName: '',
      });
      setLoadingMessage('');
    } catch (e) {
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error updating your dog\'s profile, please try again. If this problem persists, contact us at hello@pupford.com for assistance.');
    }
  };

  const handleProfileImageInputChange = (e) => {
    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];
    e.target.value = null;

    setImageType(file.type);
    setImageUploadOpen(false);

    if (!file.type.includes('image/')) {
      setModalTitle('Notice!');
      setModalText('Only image files can be uploaded.');
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setAddedImageSrc(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const closeImageCropperModal = () => {
    setAddedImageSrc(null);
    setShowPreview(false);
    setCrop({ unit: '%', width: 30, aspect: 1 });
    setCompletedCrop(null);
    imgRef.current = null;
    previewCanvasRef.current = null;
  };

  const saveProfileImage = () => {
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    if (!crop || !canvas || !imageType) {
      return;
    }
  
    canvas.toBlob(handleUploadProfileImage, imageType, 1);
  };

  const handleUploadProfileImage = async (blob) => {
    closeImageCropperModal();
    setLoadingMessage('Updating profile picture...');

    const currentUser = firebase.auth().currentUser;
    const userId = currentUser.uid;
    const imageFileExtension = imageType.split('/');
    const newFileName = `profile-image-${Date.now()}.${imageFileExtension[1] || 'png'}`;

    try {
      const storageRef = firebase.storage().ref();
      const userUploadsRef = storageRef.child(`userUploads/${userId}/${newFileName}`);
      await userUploadsRef.put(blob);
      const downloadUrl = await userUploadsRef.getDownloadURL();
      const storageUrl = downloadUrl.replace(
          'https://firebasestorage.googleapis.com/v0/b/pupford-6b94c.appspot.com/o/',
          'https://pupford.b-cdn.net/',
      );

      await firebase.firestore().collection('users').doc(userId).update({
        profileImage: {
          url: storageUrl,
          fileName: newFileName,
        },
      });

      const oldImageName = user.profileImage ? user.profileImage.fileName : '';
      if (oldImageName) {
        await axios.post(`${baseURL}/delete-old-profile-images/${userId}`, {
          fileName: oldImageName,
        });
      }

      dispatch(setUserInfo({
        ...user,
        profileImage: {
          url: storageUrl,
          fileName: newFileName,
        },
      }));
      setLoadingMessage('');
    } catch (e) {
      console.log('error', e);
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error saving your profile picture, please try again.');
    }
  };

  return (
    <div className="Profile">
      {!loadingMessage ? null : <LoadingMessage message={loadingMessage} />}

      {!user.coursesSet ?
        <div className="loading-container">
          <Loader size={2} />
          <p>Loading your profile...</p>
        </div> :
        <Card>
          <div className="profile-content">
            <div className="profile-group">
              <h3>Email <img src={pencilIcon} alt="edit" onClick={() => openEdit('email')} /></h3>
              <p>{user.email || 'Not set'}</p>

              <h3>Username <img src={pencilIcon} alt="edit" onClick={() => openEdit('username')} /></h3>
              <p>{user.userName || 'Not set'}</p>

              <h3>Profile Picture <img src={pencilIcon} alt="edit" onClick={() => openEdit('profileImage')} /></h3>
              
              <div className="profile-image-container">
                {!(user.profileImage && user.profileImage.url) ?
                  <img src={pawIcon} alt="profile" className="paw-icon" /> :
                  <img src={user.profileImage.url} alt="User"/>
                }
              </div>

              <div className="dogs-profile-header-row">
                <h3>Dog's Profile <img src={pencilIcon} alt="edit" onClick={() => openEdit('dogDetails')} /></h3>

                <button className="switch-dogs-button" onClick={() => setManageDogsModalOpen(true)}>
                  Switch Dogs
                </button>
              </div>

              <p><strong>Name:</strong> {user.dogName || 'Not set'}</p>
              <p><strong>Birthday:</strong> {moment(user.dogBirthday || new Date()).format('MMM DD, YYYY')}</p>
              <p><strong>Breed:</strong> {user.dogBreed || 'Not set'}</p>

              <p><strong>Behaviors:</strong></p>

              {behaviors.map((behavior, i) => {
                const userRatings = user.behaviorTracking || {};
                const rating = userRatings[behavior] ? userRatings[behavior].ratings[userRatings[behavior].ratings.length - 1] : 0;

                return (
                  <div
                    key={behavior}
                    className="behavior-row link-item"
                    onClick={() => {
                      setCurrentBehaviorIndex(i);
                      setCurrentBehaviorRating(rating);
                    }}
                  >
                    <p>{behavior}</p>

                    <div>
                      <RatingStars
                        numberOfStars={5}
                        rating={rating}
                        starRatedColor="#fc9a26"
                        starDimension="17px"
                        starSpacing="0px"
                      />
                    </div>
                  </div>
                );
              })}

              <div className="section-divider"></div>

              <h3>General</h3>
              <a href="https://s3.us-east-2.amazonaws.com/30-day-perfect-pup-manual/30-Day-Perfect-Pup-With-Zak-George.pdf" target="_blank" rel="noopener noreferrer">
                <p className="link-item">eBook</p>
              </a>

              <a href="https://pupford.com/pupford-academy-input-survey/" target="_blank" rel="noopener noreferrer">
                <p className="link-item">Provide Feedback</p>
              </a>
              {/* <p>FAQs</p> */}
              <p onClick={() => { setChangePasswordModalOpen(true) }} className="link-item">Update Password</p>
            </div>
            <div className="profile-group">
              <h3>Legal</h3>
              <a href="https://pupford.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
                <p className="link-item">Terms of Use</p>
              </a>
              <a href="https://pupford.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                <p className="link-item">Privacy Policy</p>
              </a>
            </div>
            <div className="profile-group">
              <h3>Contact Us</h3>
              <div className="social-items">
                <a href="https://www.facebook.com/pupforddogs" target="_blank" rel="noopener noreferrer">
                  <div className="social-item">
                    <img className="social-image" src="https://pupford.com/wp-content/themes/pupford/icons/facebook.svg" alt="facebook" />
                  </div>
                </a>
                <a href="https://www.instagram.com/pupford" target="_blank" rel="noopener noreferrer">
                  <div className="social-item">
                    <img className="social-image" src="https://pupford.com/wp-content/themes/pupford/icons/instagram.svg" alt="instagram" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Card>
      }

      <ManageDogsModal
        open={manageDogsModalOpen}
        close={(modalData) => {
          setManageDogsModalOpen(false);

          if (modalData && modalData.modalTitle && modalData.modalText) {
            setModalTitle(modalData.modalTitle);
            setModalText(modalData.modalText);
          }
        }}
        currentDog={{
          dogBirthday: user.dogBirthday || moment().valueOf(),
          dogBreed: user.dogBreed || dogBreeds[0] || '',
          dogName: user.dogName || '',
          behaviorTracking: user.behaviorTracking || {},
          academyCompletion: user.academyCompletion || {},
        }}
        dogBreeds={dogBreeds || []}
        user={user}
      />

      <Modal
        open={changePasswordModalOpen}
        close={() => { setChangePasswordModalOpen(false) }}
        title="Update Password"
        buttons={[
          <button
            style={{width: '80px'}}
            key="change-password-submit"
            onClick={handleChangePassword}
            disabled={passwordUpdating || !currentPassword || (newPassword !== confirmNewPassword) || (!hasLowerCase || !hasUpperCase || !hasNumber || !hasLength)}
          >{!passwordUpdating ? 'Submit' : <Loader size={1}/>}</button>,
          <button key="change-password-cancel" onClick={() => { setChangePasswordModalOpen(false) }} className="secondary">Cancel</button>,
        ]}
      >
        <div>
          <label>
            Current Password:
          </label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => { setCurrentPassword(e.target.value); }}
          />

          <div className="new-password-container">
            <label>
              New Password:
            </label>
            <input
              type="password"
              value={newPassword}
              onBlur={() => {
                setNewPasswordFocused(false);
              }}
              onFocus={() => { setNewPasswordFocused(true) }}
              onChange={newPasswordChanged}
            />
            {!newPasswordFocused ? null : 
              <PasswordStrength
                hasLowerCase={hasLowerCase}
                hasUpperCase={hasUpperCase}
                hasNumber={hasNumber}
                hasLength={hasLength}
              />
            }
          </div>

          <div className="new-password-container">
            <label>
              Confirm New Password:
            </label>
            <input
              type="password"
              value={confirmNewPassword}
              onBlur={(e) => {
                setConfirmNewPasswordFocused(false);
              }}
              onFocus={() => { setConfirmNewPasswordFocused(true) }}
              onChange={(e) => { setConfirmNewPassword(e.target.value); }}
            />
            {!confirmNewPasswordFocused || !newPassword ? null : 
              <div className="password-strength-container">
                <div className="password-strength-container-inner">
                  <div className={`password-match-title ${(newPassword === confirmNewPassword) ? 'match' : 'do-not-match'}`}>
                    Passwords {(newPassword === confirmNewPassword) ? 'match': 'do not match'}
                  </div>
                  <div className="password-reset-arrow password-confirm-arrow"></div>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>

      <Modal
        open={currentBehaviorIndex !== -1}
        close={() => {
          setCurrentBehaviorIndex(-1);
          setCurrentBehaviorRating(0);
        }}
        buttons={[
          <button className="success" key="rating-save" onClick={saveBehaviorRating}>Save</button>,
          <button key="rating-close" onClick={() => {
            setCurrentBehaviorIndex(-1);
            setCurrentBehaviorRating(0);
          }}>Close</button>,
        ]}
      >
        <div className="behavior-modal">
          <p className="behavior-modal-title">How did {user.dogName || 'your dog'} do with {behaviors[currentBehaviorIndex]} today?</p>
          <p className="behavior-modal-text">Ratings help us recommend the best courses for your pup!</p>

          <div className="behavior-modal-rating">
            <RatingStars
              numberOfStars={5}
              rating={currentBehaviorRating}
              changeRating={(rating) => { setCurrentBehaviorRating(rating) }}
              starRatedColor="#fc9a26"
              starHoverColor="#fc9a26"
              starDimension="35px"
              starSpacing="0px"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={updateEmailOpen}
        close={() => {
          setUpdateEmailOpen(false);
          setUpdatedEmail('');
          setCurrentPassword('');
        }}
        title="Update Email Address"
        buttons={[
          <button key="modal-submit" className="success" disabled={!emailRegex.test(updatedEmail) || !currentPassword} onClick={() => {
            submitUpdatedEmail();
          }}>Submit</button>,
          <button key="modal-close" onClick={() => {
            setUpdateEmailOpen(false);
            setUpdatedEmail('');
            setCurrentPassword('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div>
            <label>Password</label>
            <input
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
              type="password"
            />
          </div>
          <div>
            <label>Email Address</label>
            <input
              value={updatedEmail}
              onChange={(e) => {
                setUpdatedEmail(e.target.value);
              }}
              type="email"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={updateUsernameOpen}
        close={() => {
          setUpdateUsernameOpen(false);
          setUpdatedUsername('');
        }}
        title="Update Username"
        buttons={[
          <button key="modal-submit" className="success" disabled={!updatedUsername} onClick={() => {
            submitUpdateUsername();
          }}>Submit</button>,
          <button key="modal-close" onClick={() => {
            setUpdateUsernameOpen(false);
            setUpdatedUsername('');
          }}>Close</button>,
        ]}
      >
        <div>
          <label>Username</label>
          <input
            value={updatedUsername}
            onChange={(e) => {
              setUpdatedUsername(e.target.value);
            }}
            type="text"
          />
        </div>
      </Modal>

      <Modal
        open={updateDogDetailsOpen}
        close={() => {
          setUpdateDogDetailsOpen(false);
          setUpdatedDogDetails({
            dogBirthday: moment().format('YYYY-MM-DD'),
            dogBreed: '',
            dogName: '',
          });
        }}
        title="Update Your Dog's Profile"
        buttons={[
          <button key="modal-submit" className="success" onClick={() => {
            submitUpdateDogDetails();
          }}>Submit</button>,
          <button key="modal-close" onClick={() => {
            setUpdateDogDetailsOpen(false);
            setUpdatedDogDetails({
              dogBirthday: moment().format('YYYY-MM-DD'),
              dogBreed: '',
              dogName: '',
            });
          }}>Close</button>,
        ]}
      >
        <div>
          <div>
            <label>Your Dog's Name</label>
            <input
              value={updatedDogDetails.dogName}
              onChange={(e) => {
                setUpdatedDogDetails({
                  ...updatedDogDetails,
                  dogName: e.target.value,
                });
              }}
              placeholder="Enter your dog's name"
              type="text"
            />
          </div>
          <div className="dog-breed-select">
            <label>Your Dog's Breed</label>
            <select value={updatedDogDetails.dogBreed} onChange={(e) => {
              setUpdatedDogDetails({
                ...updatedDogDetails,
                dogBreed: e.target.value,
              });
            }}>
              {dogBreeds.map(breed => {
                return <option key={breed} value={breed}>{breed}</option>
              })}
            </select>
          </div>
          <div>
            <label>Your Dog's Birthday</label>
            <input
              value={updatedDogDetails.dogBirthday}
              onChange={(e) => {
                setUpdatedDogDetails({
                  ...updatedDogDetails,
                  dogBirthday: e.target.value,
                });
              }}
              type="date"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={imageUploadOpen}
        close={() => { setImageUploadOpen(false) }}
        title={'Upload Profile Image'}
        buttons={[
          <button key="image-upload-close" onClick={() => { setImageUploadOpen(false) }}>Close</button>,
        ]}
      >
        <div
          className={`profile-image-upload-modal${dropzoneActive ? ' drag-over' : ''}`}
          onDragEnter={() => setDropzoneActive(true)}
          onDragLeave={() => setDropzoneActive(false)}
          onDrop={() => setDropzoneActive(false)}
        >
          <p>Click or drag and drop image here.</p>

          <input
            className="profile-image-upload-dropzone"
            type="file"
            onChange={handleProfileImageInputChange}
            accept="image/*"
          />
        </div>
      </Modal>

      <Modal
        open={!!addedImageSrc || !!showPreview}
        close={closeImageCropperModal}
        title=""
        buttons={[
          <button key="image-copper-confirm" className="success" onClick={() => {
            if (!showPreview) {
              setShowPreview(true);
              return;
            }

            saveProfileImage();
          }}>{showPreview ? 'Save' : 'Preview'}</button>,
          <button key="image-copper-close" onClick={closeImageCropperModal}>Close</button>,
        ]}
      >
        <div style={{textAlign: 'center'}}>
          {!addedImageSrc ? null :
            <ImageCropper
              src={addedImageSrc}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              keepSelection
              ruleOfThirds
              circularCrop
            />
          }
          {!showPreview ? null :
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
                borderRadius: '50%',
              }}
            />
          }
        </div>
      </Modal>

      <Modal
        open={!!modalTitle}
        close={() => { setModalTitle('') }}
        title={modalTitle}
        buttons={[
          <button key="notice-close" onClick={() => { setModalTitle('') }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
