import React from 'react';

import './soundsTraining.scss';
import Card from '../../../shared/card/card';
import clickerImage from '../../../../../images/clicker.png';

const clickerSoundFile = 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1620681408594-1617916240979-ClockStopwatch%20TL02_48_1.mp3?alt=media&token=9faf08e8-db10-4582-9488-876356c6e683';

function SoundsTraining({
  hasAccess,
}) {
  return (
    <div className="SoundsTraining">
      <div
        className="sound-container"
        onClick={() => {
          const audio = new Audio(clickerSoundFile);
          audio.play();
        }}
      >

        <div className="sound-inner">
          <img src={clickerImage} alt='Clicker' />
        </div>
        <div className="sound-label-container">
          <p>Clicker</p>
        </div>
      </div>
    </div>
  );
}

export default SoundsTraining;
