import React, { useRef, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch } from 'react-redux';
import firebase from '../../../../firebase';

import Loader from '../loader/loader';
import SideBySideMagnifier from '../SideBySideMagnifier';
import { addToCart } from '../../../../actions';
import './physicalProductModal.scss';

import plusIcon from '../../../../images/plus.svg';
import minusIcon from '../../../../images/minus.svg';

function PhysicalProductModal({
  simplifiedProduct = {},
  close,
}) {
  const dispatch = useDispatch();
  const selectEl = useRef(null);
  const currentImageRef = useRef(null);
  const imagesContainerRef = useRef(null);
  const imageMouseDownInterval = useRef(null);
  const [finishedLoadingProduct, setFinishedLoadingProduct] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [currentProductImage, setCurrentProductImage] = useState({});
  const [currentProductImageIndex, setCurrentProductImageIndex] = useState(0);
  const [showOtherFiles, setShowOtherFiles] = useState(false);
  const [currentImageSelectionHeight, setCurrentImageSelectionHeight] = useState(undefined);
  const [salePrice, setSalePrice] = useState('');
  const [subscriptionSelected, setSubscriptionSelected] = useState(false);
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState(0);
  const [firstTimeSavingsPercent, setFirstTimeSavingsPercent] = useState('');
  const [subscriptionSavingsPercent, setSubscriptionSavingsPercent] = useState('');

  useEffect(() => {
    if (currentImageRef && currentImageRef.current && currentImageRef.current.clientHeight) {
      setCurrentImageSelectionHeight(currentImageRef.current.clientHeight);
    } else {
      setCurrentImageSelectionHeight(undefined);
    }
  }, [currentProductImage]);

  useEffect(() => {
    if (simplifiedProduct.id) {
      fetchFullProduct();
    }
  }, [simplifiedProduct]);

  const fetchFullProduct = async () => {
    try {
      const db = firebase.firestore();
      const productSnapshot = await db.collection('products-v2').doc(simplifiedProduct.id).get();
      const productData = productSnapshot.data();

      setProduct({
        ...productData,
        id: simplifiedProduct.id,
      });
      setFinishedLoadingProduct(true);
      setCurrentProductImage(productData.media[0]);
      setSalePrice((productData.isOnSale && productData.salePrice) ? productData.salePrice : '');

      if (productData.subscriptionOptions.length) {
        if (+productData.subscriptionOptions[0].discountPercentage) {
          setSubscriptionSavingsPercent(+productData.subscriptionOptions[0].discountPercentage);
        }
  
        if (+productData.subscriptionOptions[0].firstOrderDiscountPercentage) {
          setFirstTimeSavingsPercent(+productData.subscriptionOptions[0].firstOrderDiscountPercentage);
        }

        setSubscriptionSelected(true);
        setSelectedSubscriptionIndex(0);

        if (!productData.selectedSubscriptionProduct) {
          setSalePrice(calculateSubscriptionPrice(0, productData));
        }
      }

      const fbq = window.fbq || function() {};
      fbq('track', 'ViewContent');

      const gtag = window.gtag || function() {};
      gtag('event', 'view_item', {
        items: [
          {
            id: simplifiedProduct.id,
            name: productData.name,
            brand: 'Pupford',
            category: productData.categories.join('/'),
            price: +productData.price,
          },
        ],
      });
    } catch (e) {
      console.log(e);
      setErrorMessage(`There was an error retrieving the details for ${simplifiedProduct.name}. If this problem persists, please contact us.`);
    }
  };

  const calculateSubscriptionPrice = (index, product) => {
    const priceToUse = product.selectedSubscriptionProduct ? product.selectedSubscriptionProduct.price : +product.price;
    const discountPercentage = +(product.subscriptionOptions[index].discountPercentage);
    const firstTimeDiscountPercentage = +(product.subscriptionOptions[index].firstOrderDiscountPercentage);

    if (firstTimeDiscountPercentage) {
      return (priceToUse * (1 - (firstTimeDiscountPercentage / 100))).toFixed(2);
    }

    if (!discountPercentage) {
      return '';
    }

    return (priceToUse * (1 - (discountPercentage / 100))).toFixed(2);
  };

  const renderCurrentMedia = () => {
    if (currentProductImage.contentType === 'video' && currentProductImage.url.includes('player.vimeo')) {
      const videoUrl = currentProductImage.url.split('.hd')[0];
      const videoUrlSegments = videoUrl.split('/');
      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
      const options = [];

      if (currentProductImage.autoplay) {
        options.push('autoplay');
      }

      if (currentProductImage.muted) {
        options.push('muted', 'cc');
      }

      let query = `${options.length ? '?' : ''}`;

      options.forEach((option, i) => {
        if (i === 0) {
          query += `${option}=1`;
        } else {
          query += `&${option}=1`;
        }
      });

      return (
        <div className="videoContainerOuter" ref={currentImageRef}>
          <div className="videoContainer">
            <div className="videoInner">
              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={currentImageRef} className="selectedImageContainer">
        <SideBySideMagnifier
          imageSrc={currentProductImage.url || product.media[0].url}
          imageAlt={currentProductImage.alt || product.media[0].alt}
          alwaysInPlace={false}
          fillAvailableSpace={false}
        />
      </div>
    );
  };

  const handleImageChange = (image, i) => {
    setCurrentProductImage(image);
    setCurrentProductImageIndex(i);
    setShowOtherFiles(true);
  };

  const handleImageContainerScroll = (goUp) => {
    const amountToScroll = goUp ? -5 : 5;

    if (imagesContainerRef && imagesContainerRef.current) {
      imagesContainerRef.current.scrollTop += amountToScroll;
    }
  };

  const handleImageArrowMouseDown = (goUp) => {
    handleImageContainerScroll(goUp);

    const mouseDownInterval = setInterval(() => {
      handleImageContainerScroll(goUp);
    }, 15);

    imageMouseDownInterval.current = mouseDownInterval;
  };

  const handleImageArrowMouseUp = () => {
    if (imageMouseDownInterval && imageMouseDownInterval.current && imageMouseDownInterval.current) {
      clearInterval(imageMouseDownInterval.current);
      imageMouseDownInterval.current = false;
    }
  };

  if (!finishedLoadingProduct) {
    return (
      <div className="PhysicalProductModal">
        <div className="initial-product-container">
          <div className="image-container well">
            <img src={simplifiedProduct.image && simplifiedProduct.image.url} alt={simplifiedProduct.name} />
          </div>

          <div className="initial-right-container">
            <p className="product-name">{simplifiedProduct.name}</p>

            <div className="loading-container">
              <Loader size={2} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="PhysicalProductModal">
      {errorMessage ? <p>{errorMessage}</p> :
        <div className="product-content">
          <div className="imageContainerOuter">
            <div className="imageContainer">
              <div className="selectedImage">
                {renderCurrentMedia()}
              </div>
              <div className="selectedImageMobile">
                <SwipeableViews index={currentProductImageIndex} onChangeIndex={(index) => { handleImageChange(product.media[index], index) }} enableMouseEvents>
                  {product.media.map((m, i) => {
                    if (!showOtherFiles && i !== 0) {
                      return <div key={`swipable-${i}`}></div>;
                    }

                    if (m.contentType === 'video' && m.url.includes('player.vimeo')) {
                      const videoUrl = m.url.split('.hd')[0];
                      const videoUrlSegments = videoUrl.split('/');
                      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
                      const options = [];

                      if (m.autoplay) {
                        options.push('autoplay');
                      }

                      if (m.muted) {
                        options.push('muted', 'cc');
                      }

                      let query = `${options.length ? '?' : ''}`;

                      options.forEach((option, i) => {
                        if (i === 0) {
                          query += `${option}=1`;
                        } else {
                          query += `&${option}=1`;
                        }
                      });

                      return (
                        <div key={`swipable-${i}`} className="videoContainerOuter">
                          <div className="videoContainer">
                            <div className="videoInner">
                              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <img
                        key={`swipable-${i}`}
                        src={m.url || product.media[0].url}
                        className="lazyload"
                      />
                    );
                  })}
                </SwipeableViews>
              </div>
              <div className="imageSelection" style={{height: currentImageSelectionHeight}}>
                <div className="imagesContainer" ref={imagesContainerRef}>
                  {product.media.map((image, i) => {
                    return (
                      <div className="mediaItemContainer" key={`product-image-${i}`}>
                        {image.contentType === 'video' ?
                          <div className="videoThumbnailContainer" onClick={() => { handleImageChange(image, i) }} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}>
                            <img height="50" src="https://pupford.com/images/play-icon.svg" className="playIcon lazyload" alt="play icon" />
                            <img src={image.placeholder} className="thumbnailImage lazyload" alt={image.alt}/>
                          </div> :
                          <img src={image.url} className="desktopImageThumbnail lazyload" alt={image.alt} onClick={() => { handleImageChange(image, i) }} style={{ borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}/>
                        }
                        <div className="imageSelectionButton" onClick={() => { handleImageChange(image, i) }} style={{ backgroundColor: !currentProductImage.url ? (i === 0 ? '#000' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#000' : 'rgba(0, 0, 0, 0.1)') }}></div>
                      </div>
                    );
                  })}
                </div>

                <div className="imageArrowContainer">
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(true)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Up" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(false)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Down" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="productDetailsContainer">
            <p className="product-name">{product.name}</p>

            {!product.categories.length ? null : <div className="categoriesContainer">
              {product.categories.map((category, i) => {
                return (
                  <div key={`category-${i}`} className="category">
                    <p>{category}</p>
                  </div>
                );
              })}
            </div>}

            {product.selectedSubscriptionProduct ? null :
              <>
                {(!product.subscriptionOptions.length || product.subscriptionOnly) ? null :
                  <div className="oneTimeOrSubRow">
                    <button
                      className={`${subscriptionSelected ? 'unselected' : ''} oneTimePurchaseButton`}
                      onClick={() => {
                        setSubscriptionSelected(false);
                        setSalePrice(null);
                        if (product.salePrice && product.isOnSale) {
                          setSalePrice(product.salePrice);
                        }
                        setFirstTimeSavingsPercent(null);
                        setSubscriptionSavingsPercent(null);
                      }}
                    >
                      <div className="buttonRadio">
                        <div className="radioInner"></div>
                      </div>
                      One-Time Purchase
                    </button>
                    <button
                      className={`${subscriptionSelected ? '' : 'unselected'}`}
                      onClick={() => {
                        setSubscriptionSelected(true);
                        setSalePrice(null);

                        if (product.selectedSubscriptionProduct && product.isOnSale) {
                          setSalePrice(product.salePrice);
                        } else {
                          setSalePrice(calculateSubscriptionPrice(selectedSubscriptionIndex, product));
                        }

                        if (+product.subscriptionOptions[selectedSubscriptionIndex].discountPercentage) {
                          setSubscriptionSavingsPercent(+product.subscriptionOptions[selectedSubscriptionIndex].discountPercentage);
                        }
    
                        if (+product.subscriptionOptions[selectedSubscriptionIndex].firstOrderDiscountPercentage) {
                          setFirstTimeSavingsPercent(+product.subscriptionOptions[selectedSubscriptionIndex].firstOrderDiscountPercentage);
                        }
                      }}
                    >
                      <div className="buttonRadio">
                        <div className="radioInner"></div>
                      </div>
                      Subscribe & Save
                    </button>
                  </div>
                }

                {!subscriptionSelected ? null :
                  <div className="subscriptionSelectContainer">
                    {product.subscriptionOptions.length <= 1 ? null :
                      <div className="selectArrow" onClick={() => {
                        selectEl.current.focus();
                      }}>▾</div>
                    }
                    <select
                      ref={selectEl}
                      value={selectedSubscriptionIndex}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedSubscriptionIndex(value);
                        setFirstTimeSavingsPercent(null);
                        setSubscriptionSavingsPercent(null);
                        setSalePrice(null);

                        if (product.selectedSubscriptionProduct && product.isOnSale) {
                          setSalePrice(product.salePrice);
                        } else {
                          setSalePrice(calculateSubscriptionPrice(value, product));
                        }

                        if (+product.subscriptionOptions[value].discountPercentage) {
                          setSubscriptionSavingsPercent(+product.subscriptionOptions[value].discountPercentage);
                        }

                        if (+product.subscriptionOptions[value].firstOrderDiscountPercentage) {
                          setFirstTimeSavingsPercent(+product.subscriptionOptions[value].firstOrderDiscountPercentage);
                        }
                      }}
                      disabled={product.subscriptionOptions.length <= 1}
                    >
                      {product.subscriptionOptions.map((option, i) => {
                        const plural = option.interval > 1;
                        return <option key={`subscription-option-${i}`} value={i}>Every {`${plural ? option.interval : ''} ${option.period}${plural ? 's' : ''}`}</option>
                      })}
                    </select>
                  </div>
                }

                {product.isDigital ? null :
                  <div className="amountContainer">
                    <div className="amountContainerInner">
                      <div className="quantityButton">
                        <button
                          className="quantityButtonInner"
                          onClick={() => {
                            const updatedQuantity = +quantity - 1;

                            if (updatedQuantity === 0) {
                              return;
                            }

                            setQuantity(updatedQuantity);
                          }}
                        >
                          <img src={minusIcon} alt="add" width="15" />
                        </button>
                      </div>
                      <div className="quantityAmount">{quantity}</div>
                      <div className="quantityButton">
                        <button
                          className="quantityButtonInner"
                          onClick={() => {
                            const updatedQuantity = +quantity + 1;

                            setQuantity(updatedQuantity);
                          }}
                        >
                          <img src={plusIcon} alt="add" width="15" />
                        </button>
                      </div>
                    </div>
                  </div>
                }

                <div className="buyRow">
                  <div className="priceLine">
                    {!salePrice ? null : <p className="productPrice productSalePrice">${salePrice}</p>}
                    <p className={`productPrice ${salePrice ? 'originalPrice' : ''}`}>${product.price}</p>
                  </div>

                  <button onClick={() => {
                    const productToAdd = {
                      productId: product.id,
                      hasMembership: product.memberships.length,
                      name: product.name,
                      image: product.media[0],
                      path: product.path,
                      sku: product.sku,
                      isDigital: product.isDigital,
                      originalPrice: product.price,
                      salePrice: product.isOnSale ? product.salePrice : product.price,
                      price: salePrice || product.price,
                      subscription: subscriptionSelected ? product.subscriptionOptions[selectedSubscriptionIndex] : null,
                      subscriptionOptions: product.subscriptionOptions || [],
                      selectedSubscriptionIndex: selectedSubscriptionIndex,
                      subscriptionOnly: product.subscriptionOnly || false,
                      tags: product.tags || [],
                      categories: product.categories || [],
                      shippingClass: product.shippingClass,
                      quantity: quantity,
                      taxCode: product.taxCode,
                      customThankYouPagePath: product.customThankYouPagePath || '',
                      customThankYouPagePriority: product.customThankYouPagePriority || 1,
                    };

                    if (product.selectedSubscriptionProduct) {
                      productToAdd.selectedSubscriptionProduct = product.selectedSubscriptionProduct;
                      productToAdd.customFirstOrderShipping = (product.hasCustomFirstOrderShipping && product.customFirstOrderShipping) ? product.customFirstOrderShipping : false;
                    }

                    const fbq = window.fbq || function() {};
                    fbq('track', 'AddToCart');

                    const gtag = window.gtag || function() {};
                    gtag('event', 'add_to_cart', {
                      items: [
                        {
                          id: product.id,
                          name: product.name,
                          brand: 'Pupford',
                          category: product.categories.join('/'),
                          price: salePrice || product.price,
                        },
                      ],
                    });

                    dispatch(addToCart(productToAdd));
                    close();
                  }}>
                    Add to Cart
                  </button>
                </div>
              </>
            }

            <p className="description">{product.description}</p>

            <a href={`https://pupford.com/product/${product.path}`} target="_blank">
              <div className="view-full-details">View Full Details</div>
            </a>
          </div>
        </div>
      }
    </div>
  );
}

export default PhysicalProductModal;
