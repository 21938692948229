import React, { useRef, useEffect } from 'react';

import styles from './buttonPreviewModal.module.scss';

function ButtonPreviewModal({ open, close, title, children, buttons }) {
  const modalEl = useRef(null);

  useEffect(() => {
    if (open) {
      document.body.append(modalEl.current);
    } else if (modalEl.current) {
      document.body.removeChild(modalEl.current);
    }
  }, [open]);

  if (!open && !modalEl.current) {
    return <div></div>;
  }

  return (
    <div ref={modalEl} className={styles.ButtonPreviewModal}>
      <div className={styles.modalBody}>
        <div className={styles.closeButton} onClick={close}>
          <i>×</i>
        </div>

        {!title ? null : <h3 className={styles.title}>{title}</h3>}
        <div className={styles.modalContent}>{children}</div>
        {!buttons ? null : <div className={styles.buttonsContainer}>{buttons}</div>}
      </div>
    </div>
  );
}

export default ButtonPreviewModal;
