import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from '../../../../firebase';

import { setTrainingTools } from '../../../../actions';
import Card from '../../shared/card/card';
import Loader from '../../shared/loader/loader';
import Modal from '../../shared/modal/modal';
import PhysicalProductModal from '../../shared/physicalProductModal/physicalProductModal';
import './dashboard.scss';

const defaultModule = {
  image: "https://pupford.b-cdn.net/assets%2Fdigital-products%2F21ImpulseControlGames%2F1604545370783-patrick-hendry-nk_uvN_b-w8-unsplash.jpg?alt=media&token=4670556a-2222-4e35-a796-e49e9164e4c1",
  title: "Pupford Academy",
  description: "Take your pup to the next level",
  path: '/courses',
};

const defaultAcademyModules = [
  {
    backgroundImage: 'https://pupford.b-cdn.net/assets%2Fdigital-products%2F21ImpulseControlGames%2FBoundariesBud%2F1604530190155-Boundaries-Bud-Thumbnail.png?alt=media&token=8d2c37df-24d7-4e92-8e77-c901ba78808f',
    title: 'Boundaries, Bud',
    description: 'Great for teaching your dog boundaries within your home.',
    path: '/course/191824/0',
    isFreeCourse: false,
    courseId: '191824',
  },
  {
    backgroundImage: 'https://pupford.b-cdn.net/assets%2Fdigital-products%2Fseparation-anxiety-course%2Fhero-images%2F1599591224051-separation%20anxiety%20course%20hero%20image.jpg?alt=media&token=d6088e03-cf03-4c89-9b9c-53a1005915b7',
    title: 'Separation Anxiety Course',
    description: "Effective and humanely overcome your dog's separation anxiety.",
    path: '/course/500600/1',
    isFreeCourse: false,
    courseId: '500600',
  },
  {
    backgroundImage: 'https://pupford.b-cdn.net/assets%2Fdigital-products%2Ftrick-training%2F1604585401174-brown-dog-doing-trick-training.jpg?alt=media&token=fb49af9b-d5e4-41ab-83db-2b4e384d548c',
    title: 'Trick Training',
    description: 'Teach Your Dog 20 Fun Tricks and Improve Your Communication With Your Dog',
    path: '/course/399703/1',
    isFreeCourse: false,
    courseId: '399703',
  },
];

function Dashboard(props) {
  const dispatch = useDispatch();
  const trainingTools = useSelector(state => state.trainingTools.mainTools);
  const user = useSelector(state => state.user);
  const courses = useSelector(state => state.courses);
  const freeCourses = useSelector(state => state.freeCourses.courses);
  const [nextModule, setNextModule] = useState(defaultModule);
  const [mainTrainingTools, setMainTrainingTools] = useState([]);
  const [recommendedModules, setRecommendedModules] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productModalOpen, setProductModalOpen] = useState(false);

  useEffect(() => {
    fetchTrainingTools();
  }, []);

  const fetchTrainingTools = async () => {
    if (trainingTools.length) {
      setMainTrainingTools(trainingTools);
      return;
    }

    try {
      const querySnapshot = await firebase.firestore().collection('training-tools').doc('training-tools').get();
      const tools = querySnapshot.data();

      setMainTrainingTools(tools.mainTools);
      dispatch(setTrainingTools(tools));
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (user && freeCourses.length && courses.length) {
      checkNextModule();
      getRecommendedModules();
    }
  }, [user, freeCourses, courses]);

  const checkNextModule = () => {
    const lastCompletedModule = user.lastCompletedModule;

    if (!lastCompletedModule && freeCourses.length) {
      const fullCourse = freeCourses[0] || {};
      const module = (fullCourse.modules && fullCourse.modules.length) ? fullCourse.modules[0] : {};

      setNextModule({
        image: module.backgroundImage,
        title: module.title,
        description: module.description,
      });
    } else {
      const { lastCourseId, lastModuleId } = lastCompletedModule;
      const freeCourseIndex = freeCourses.findIndex(course => course.id === lastCourseId);
      const courseIndex = courses.findIndex(course => course.id === lastCourseId);
      let course;
      let nextModuleToSet;

      if (freeCourseIndex !== -1) {
        course = freeCourses[freeCourseIndex];
      } else if (courseIndex !== -1) {
        course = courses[courseIndex];
      }

      if (course) {
        const modules = course.modules;
        const lastModuleIndex = course.modules.findIndex(module => module.id === lastModuleId);

        for (let i = lastModuleIndex + 1; i < modules.length; i++) {
          const module = modules[i];

          if (module && typeof module !== 'string') {
            const path = `/course/${course.webPath || course.id}/${i}`;

            nextModuleToSet = {
              image: module.backgroundImage,
              title: module.title,
              description: module.description,
              path,
            };
            break;
          }
        }
      }

      if (nextModuleToSet) {
        setNextModule(nextModuleToSet);
      }
    }
  };

  const setDefaultRecommendedModules = () => {
    const selectedPath = user.selectedPath;
    const defaultModules = freeCourses.map(course => {
      const firstCourseModule = course.modules[0];

      return {
        backgroundImage: firstCourseModule.backgroundImage,
        title: firstCourseModule.title,
        description: firstCourseModule.description,
        path: `/course/${course.webPath || course.id}/0`,
        categories: firstCourseModule.categories || [],
        isFreeCourse: true,
      };
    });

    if (selectedPath === 'full') {
      defaultModules.splice(0, 1);
    } else if (selectedPath === 'new-dog') {
      defaultModules.splice(1, 1);
    } else if (selectedPath === 'behavior') {
      defaultModules.splice(2, 1);
    }

    return defaultModules;
  };

  const getRecommendedModules = () => {
    const defaultModules = setDefaultRecommendedModules();
    let sortedBehaviors = [];
    let relatedModules = [];
    const userRatings = user.behaviorTracking;

    if (!userRatings) {
      setRecommendedModules([ ...defaultModules, ...defaultAcademyModules ]);
      return;
    }

    const freeModules = freeCourses.map(course => {
      return course.modules.map((module, i) => {
        return {
          backgroundImage: module.backgroundImage,
          title: module.title,
          description: module.description,
          path: `/course/${course.webPath || course.id}/0`,
          categories: module.categories || [],
          isFreeCourse: true,
        };
      });
    }).flat();

    for (const behavior in userRatings) {
      const ratings = userRatings[behavior].ratings;
      const lastRating = ratings[ratings.length - 1];
      sortedBehaviors.push([behavior, lastRating]);
    }

    sortedBehaviors.sort((a, b) => {
      return a[1] - b[1];
    });

    const lowestRatedBehaviors = sortedBehaviors.slice(0, 3);
    const behaviorIndex = 0;
    const ratingIndex = 1;

    lowestRatedBehaviors.forEach(behavior => {
      if (behavior[ratingIndex] > 3) {
        return;
      }

      const behaviorModules = freeModules.filter((module) => {
        if (module.categories) {
          return module.categories.includes(behavior[behaviorIndex]);
        }
      });

      if (behaviorModules.length) {
        relatedModules.push(behaviorModules[behaviorModules.length - 1]);
      }
    });

    const combinedModules = relatedModules.concat(defaultModules);
    const recommendedModules = combinedModules.slice(0, 3);
    setRecommendedModules([ ...recommendedModules, ...defaultAcademyModules ]);
  };

  const hasAccessToPremiumCourse = (courseId) => {
    const foundCourse = courses.find(course => {
      return course.id === courseId;
    });

    return foundCourse && !foundCourse.disabled;
  };

  return (
    <div className="Dashboard">
      {!recommendedModules.length ?
        <div className="loading-container">
          <Loader size={2} />
          <p>Loading dashboard...</p>
        </div> :
        <>
          <Card>
            <div
              className="next-module-container"
              style={{ backgroundImage: `url(${nextModule.image})` }}
            ></div>

            <div className="next-module-actions">
              <div className="next-module-text-container">
                <p className="next-module-title">
                  Continue {nextModule.title}
                </p>
                <p className="next-module-description">
                  {nextModule.description}
                </p>
              </div>

              <Link to={nextModule.path}>
                <button>
                  Continue Training
                </button>
              </Link>
            </div>
          </Card>

          {!mainTrainingTools.length ? null :
            <div className="training-tools">
              <div className="header-container">
                <p>Training Tools</p>

                <Link to="/shop">
                  See All
                </Link>
              </div>

              <div className="training-tools-row">
                {mainTrainingTools.map(tool => {
                  return (
                    <div className="training-tool" key={tool.id}>
                      <a onClick={() => {
                        setSelectedProduct({
                          id: tool.id,
                          name: tool.name,
                          image: {
                            url: tool.image,
                          },
                        });
                        setProductModalOpen(true);
                      }}>
                        <Card backgroundColor="#F1F2F2">
                          <div className="training-tool-inner">
                            <Card>
                              <img src={tool.image} alt={tool.name} />
                            </Card>

                            <p>{tool.name}</p>
                          </div>
                        </Card>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          <div className="recommended-modules">
            <div className="header-container">
              <p>Recommended For You</p>
            </div>

            <div className="recommended-modules-row">
              {recommendedModules.map((module, i) => {
                return (
                  <div className="recommended-module" key={`recommended-module-${i}`}>
                    <Link to={(!module.isFreeCourse && !hasAccessToPremiumCourse(module.courseId)) ? `/buy-course/${module.courseId}` : module.path}>
                      <Card backgroundColor="#F1F2F2">
                        <div className="recommended-module-inner">
                          <Card>
                            <div
                              className="image-background"
                              style={{ backgroundImage: `url(${module.backgroundImage})` }}
                            ></div>
                          </Card>

                          <p className="module-title">{module.title}</p>

                          <p className="module-description">{module.description}</p>
                        </div>
                      </Card>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      }

      <Modal
        open={productModalOpen}
        close={() => {
          setProductModalOpen(false);
          setSelectedProduct({});
        }}
        title=""
        buttons={[]}
      >
        {!productModalOpen ? null :
          <PhysicalProductModal
            simplifiedProduct={selectedProduct}
            close={() => {
              setProductModalOpen(false);
              setSelectedProduct({});
            }}
          />
        }
      </Modal>
    </div>
  );
}

export default Dashboard;
