import {
  SET_FREE_COURSE_ID,
  SET_FREE_CURRENT_MODULE_INDEX,
  SET_FREE_COURSES,
} from './types';

export const setFreeCourses = (courses) => {
  return {
    type: SET_FREE_COURSES,
    payload: courses,
  };
};

export const setFreeCourseId = (courseId) => {
  return {
    type: SET_FREE_COURSE_ID,
    payload: courseId,
  };
};

export const setFreeCurrentModuleIndex = (index) => {
  return {
    type: SET_FREE_CURRENT_MODULE_INDEX,
    payload: index,
  };
};
