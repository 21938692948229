import React from 'react';

import styles from './productDropdownItem.module.scss';

export default function ProductDropdownItem({ item }) {

  const getOverlayImageJustifyContent = (value) => {
    const centerValues = ['left', 'right', 'center'];
    const bottomValues = ['bottom', 'bottom-left', 'bottom-right'];

    if (centerValues.includes(value)) {
      return 'space-around';
    }

    if (bottomValues.includes(value)) {
      return 'flex-end';
    }

    return 'flex-start';
  };

  const getOverlayImageTextAlignment = (value) => {
    const centerValues = ['center', 'top', 'bottom'];
    const rightValues = ['right', 'top-right', 'bottom-right'];

    if (centerValues.includes(value)) {
      return 'center';
    }

    if (rightValues.includes(value)) {
      return 'right';
    }

    return 'left';
  };

  if (item.type === 'title') {
    return (
      <h2 className={styles.title} style={{textAlign: item.textAlign}}>
        {item.value}
      </h2>
    );
  } else if (item.type === 'subtitle') {
    return (
      <h3 className={styles.subtitle} style={{textAlign: item.textAlign}}>
        {item.value}
      </h3>
    );
  } else if (item.type === 'paragraph') {
    return (
      <p className={styles.paragraph} style={{textAlign: item.textAlign}}>
        {item.value}
      </p>
    );
  } else if (item.type === 'image') {
    return (
      <div className={styles.imageContainer}>
        <img src={item.value} style={{ width: `${item.width || 100}%`, marginTop: item.marginTop || 20, marginBottom: item.marginBottom || 20 }}/>
      </div>
    );
  } else if (item.type === 'video') {
    const videoUrl = item.value.split('.hd')[0];
    const videoUrlSegments = videoUrl.split('/');
    const videoId = videoUrlSegments[videoUrlSegments.length - 1];

    return (
      <div className={styles.videoContainer}>
        <div className={styles.videoInner}>
          <iframe title={`product-video`} src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
        </div>
      </div>
    );
  } else if (item.type === 'textOverlayImage') {
    const textAlignment = getOverlayImageTextAlignment(item.textAlign);

    return (
      <div className={styles.textOverlayImageContainer}>
        <img className={styles.textOverlayImage} src={item.value} width="100%" height="auto"/>

        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: item.overlayFilterColor || 'rgba(0, 0, 0, 0)'}}>
          <div style={{position: 'relative', padding: 10, paddingLeft: 15, paddingRight: 15, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: getOverlayImageJustifyContent(item.textAlign)}}>
            <div>
              {!item.title ? null : <h2 className={styles.heading} style={{textAlign: textAlignment, color: item.textColor || '#000'}}>{item.title}</h2>}
              {!item.paragraph ? null : <h3 className={styles.subHeading} style={{textAlign: textAlignment, color: item.textColor || '#000'}}>{item.paragraph}</h3>}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
