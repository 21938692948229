import {
  SET_BEHAVIORS,
} from './types';

export const setBehaviors = (behaviors) => {
  return {
    type: SET_BEHAVIORS,
    payload: behaviors,
  };
};
