import {
  SET_SOUNDS,
  SET_SOUNDS_ACCESS,
} from '../actions/types';

const defaultState = {
  categories: [],
  sounds: {},
  hasAccess: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SOUNDS:
      return action.payload;
    case SET_SOUNDS_ACCESS:
      return {
        ...state,
        hasAccess: action.payload,
      };
    default:
      return state;
  }
};
