import {
  SET_COURSES,
} from './types';

export const setCourses = (courses) => {
  return {
    type: SET_COURSES,
    payload: courses,
  };
};
