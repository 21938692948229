import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './soundsNav.scss';
import Modal from '../../../shared/modal/modal';

function SoundsNav({ categories, selectedCategory, setSelectedCategory }) {
  const user = useSelector(state => state.user);
  const [showClickerInstructions, setShowClickerInstructions] = useState(false);
  const [introIndex, setIntroIndex] = useState(-1);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [accessDeniedTitle, setAccessDeniedTitle] = useState('');
  const [accessDeniedText, setAccessDeniedText] = useState('');

  const introSections = () => {
    return [
      {
        videoId: '553424965',
        paragraphs: [],
      },
      {
        paragraphs: [
          'Start by playing the sound once at a very low volume.',
          `As soon as you play the sound once, give ${user.dogName || 'your dog'} a treat.`,
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058865087-training_still_1.png?alt=media&token=13bfade5-baf9-479c-9ead-7697381caf0b',
      },
      {
        paragraphs: [
          `If ${user.dogName || 'your dog'} gets nervous or scared, simply lower the volume back to their comfort level. There is no need to rush.`,
          'This process can take multiple sessions over days or even weeks.',
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058873165-training_still_2.png?alt=media&token=85b18e19-ab44-4c92-936c-51dec3094173',
      },
      {
        paragraphs: [
          'Repeat these steps until you can reach full volume of the sound without your dog getting scared or nervous.',
          `The key is to pair these sounds with high-value treats to make the new sounds/stimuli have a positive association for ${user.dogName || 'your dog'}!`,
        ],
        image: 'https://pupford.b-cdn.net/assets%2Fapp-sounds%2F1622058870656-training_still_3.png?alt=media&token=212de046-4993-4e9f-8405-bdbd0a9a2b29',
      },
    ];
  };

  const clickerInstructionSteps = [
    'Press the clicker and then immediately give your dog a treat. Repeat 8-10 times.',
    'Prompt your dog to do a behavior they know well (like sit for example). Give the cue word and press the clicker the instant your dog’s butt hits the ground and then give a treat.',
    'Repeat this process multiple times. The key is to use the clicker the instant your dog does the behavior you’re asking for.',
  ];

  const introSectionButtons = () => {
    const sections = introSections();
    const buttons = [];

    if (sections[introIndex - 1]) {
      buttons.push(
        <button key="intro-back" onClick={() => {
          setIntroIndex(introIndex - 1);
        }}>Back</button>
      );
    }

    if (sections[introIndex + 1]) {
      buttons.push(
        <button className="success" key="intro-next" onClick={() => {
          setIntroIndex(introIndex + 1);
        }}>Next</button>
      );
    } else {
      buttons.push(
        <button className="success" key="intro-done" onClick={() => {
          setIntroIndex(-1);
        }}>Done</button>
      );
    }

    return buttons;
  };

  const renderIntroSections = () => {
    const section = introSections()[introIndex];

    if (!section) {
      return null;
    }

    return (
      <div className="modal-text">
        {section.paragraphs.map((paragraph, i) => {
          return <p key={`intro-paragraph-${i}`}>{paragraph}</p>;
        })}

        {!section.image ? null : <img src={section.image} alt="Intro section image" width="100%" />}

        {!section.videoId ? null :
          <div className="sounds-video-container">
            <div className="sounds-video-inner">
              <iframe title="Intro section video" src={`https://player.vimeo.com/video/${section.videoId}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="300px" name="fitvid0"></iframe>
            </div>
          </div>
        }
      </div>
    );
  };

  return (
    <div className="SoundsNav">
      <div className='navBar'>
        <div className='categorySelectContainer'>
          <label>Category:</label>
          <select value={selectedCategory} onChange={(e) => { setSelectedCategory(e.target.value) }}>
            {categories.map(category => {
              return <option key={category} value={category}>{category}</option>;
            })}
          </select>
        </div>

        <div className='additionalInfoContainerOuter'>
          <div className='additionalInfoContainer'>
            <p>Additional Info</p>

            <div className='additionalInfoDropdown'>
              <button onClick={() => setShowClickerInstructions(true)}>
                Clicker Instructions
              </button>
              <button onClick={() => setIntroIndex(0)}>
                Sounds Instructions
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modalContainer'>
        <Modal
          open={introIndex !== -1}
          close={() => {
            setIntroIndex(-1);
          }}
          title="Using the Training Sounds"
          buttons={introSectionButtons()}
        >
          {renderIntroSections()}
        </Modal>

        <Modal
          open={showClickerInstructions}
          close={() => {
            setShowClickerInstructions(false);
          }}
          title="Clicker Training"
          buttons={[
            <button key="modal-close" onClick={() => {
              setShowClickerInstructions(false);
            }}>Done</button>,
          ]}
        >
          <>
            <div className="modal-text">
              <div className="sounds-video-container">
                <div className="sounds-video-inner">
                  <iframe title="Intro section video" src={`https://player.vimeo.com/video/553429179`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="300px" name="fitvid0"></iframe>
                </div>
              </div>

              {clickerInstructionSteps.map((step, i) => {
                return <p key={`clicker-step-${i}`}><strong>Step {i + 1}</strong> - {step}</p>;
              })}

              <p>This marks the behavior clearly in your dog’s mind and increases the likelihood of them repeating the behavior in the future.</p>
            </div>
          </>
        </Modal>

        <Modal
          open={!!accessDeniedTitle}
          close={() => {
            setAccessDeniedTitle('');
            setAccessDeniedText('');
          }}
          title={accessDeniedTitle}
          buttons={[
            <a key="modal-upgrade" href="https://pupford.com/product/training-desensitization-sounds/">
              <button className="success" onClick={() => {
                setAccessDeniedTitle('');
                setAccessDeniedText('');
              }}>Upgrade Now</button>
            </a>,
            <button key="modal-close" onClick={() => {
              setAccessDeniedTitle('');
              setAccessDeniedText('');
            }}>Close</button>,
          ]}
        >
          <div className="modal-text">{accessDeniedText}</div>
        </Modal>

        <Modal
          open={!!modalText}
          close={() => {
            setModalText('');
          }}
          title={modalTitle}
          buttons={[
            <button key="modal-close" onClick={() => {
              setModalText('');
            }}>Close</button>,
          ]}
        >
          <div className="modal-text">{modalText}</div>
        </Modal>
      </div>
    </div>
  );
}

export default SoundsNav;
