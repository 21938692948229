export const generalItems = {
  styles: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderWidth: 0,
    borderColor: '',
    borderRadius: 0,
    borderStyle: 'none',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'inherit',
    maxWidth: '',
  },
  editableStyles: [
    {
      type: 'backgroundColor',
      label: 'Background Color',
      placeholder: 'Enter background color',
    },
    {
      type: 'borderWidth',
      label: 'Border Width',
      placeholder: 'Enter border width',
    },
    {
      type: 'borderColor',
      label: 'Border Color',
      placeholder: 'Enter border color',
    },
    {
      type: 'borderRadius',
      label: 'Border Radius',
      placeholder: 'Enter border radius',
    },
    {
      type: 'borderStyle',
      label: 'Border Style',
      placeholder: 'Enter border style',
      inputType: 'select',
      options: [ 'none', 'solid', 'dotted', 'dashed' ],
    },
    {
      type: 'marginTop',
      label: 'Margin Top',
      placeholder: 'Enter margin top',
    },
    {
      type: 'marginBottom',
      label: 'Margin Bottom',
      placeholder: 'Enter margin bottom',
    },
    {
      type: 'marginLeft',
      label: 'Margin Left',
      placeholder: 'Enter margin left',
    },
    {
      type: 'marginRight',
      label: 'Margin Right',
      placeholder: 'Enter margin right',
    },
    {
      type: 'paddingTop',
      label: 'Padding Top',
      placeholder: 'Enter padding top',
    },
    {
      type: 'paddingBottom',
      label: 'Padding Bottom',
      placeholder: 'Enter padding bottom',
    },
    {
      type: 'paddingLeft',
      label: 'Padding Left',
      placeholder: 'Enter padding left',
    },
    {
      type: 'paddingRight',
      label: 'Padding Right',
      placeholder: 'Enter padding right',
    },
    {
      type: 'textAlign',
      label: 'Text Align',
      inputType: 'select',
      options: [
        'inherit',
        'left',
        'center',
        'right',
      ],
    },
    {
      type: 'maxWidth',
      label: 'Max Width',
      placeholder: 'Enter max width',
    },
  ],
};

export const containerItems = {
  backgroundOverlayColor: '',
  styles: {
    ...generalItems.styles,
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '',
    minHeight: '',
  },
  editableStyles: [
    ...generalItems.editableStyles,
    {
      type: 'backgroundImage',
      label: 'Background Image URL',
      placeholder: 'Enter URL',
    },
    {
      type: 'backgroundPosition',
      label: 'Background Position',
      inputType: 'select',
      options: [
        'left top',
        'left center',
        'left bottom',
        'right top',
        'right center',
        'right bottom',
        'center top',
        'center center',
        'center bottom',
      ],
    },
    {
      type: 'height',
      label: 'Height',
      placeholder: 'Enter height',
    },
    {
      type: 'minHeight',
      label: 'Min Height',
      placeholder: 'Enter min height',
    },
  ],
};

export const textItems = {
  text: 'Enter text here',
  styles: {
    ...generalItems.styles,
    color: '#000',
    lineHeight: 1,
    fontSize: '24px',
    width: '100%',
    fontFamily: 'Visby',
    display: '',
    fontWeight: '400',
  },
  editableStyles: [
    ...generalItems.editableStyles,
    {
      type: 'color',
      label: 'Text Color',
      placeholder: 'Enter text color',
    },
    {
      type: 'lineHeight',
      label: 'Line Height',
      placeholder: 'Enter line height',
    },
    {
      type: 'fontSize',
      label: 'Font Size',
      placeholder: 'Enter font size',
    },
    {
      type: 'width',
      label: 'Width',
      placeholder: 'Enter width',
    },
    {
      type: 'fontFamily',
      label: 'Font',
      inputType: 'select',
      options: [
        'Visby',
        'Arial',
        'Verdana',
        'Trebuchet MS',
        'Times New Roman',
        'Didot',
        'American Typewriter',
        'Andale Mono',
        'Courier',
        'Bradley Hand',
        'Luminari',
      ],
    },
    {
      type: 'display',
      label: 'Display',
      inputType: 'select',
      options: [
        '',
        'none',
        'inline',
        'block',
        'inline-block',
        'initial',
        'inherit',
      ],
    },
    {
      type: 'fontWeight',
      label: 'Font Weight',
      placeholder: 'Enter font weight',
    },
  ],
};
