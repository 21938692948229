import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import SwipeableViews from 'react-swipeable-views';
import firebase from '../../../../firebase';

import RatingStars from '../../shared/ratingStars';
import Loader from '../../shared/loader/loader';
import SideBySideMagnifier from '../../shared/SideBySideMagnifier';
import ProductDropdownItem from '../../shared/productDropdownItem';
import PageRenderer from '../../shared/pageRenderer';
import Modal from '../../shared/modal/modal';
import PurchaseCourseModal from '../../shared/purchaseCourseModal/purchaseCourseModal';
import LoadingMessage from '../../shared/loadingMessage/loadingMessage';
import './buyCourse.scss';
import {
  setAcademyCourseProducts,
  setShopSettings,
  setCourses,
  addToCart,
} from '../../../../actions';

const reviewsPerPage = 5;
const academyMembershipId = '396404';

function BuyCourse(props) {
  const dispatch = useDispatch();
  const courses = useSelector(state => state.courses);
  const currentUser = useSelector(state => state.user);
  const academyCourseProducts = useSelector(state => state.academyCourseProducts);
  const shopSettings = useSelector(state => state.shopSettings);
  const currentImageRef = useRef(null);
  const imagesContainerRef = useRef(null);
  const imageMouseDownInterval = useRef(null);
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [currentProductImage, setCurrentProductImage] = useState({});
  const [currentProductImageIndex, setCurrentProductImageIndex] = useState(0);
  const [showOtherFiles, setShowOtherFiles] = useState(false);
  const [dropdownExpandedState, setDropdownExpandedState] = useState([]);
  const [currentImageSelectionHeight, setCurrentImageSelectionHeight] = useState(undefined);
  const [salePrice, setSalePrice] = useState('');
  const [reviews, setReviews] = useState([]);
  const [reviewAverage, setReviewAverage] = useState(0);
  const [numberOfReviews, setNumberOfReviews] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingNewReviewPage, setLoadingNewReviewPage] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [leaveReviewRating, setLeaveReviewRating] = useState(5);
  const [reviewTitle, setReviewTitle] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [reviewerEmail, setReviewerEmail] = useState('');
  const [pendingReviewAction, setPendingReviewAction] = useState(false);
  const [userActions, setUserActions] = useState(false);
  const [firstReview, setFirstReview] = useState(null);
  const [lastReview, setLastReview] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isAcademyPurchase, setIsAcademyPurchase] = useState(false);
  const [buyCourseModalOpen, setBuyCourseModalOpen] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (currentImageRef && currentImageRef.current && currentImageRef.current.clientHeight) {
      setCurrentImageSelectionHeight(currentImageRef.current.clientHeight);
    } else {
      setCurrentImageSelectionHeight(undefined);
    }
  }, [currentProductImage]);

  useEffect(() => {
    fetchAcademyCourseProducts();
    fetchShopSettings();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCourse();
    }
  }, [id]);

  useEffect(() => {
    if (product.id) {
      getReviewData();
    }
  }, [product]);

  useEffect(() => {
    const user = firebase.auth().currentUser;

    if (user.uid && !user.isAnonymous) {
      setUserId(user.uid);
    } else {
      setUserId('');
    }
  }, [currentUser]);

  const fetchAcademyCourseProducts = async () => {
    if (academyCourseProducts.length) {
      return;
    }

    try {
      const db = firebase.firestore();
      const academyProductsSnapshot = await db.collection('products-v2').where('memberships', 'array-contains', academyMembershipId).get();

      const products = academyProductsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }).filter(p => {
        return p.active;
      });

      dispatch(setAcademyCourseProducts(products));
    } catch (e) {
      return;
    }
  };

  const fetchShopSettings = async () => {
    if (shopSettings.settingsSet) {
      return;
    }

    try {
      const db = firebase.firestore();
      const shopSettingsSnapshot = await db.collection('config').doc('shop-settings').get();
      const data = shopSettingsSnapshot.data();

      dispatch(setShopSettings(data));
    } catch (e) {
      return;
    }
  };

  const fetchCourse = async () => {
    try {
      const db = firebase.firestore();
      const courseSnapshot = await db.collection('products-v2').doc(id).get();
      const courseData = courseSnapshot.data();

      if (typeof courseData.editorData === 'string') {
        courseData.editorData = JSON.parse(courseData.editorData);
      }

      setProduct({
        ...courseData,
        id,
      });
      setCurrentProductImage(courseData.media[0]);
      setDropdownExpandedState(courseData.descriptionSections.map(s => {
        return false;
      }));
      setLoading(false);
      const calculatedSalePrice = (courseData.isOnSale && courseData.salePrice) ? courseData.salePrice : '';
      setSalePrice(calculatedSalePrice);

      const fbq = window.fbq || function() {};
      fbq('track', 'ViewContent');

      const gtag = window.gtag || function() {};
      gtag('event', 'view_item', {
        items: [
          {
            id,
            name: courseData.name,
            brand: 'Pupford',
            category: courseData.categories.join('/'),
            price: calculatedSalePrice || +courseData.price,
          },
        ],
      });
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  const handleImageChange = (image, i) => {
    setCurrentProductImage(image);
    setCurrentProductImageIndex(i);
    setShowOtherFiles(true);
  };

  const handleImageContainerScroll = (goUp) => {
    const amountToScroll = goUp ? -5 : 5;

    if (imagesContainerRef && imagesContainerRef.current) {
      imagesContainerRef.current.scrollTop += amountToScroll;
    }
  };

  const handleImageArrowMouseDown = (goUp) => {
    handleImageContainerScroll(goUp);

    const mouseDownInterval = setInterval(() => {
      handleImageContainerScroll(goUp);
    }, 15);

    imageMouseDownInterval.current = mouseDownInterval;
  };

  const handleImageArrowMouseUp = () => {
    if (imageMouseDownInterval && imageMouseDownInterval.current && imageMouseDownInterval.current) {
      clearInterval(imageMouseDownInterval.current);
      imageMouseDownInterval.current = false;
    }
  };

  const getReviewData = async () => {
    try {
      const reviewDataSnapshot = await firebase.firestore().collection('product-reviews').doc('data').get();
      const reviewData = reviewDataSnapshot.data();

      if (!reviewData[product.id]) {
        setNumberOfPages(0);
        setReviewAverage(0);
        setNumberOfReviews(0);
        setReviews([]);
        return;
      }

      const reviewsSnapshot = await firebase.firestore().collection('product-reviews').where('live', '==', true).where('productId', '==', product.id).orderBy('created', 'desc').limit(reviewsPerPage).get();
      const mappedReviews = reviewsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const liveCount = reviewData[product.id].liveCount;
      const pagesCount = Math.ceil(liveCount / reviewsPerPage);

      setNumberOfPages(pagesCount);
      setNumberOfReviews(liveCount);
      setReviewAverage(reviewData[product.id].liveAverage);
      setReviews(mappedReviews);
      setFirstReview(reviewsSnapshot.docs[0]);
      setLastReview(reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getReviewsPage = async (value) => {
    if (loadingNewReviewPage) {
      return;
    }

    setLoadingNewReviewPage(true);

    try {
      let reviewsSnapshot;
      let newPage = page;

      if (value === 'next') {
        reviewsSnapshot = await firebase.firestore().collection('product-reviews').where('live', '==', true).where('productId', '==', product.id).orderBy('created', 'desc').startAfter(lastReview).limit(reviewsPerPage).get();
        newPage += 1;
      } else {
        reviewsSnapshot = await firebase.firestore().collection('product-reviews').where('live', '==', true).where('productId', '==', product.id).orderBy('created', 'desc').endBefore(firstReview).limitToLast(reviewsPerPage).get();
        newPage -= 1;
      }

      const mappedReviews = reviewsSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      setReviews([]);
      setPage(newPage);
      setReviews(mappedReviews);
      setFirstReview(reviewsSnapshot.docs[0]);
      setLastReview(reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]);
      setLoadingNewReviewPage(false);
    } catch (e) {
      console.log('error getting reviews page', e);
      setLoadingNewReviewPage(false);
    }
  };

  const getUserActions = async () => {
    if (!currentUser || !userId) {
      return;
    }

    try {
      let actions = userActions;

      if (actions) {
        return actions;
      }

      const userActionsSnapshot = await firebase.firestore().collection('user-actions').doc(userId).get();
      actions = userActionsSnapshot.data();

      if (!actions) {
        return;
      }

      return actions;
    } catch (e) {
      console.log('error getting user actions', e);
    }
  };

  const submitReview = async () => {
    if (pendingReviewAction || !userId) {
      return;
    }

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(reviewerEmail))) {
      setModalTitle('Notice:');
      setModalText('You have entered an invalid email address. Please update your email address and try again.');
      return;
    }

    setPendingReviewAction(true);
    setLoadingMessage('Submitting review...');

    try {
      let actions = await getUserActions();

      if (!actions) {
        actions = {};
      }

      if (!actions.productReviews) {
        actions = {
          ...actions,
          productReviews: [product.id],
        };
      } else {
        if (actions.productReviews.includes(product.id)) {
          setPendingReviewAction(false);
          setLoadingMessage('');
          setModalTitle('Notice:');
          setModalText('You have already left a review for this product. You can only leave one review per product.');
          return;
        } else {
          actions = {
            ...actions,
            productReviews: [ actions.productReviews, ...product.id ],
          };
        }
      }

      const reviewsSnapshot = await firebase.firestore().collection('product-reviews').where('productId', '==', product.id).where('email', '==', reviewerEmail).get();

      if (reviewsSnapshot.docs && reviewsSnapshot.docs.length) {
        setPendingReviewAction(false);
        setLoadingMessage('');
        setModalTitle('Notice:');
        setModalText('You have already left a review for this product. You can only leave one review per product.');
        return;
      }

      let shouldVerifyEmail = true;

      if (currentUser.email && currentUser.email === reviewerEmail) {
        shouldVerifyEmail = false;
      }

      const result = await axios.post(`https://api-pupford.b-cdn.net/webApi/reviews-add`, {
        email: reviewerEmail,
        userId,
        content: reviewText,
        productId: product.id,
        rating: leaveReviewRating,
        title: reviewTitle,
        userName: reviewerName,
        shouldVerifyEmail,
      });

      setPendingReviewAction(false);
      setLoadingMessage('');

      if (result.data.error) {
        setModalTitle('Error:');
        setModalText(result.data.error);
      } else {
        setReviewModalOpen(false);
        setModalTitle('Success!');
        setModalText(result.data.message);
      }
    } catch (e) {
      setPendingReviewAction(false);
      setLoadingMessage('');
      setModalTitle('Error:');
      setModalText('There was an error submitting your review. Please try again.');
    }
  };

  const updateReviewVote = async (reviewId, i, type) => {
    if (pendingReviewAction || !userId) {
      return;
    }

    setPendingReviewAction(true);

    try {
      let actions = await getUserActions();
      let shouldAdd = true;
      let shouldCreate = false;

      if (!actions) {
        actions = {};
        shouldCreate = true;
      }

      const reviewType = type === 'upVotes' ? 'upVotedReviews' : 'downVotedReviews';

      if (!actions[reviewType]) {
        actions = {
          ...actions,
          [reviewType]: [reviewId],
        };
      } else {
        if (actions[reviewType].includes(reviewId)) {
          const reviewIndex = actions[reviewType].indexOf(reviewId);
          const reviewsTypeCopy = [ ...actions[reviewType] ];
  
          reviewsTypeCopy.splice(reviewIndex, 1);
  
          actions = {
            ...actions,
            [reviewType]: reviewsTypeCopy,
          };
          shouldAdd = false;
        } else {
          const reviewsTypeCopy = [ ...actions[reviewType] ];
  
          reviewsTypeCopy.push(reviewId);
  
          actions = {
            ...actions,
            [reviewType]: reviewsTypeCopy,
          };
        }
      }

      const interval = shouldAdd ? 1 : -1;
      await firebase.firestore().collection('product-reviews').doc(reviewId).update({
        [type]: firebase.firestore.FieldValue.increment(interval),
      });

      const reviewsCopy = [ ...reviews ];
      const review = {
        ...reviewsCopy[i],
        [type]: reviewsCopy[i][type] + interval,
      };

      reviewsCopy[i] = review;
      setReviews(reviewsCopy);

      if (shouldCreate) {
        await firebase.firestore().collection('user-actions').doc(userId).set(actions);
      } else {
        await firebase.firestore().collection('user-actions').doc(userId).update(actions);
      }

      setUserActions(actions);

      setPendingReviewAction(false);
    } catch (e) {
      console.log('e', e);
      setPendingReviewAction(false);
    }
  };

  const renderCurrentMedia = () => {
    if (currentProductImage.contentType === 'video' && currentProductImage.url.includes('player.vimeo')) {
      const videoUrl = currentProductImage.url.split('.hd')[0];
      const videoUrlSegments = videoUrl.split('/');
      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
      const options = [];

      if (currentProductImage.autoplay) {
        options.push('autoplay');
      }

      if (currentProductImage.muted) {
        options.push('muted', 'cc');
      }

      let query = `${options.length ? '?' : ''}`;

      options.forEach((option, i) => {
        if (i === 0) {
          query += `${option}=1`;
        } else {
          query += `&${option}=1`;
        }
      });

      return (
        <div className="videoContainerOuter" ref={currentImageRef}>
          <div className="videoContainer">
            <div className="videoInner">
              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={currentImageRef} className="selectedImageContainer">
        <SideBySideMagnifier
          imageSrc={currentProductImage.url || product.media[0].url}
          imageAlt={currentProductImage.alt || product.media[0].alt}
          alwaysInPlace={false}
          fillAvailableSpace={false}
        />
      </div>
    );
  };

  const renderDropdowns = () => {
    if (!product.descriptionSections.length) {
      return null;
    };
  
    return (
      <div className="dropdownsContainer">
        {product.descriptionSections.map((s, i) => {
          return (
            <div key={`dropdown-item-${i}`} className="dropdownContainer">
              <button
                className={`dropdownHeaderContainer ${dropdownExpandedState[i] ? 'expanded' : ''}`}
                onClick={() => {
                  const dropdownState = [ ...dropdownExpandedState ];
                  dropdownState[i] = !dropdownState[i];
                  setDropdownExpandedState(dropdownState);
                }}
              >
                {s.title}
                <div className="buttonIcon">{dropdownExpandedState[i] ? '-' : '+'}</div>
              </button>
              <div className={`dropdownContentContainer ${dropdownExpandedState[i] ? 'show' : ''}`}>
                {s.sections.map((section, j) => {
                  return <ProductDropdownItem key={`dropdown-item-${i}-${j}`} item={section} />
                })}
              </div>
            </div>
          );
        })}
      </div>
    )
  };

  const handleAcademyPurchaseStripeSuccess = async () => {
    setBuyCourseModalOpen(false);
    setIsAcademyPurchase(false);
    setLoadingMessage('Loading courses...');

    const coursesToFetchIds = courses.filter(course => {
      return typeof course.modules === 'number';
    }).map(course => course.id);

    try {
      const updatedCourses = [ ...courses ];

      for (let i = 0; i < coursesToFetchIds.length; i++) {
        const courseSnapshot = await firebase.firestore().collection('membership-content').doc(coursesToFetchIds[i]).get();
        const data = courseSnapshot.data();
  
        if (!data) {
          setModalTitle('Notice:');
          setModalText('Your order has been placed but there was an error redirecting you to the courses page. You can access all of the courses by refreshing this page and manually navigating to the courses page. If you need further assistance, please contact us.');
          return;
        }
  
        const course = {
          ...data,
          id: courseSnapshot.id,
        };
  
        const foundCourseIndex = updatedCourses.findIndex(c => c.id === course.id);

        if (foundCourseIndex) {
          updatedCourses[foundCourseIndex] = course;
        }
      }

      setLoadingMessage('');
      dispatch(setCourses(updatedCourses));
      props.history.push('/courses');
    } catch (e) {
      setLoadingMessage('');
      setModalTitle('Notice:');
      setModalText('Your order has been placed but there was an error redirecting you to the courses page. You can access all of the courses by refreshing this page and manually navigating to the courses page. If you need further assistance, please contact us.');
    }
  };

  const handleStripeSuccess = async () => {
    if (isAcademyPurchase) {
      handleAcademyPurchaseStripeSuccess();
      return;
    }

    setBuyCourseModalOpen(false);
    setIsAcademyPurchase(false);

    try {
      const courseSnapshot = await firebase.firestore().collection('membership-content').doc(product.id).get();
      const data = courseSnapshot.data();

      if (!data) {
        setModalTitle('Notice:');
        setModalText('Your order has been placed but there was an error redirecting you to the course page. You can access the course by refreshing this page and manually navigating to the course. If you need further assistance, please contact us.');
        return;
      }

      const course = {
        ...data,
        id: product.id,
      };

      const foundCourseIndex = courses.findIndex(course => course.id === product.id);
      const updatedCourses = [ ...courses ];

      if (foundCourseIndex) {
        updatedCourses[foundCourseIndex] = course;
      } else {
        updatedCourses.unshift(course);
      }

      dispatch(setCourses(updatedCourses));

      props.history.push(`/course/${product.id}`);
    } catch (e) {
      setModalTitle('Notice:');
      setModalText('Your order has been placed but there was an error redirecting you to the course page. You can access the course by refreshing this page and manually navigating to the course. If you need further assistance, please contact us.');
    }
  };

  return (
    <div className="BuyCourse">
      {!loadingMessage ? null : <LoadingMessage message={loadingMessage} />}
      {loading ?
        <div className="MyCoursesLoader">
          <Loader size={2} />
        </div> :
        <div className="course-content">
          <div className="imageContainerOuter">
            <div className="imageContainer">
              <div className="selectedImage">
                {renderCurrentMedia()}
              </div>
              <div className="selectedImageMobile">
                <SwipeableViews index={currentProductImageIndex} onChangeIndex={(index) => { handleImageChange(product.media[index], index) }} enableMouseEvents>
                  {product.media.map((m, i) => {
                    if (!showOtherFiles && i !== 0) {
                      return <div key={`swipable-${i}`}></div>;
                    }

                    if (m.contentType === 'video' && m.url.includes('player.vimeo')) {
                      const videoUrl = m.url.split('.hd')[0];
                      const videoUrlSegments = videoUrl.split('/');
                      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
                      const options = [];

                      if (m.autoplay) {
                        options.push('autoplay');
                      }

                      if (m.muted) {
                        options.push('muted', 'cc');
                      }

                      let query = `${options.length ? '?' : ''}`;

                      options.forEach((option, i) => {
                        if (i === 0) {
                          query += `${option}=1`;
                        } else {
                          query += `&${option}=1`;
                        }
                      });

                      return (
                        <div key={`swipable-${i}`} className="videoContainerOuter">
                          <div className="videoContainer">
                            <div className="videoInner">
                              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <img
                        key={`swipable-${i}`}
                        src={m.url || product.media[0].url}
                        className="lazyload"
                      />
                    );
                  })}
                </SwipeableViews>
              </div>
              <div className="imageSelection" style={{height: currentImageSelectionHeight}}>
                <div className="imagesContainer" ref={imagesContainerRef}>
                  {product.media.map((image, i) => {
                    return (
                      <div className="mediaItemContainer" key={`product-image-${i}`}>
                        {image.contentType === 'video' ?
                          <div className="videoThumbnailContainer" onClick={() => { handleImageChange(image, i) }} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}>
                            <img height="50" src="https://pupford.com/images/play-icon.svg" className="playIcon lazyload" alt="play icon" />
                            <img src={image.placeholder} className="thumbnailImage lazyload" alt={image.alt}/>
                          </div> :
                          <img src={image.url} className="desktopImageThumbnail lazyload" alt={image.alt} onClick={() => { handleImageChange(image, i) }} style={{ borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}/>
                        }
                        <div className="imageSelectionButton" onClick={() => { handleImageChange(image, i) }} style={{ backgroundColor: !currentProductImage.url ? (i === 0 ? '#000' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#000' : 'rgba(0, 0, 0, 0.1)') }}></div>
                      </div>
                    );
                  })}
                </div>

                <div className="imageArrowContainer">
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(true)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Up" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(false)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Down" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div className="desktopDropdownsContainer">
              {renderDropdowns()}
            </div>
          </div>

          <div className="productDetailsContainer">
            <h1>{product.name}</h1>

              <div className="ratingStarsContainer">
                <div className="ratingStarsInner">
                  <RatingStars
                    numberOfStars={5}
                    rating={reviewAverage}
                    starRatedColor="#fc9a26"
                    starDimension="17px"
                    starSpacing="0px"
                  />
                </div>
                <div className="ratingStarsAmount">
                  <a href="#product-reviews">{numberOfReviews} Reviews</a>
                </div>
              </div>

            <div className="buyRow">
              <div className="priceLine">
                {!salePrice ? null : <p className="productPrice productSalePrice">${salePrice}</p>}
                <p className={`productPrice ${salePrice ? 'originalPrice' : ''}`}>${product.price}</p>
              </div>

              <a>
                <button onClick={() => {
                  setBuyCourseModalOpen(true);
                }}>
                  Buy Now
                </button>
              </a>
            </div>

            <div className="otherActionButtonContainer">
              <button onClick={() => {
                const productToAdd = {
                  productId: product.id,
                  hasMembership: product.memberships.length,
                  name: product.name,
                  image: product.media[0],
                  path: product.path,
                  sku: product.sku,
                  isDigital: product.isDigital,
                  originalPrice: product.price,
                  salePrice: product.isOnSale ? product.salePrice : product.price,
                  price: salePrice || product.price,
                  subscription: null,
                  subscriptionOptions: product.subscriptionOptions || [],
                  selectedSubscriptionIndex: 0,
                  subscriptionOnly: product.subscriptionOnly || false,
                  tags: product.tags || [],
                  categories: product.categories || [],
                  shippingClass: product.shippingClass,
                  quantity: 1,
                  taxCode: product.taxCode,
                  customThankYouPagePath: product.customThankYouPagePath || '',
                  customThankYouPagePriority: product.customThankYouPagePriority || 1,
                };

                const fbq = window.fbq || function() {};
                fbq('track', 'AddToCart');

                const gtag = window.gtag || function() {};
                gtag('event', 'add_to_cart', {
                  items: [
                    {
                      id: product.id,
                      name: product.name,
                      brand: 'Pupford',
                      category: product.categories.join('/'),
                      price: salePrice || product.price,
                    },
                  ],
                });

                dispatch(addToCart(productToAdd));
              }}>
                Add to Cart
              </button>
            </div>

            {!academyCourseProducts.length ? null :
              <div className="otherActionButtonContainer">
                <a>
                  <button onClick={() => {
                    setIsAcademyPurchase(true);
                    setBuyCourseModalOpen(true);
                  }}>
                    Get Academy access
                  </button>
                </a>
              </div>
            }

            <p className="description">{product.description}</p>
          </div>

          <div>
            <PageRenderer items={product.editorData.children} clicked={() => {}} />
          </div>

          <div id="product-reviews" className="productContainer">
            <div className="productContainerInner">
              <div className="productReviewsContainer">
                <h2>Customer Reviews</h2>

                <div className="dividerLine"></div>

                <div className="reviewsSummaryContainer">
                  <div className="reviewsSummaryInner">
                    <div className="reviewsAverageText">
                      {reviewAverage.toFixed(1)}
                    </div>

                    <div className="ratingStarsInner">
                      <RatingStars
                        numberOfStars={5}
                        rating={reviewAverage}
                        starRatedColor="#fc9a26"
                        starDimension="17px"
                        starSpacing="0px"
                      />

                      <div className="ratingStarsAmount">
                        {numberOfReviews} Reviews
                      </div>
                    </div>
                  </div>

                  <div className="writeReviewButtonContainer">
                    <button className="small" onClick={() => { setReviewModalOpen(true) }}>
                      Write a Review
                    </button>
                  </div>
                </div>

                <div className="dividerLine"></div>

                <div className="reviewsContainer">
                  {reviews.map((review, i) => {
                    return (
                      <div key={review.id} className="reviewContainer well well-light">

                        <div className="reviewDetails">
                          <div className="reviewTopRow">
                            <div className="reviewerNameRow">
                              <div className="reviewerImage">
                                {review.user.name && review.user.name[0].toUpperCase()}
                              </div>

                              <div>
                                <p className="reviewerName">
                                  {review.user.name}

                                  {!review.verifiedBuyer ? null :
                                    <span className="verifiedBuyer">Verified Buyer</span>
                                  }
                                </p>

                                <div className="ratingStarsInner">
                                  <RatingStars
                                    numberOfStars={5}
                                    rating={review.rating}
                                    starRatedColor="#fc9a26"
                                    starDimension="17px"
                                    starSpacing="0px"
                                  />
                                </div>
                              </div>

                            </div>

                            <div className="reviewDate">
                              <p>{moment(review.created).format('l')}</p>
                            </div>
                          </div>

                          <div className="reviewDivider"></div>

                          <div className="reviewTitleContainer">
                            <p>{review.title.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}</p>
                          </div>

                          <div className="reviewContentContainer">
                            <p>{review.content.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}</p>
                          </div>

                          <div className="reviewBottomRow">
                            <div>
                            </div>

                            <div className="reviewHelpfulContainer">
                              <p>Was This Review Helpful?</p>
                              <div className="voteContainer" onClick={() => { updateReviewVote(review.id, i, 'upVotes') }}>👍</div> <p>{review.upVotes}</p>
                              <div className="voteContainer" onClick={() => { updateReviewVote(review.id, i, 'downVotes') }}>👎</div> <p>{review.downVotes}</p>
                            </div>
                          </div>

                          {!review.comment ? null :
                            <div className="commentContainer">
                              <div className="dividerLine"></div>

                              <div className="reviewContainer">

                                <div className="reviewDetails">
                                  <div className="reviewTopRow">
                                    <div className="reviewerNameRow">
                                      <div className="reviewerImage" style={{overflow: 'hidden', backgroundColor: '#fff'}}>
                                        <img src="https://pupford.b-cdn.net/assets%2F1602111918861-pupford-thumb.png?alt=media&token=3e2e031f-40c8-4b88-b4ab-06f8dad8a165" alt="Pupford Thumb" height="100%" width="100%" className="lazyload" />
                                      </div>

                                      <p className="reviewerName">{review.comment.name}</p>
                                    </div>

                                    <div className="reviewDate">
                                      <p>{moment(review.comment.created).format('l')}</p>
                                    </div>
                                  </div>

                                  <div className="reviewContentContainer" style={{marginTop: 20}}>
                                    <p>{review.comment.content.replace(/(&#x27;)/gm, "'").replace(/(&quot;)/gm, '"')}</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    );
                  })}

                  {numberOfPages < 1 ? null :
                    <div className="pageContainer">
                      <div>
                        <button disabled={page === 1 || loadingNewReviewPage} className="small success" onClick={() => { getReviewsPage('back') }}>
                          Back
                        </button>
                        <span className="pageText">Page <strong>{page}</strong> of <strong>{numberOfPages}</strong></span>
                        <button disabled={page === numberOfPages || loadingNewReviewPage} className="small success" onClick={() => { getReviewsPage('next') }}>
                          Next
                        </button>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Modal
        open={reviewModalOpen}
        close={() => {
          setReviewModalOpen(false);
        }}
        title="Leave a Review"
        buttons={[
          <button
            key="submit-review"
            className="success"
            disabled={!leaveReviewRating || !reviewTitle || !reviewText || !reviewerName || !reviewerEmail}
            onClick={() => {
              submitReview();
            }}
          >Submit</button>,
          <button key="modal-close" onClick={() => {
            setReviewModalOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div className="ratingModalContainer">
          <div className="ratingModalStarsContainer">
            <RatingStars
              numberOfStars={5}
              rating={leaveReviewRating}
              changeRating={(rating) => { setLeaveReviewRating(rating) }}
              starRatedColor="#fc9a26"
              starHoverColor="#fc9a26"
              starDimension="35px"
              starSpacing="0px"
            />
          </div>

          <div className="inputContainer">
            <label>Title</label>
            <input
              type="text"
              value={reviewTitle}
              onChange={(e) => { setReviewTitle(e.target.value) }}
            />
          </div>

          <div className="inputContainer">
            <label>Review</label>
            <textarea
              rows="5"
              value={reviewText}
              onChange={(e) => { setReviewText(e.target.value) }}
            ></textarea>
          </div>

          <div className="inputContainer">
            <label>Your Name</label>
            <input
              type="text"
              value={reviewerName}
              onChange={(e) => { setReviewerName(e.target.value) }}
            />
          </div>

          <div className="inputContainer">
            <label>Your Email Address</label>
            <input
              type="email"
              value={reviewerEmail}
              onChange={(e) => { setReviewerEmail(e.target.value) }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={buyCourseModalOpen}
        close={() => {
          setBuyCourseModalOpen(false);
          setIsAcademyPurchase(false);
        }}
        title={`Purchase ${isAcademyPurchase ? 'Pupford Academy' : product.name}`}
        buttons={[]}
      >
        <PurchaseCourseModal
          product={product}
          singleCoursePrice={salePrice || product.price}
          user={currentUser}
          stripeEnabled={shopSettings.stripeEnabled}
          paypalEnabled={shopSettings.paypalEnabled}
          cancelPressed={() => {
            setBuyCourseModalOpen(false);
            setIsAcademyPurchase(false);
          }}
          handleStripeSuccess={handleStripeSuccess}
          isAcademyPurchase={isAcademyPurchase}
          academyCourseProducts={academyCourseProducts}
        />
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" onClick={() => {
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div className="modal-text">{modalText}</div>
      </Modal>
    </div>
  );
}

export default BuyCourse;
