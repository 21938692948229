import firebase from '../firebase';

export const updateShoppingCart = async (cart) => {
  try {
    const uid = firebase.auth().currentUser.uid;
    await firebase.firestore().collection('shopping-carts').doc(uid).set({
      coupons: cart.coupons,
      products: cart.products,
      freeProducts: cart.freeProducts,
      userId: cart.userId,
      dateUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  } catch (e) {
    // fail silently
  }
};
