import React, { Component } from 'react';
import firebase from './firebase';
import axios from 'axios';
import { Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import OpenDay from './components/openDay/openDay';
import Login from './components/auth/login/login';
import ForgotPassword from './components/auth/forgotPassword/forgotPassword';
import ResetPassword from './components/auth/resetPassword/resetPassword';
import JoinAsPro from './components/auth/joinAsPro/joinAsPro';
import Authed from './components/authed/authed';
import Loader from './components/authed/shared/loader/loader';
import OpenInMobile from './components/authed/shared/openInMobile/openInMobile';
import { baseURL } from './config';

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  console.log('Initializing Analytics');

  ReactGA.initialize('UA-97333743-1', { standardImplementation: true });

  history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      initialized: false,
      loginError: '',
      showLoginError: false,
      loggingIn: false,
      appUrl: '',
      isAndroid: false,
      isIOS: false,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((result) => {
      let user = null;

      if (result && !result.isAnonymous) {
        user = result;
      }

      this.setState({ user, initialized: true });
    });

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
    const isMobile = isAndroid || isIOS;
    const query = new URLSearchParams(history.location.search);
    const appUrl = query.get('app');

    if (isMobile && history && history.location && history.location.search && appUrl) {
      this.setState({ appUrl, isAndroid, isIOS });
    }
  }

  async logIn(email, password) {
    this.setState({ loggingIn: true });

    try {
      const user = await firebase.auth().signInWithEmailAndPassword(email, password);
      this.setState({ user, showLoginError: false, loggingIn: false });
    } catch (e) {
      this.setState({
        user: null,
        loginError: 'We were unable to find a user with the provided credentials. Please check the information you have provided and try again.',
        showLoginError: true,
        loggingIn: false,
      });
    }
  }

  async logOut() {
    try {
      await firebase.auth().signOut();
      this.setState({ user: null });
    } catch (e) {
      console.log('error signing out', e);
    }
  }

  renderAuthenticated() {
    if (this.state.user) {
      return <Authed logOut={this.logOut.bind(this)} user={this.state.user} />
    } else if (this.state.initialized) {
      return (
        <Login
          logIn={this.logIn.bind(this)}
          showLoginError={this.state.showLoginError}
          loginError={this.state.loginError}
          closeError={() => { this.setState({showLoginError: false}) }}
          loggingIn={this.state.loggingIn}
        />
      );
    } else {
      return (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%)'
        }}>
          <Loader/>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <Router history={history}>
          <Switch>
            <Route path="/open-day/:id" component={OpenDay} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword}></Route>
            <Route path="/join-as-pro" component={JoinAsPro} />
            <Route render={(renderProps) => this.renderAuthenticated()} />
          </Switch>
        </Router>

        {!this.state.appUrl ? null :
          <OpenInMobile
            close={() => this.setState({ appUrl: '' })}
            appUrl={this.state.appUrl}
            isAndroid={this.state.isAndroid}
            isIOS={this.state.isIOS}
          />
        }
      </>
    );
  }
}

export default Main;
