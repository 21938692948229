import { combineReducers } from 'redux';
import CourseReducer from './courseReducer';
import UserReducer from './userReducer';
import FreeCourseReducer from './freeCourseReducer';
import ProductReducer from './productReducer';
import TrainingToolReducer from './trainingToolReducer';
import SoundsReducer from './soundsReducer';
import BehaviorReducer from './behaviorReducer';
import DogBreedReducer from './dogBreedReducer';
import AcademyCourseProductsReducer from './academyCourseProductReducer';
import ShopSettingsReducer from './shopSettingsReducer';
import ShoppingReducer from './shoppingReducer';

export default combineReducers({
  freeCourses: FreeCourseReducer,
  courses: CourseReducer,
  user: UserReducer,
  products: ProductReducer,
  trainingTools: TrainingToolReducer,
  sounds: SoundsReducer,
  behaviors: BehaviorReducer,
  dogBreeds: DogBreedReducer,
  academyCourseProducts: AcademyCourseProductsReducer,
  shopSettings: ShopSettingsReducer,
  cart: ShoppingReducer,
});
