import React from 'react';

import './loader.scss';
import loadingIcon from '../../../../images/loading.svg';

function Loader(props) {
  return (
    <div className="Loader">
      <img src={loadingIcon} alt="Loading..." className={`x${props.size || 3}`} />
    </div>
  );
}

export default Loader;
