import {
  SET_SOUNDS,
  SET_SOUNDS_ACCESS,
} from './types';

export const setSounds = (soundsData) => {
  return {
    type: SET_SOUNDS,
    payload: soundsData,
  };
};

export const setSoundsAccess = (hasAccess) => {
  return {
    type: SET_SOUNDS_ACCESS,
    payload: hasAccess,
  };
};
