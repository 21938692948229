import React, { Component } from 'react';

import './progressRing.scss';

class ProgressRing extends Component {
  constructor(props) {
    super(props);
    
    const { radius, stroke } = this.props;
    
    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    return (
      <div className="ProgressRing">
        <svg
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            stroke="#FF3312"
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ this.circumference + ' ' + this.circumference }
            style={ { strokeDashoffset } }
            r={ this.normalizedRadius }
            cx={ radius }
            cy={ radius }
          />
        </svg>
        <div className="progress-percentage" style={{ height: radius * 1.1, width: radius * 1.1 }}>
          <div className="progress-inner">
            <div className="progress-text-container">
              <div className="completion-amount">{this.props.progress}%</div>
              <div className="completion-text">Complete</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressRing;
