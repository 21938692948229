import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import './tabs.scss';

function Tabs(props) {
  let location = useLocation();

  const checkFindATrainerActive = () => {
    return location.pathname.includes('find-a-trainer') ? ' selected' : '';
  };

  const checkMyCoursesActive = () => {
    return (location.pathname.includes('find-a-trainer') || location.pathname.includes('profile')) ? '' : ' selected';
  };

  const checkProfileActive = () => {
    return location.pathname.includes('profile') ? ' selected' : '';
  };

  return (
    <div className="Tabs">
      {props.tabs.map((tab, i) => {
        return (
          <a
            className={`tab${props.activeTab === i ? ' selected' : ''}`}
            key={`tab-${i}-${tab}`}
            onClick={(e) => {
              e.preventDefault();
              props.onTabClick(i) 
            }}
            title={tab}
            href=""
          >{tab}</a>
        );
      })}
    </div>
  );
}

export default Tabs;
