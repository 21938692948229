import React from 'react';
import { Link } from 'react-router-dom';

import './authHeader.scss';

function AuthHeader(props) {
  return (
    <header className="AuthHeader">
      <div>
        <Link to="/">
          <img src="https://b4878e5b1c.nxcli.net/wp-content/themes/pupford/favicons/favicon-96x96.png" alt="Pupford logo" height="35" />
        </Link>
      </div>
      <Link to="/">
        Log In
      </Link>
    </header>
  );
}

export default AuthHeader;
